import React, { useState, useEffect, useContext, useRef } from 'react'
import jwt_decode from 'jwt-decode'
import {
  Button,
  Card,
  Modal,
  Tab,
  Tabs,
  InputGroup,
  Form,
  FormControl,
} from 'react-bootstrap'
import {
  FaEnvelope,
  FaClock,
  FaChevronRight,
  FaPen,
  FaTrash,
  FaPlus,
  FaEye,
} from 'react-icons/fa'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ReactPaginate from 'react-paginate'
import ReactQuill from 'react-quill'
import LoadingStatus from '../../Helper/LoadingStatus'
import {
  createEmailTemplate,
  getEmailTemplate,
  deleteEmailTemplate,
  updateEmailTemplate,
} from '../../../redux/actions/npsActions/npsSurveyAction'
import { getTemplatePlaceholder } from '../../../redux/actions/npsActions/npsChapterAction'
import { AuthContext } from '../../context/authProvider'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../NPS/index.css'
import emailContent from './emailContent'
import Placeholder from './placeholderValues'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: '12px',
    whiteSpace: 'break-spaces',
  },
}))

function ConfigureEmailModal({
  emailSelect,
  setEmailSelect,
  show,
  handleClose,
  isChapter,
}) {
  const [step, setStep] = useState('selectCard')
  const [selectedEmail, setSelectedEmail] = useState(null)
  const [selectEmailTitle, setSelectEmailTitle] = useState(null)
  const [emailTitle, setEmailTitle] = useState('')
  const [emailBody, setEmailBody] = useState('')
  const [emailSubject, setEmailSubject] = useState('')
  const [isPublic, setIsPublic] = useState(false)
  const [emailType, setEmailType] = useState('')
  const [activeTab, setActiveTab] = useState('selectEmail')
  const [filterCheck, setFilterCheck] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [previewEmail, setPreviewEmail] = useState(null)
  const [isOwner, setIsOwner] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [deleteModalShow, setDeleteModalShow] = useState(false)
  const [tabTitle, setTabTitle] = useState('Create Email Template')
  const [deleteTemplateId, setDeleteTemplateId] = useState('')
  const [placeHolderValues, setPlaceHolderValues] = useState([])
  const [isBaseTemplate, setIsBaseTemplate] = useState(false)
  const [isBaseTemplateFilter, setIsBaseTemplateFilter] = useState(false)
  const [loadingStatus, setLoadingStatus] = useState({
    status: false,
    message: 'Fetching email template...',
  })
  const bodyRef = useRef('')

  const dispatch = useDispatch()
  const [authCreds] = useContext(AuthContext)
  const endpoint = authCreds?.restEndpoint
  let tokenValue = localStorage?.getItem('token')
  const value = jwt_decode(tokenValue)
  const user_email = value?.preferred_username?.toLowerCase()

  const getTemplatePlaceholderData = useSelector(
    (state) => state.getTemplatePlaceholderData
  )
  useEffect(() => {
    dispatch(getTemplatePlaceholder(endpoint))
  }, [])
  useEffect(() => {
    if (getTemplatePlaceholderData?.loading === false) {
      setPlaceHolderValues(
        getTemplatePlaceholderData?.getTemplatePlaceholderData?.data
      )
    }
  }, [getTemplatePlaceholderData])

  useEffect(() => {
    if (show) {
      fetchEmailTemplates()
    }
    if (emailType == 'initial_email' && emailSelect.initialEmail.id) {
      setSelectedEmail(emailSelect.initialEmail.id)
      setSelectEmailTitle(emailSelect.initialEmail.title)
    } else if (emailType == 'reminder_email' && emailSelect.reminderEmail.id) {
      setSelectedEmail(emailSelect.reminderEmail.id)
      setSelectEmailTitle(emailSelect.reminderEmail.title)
    }
  }, [show, emailType, currentPage, filterCheck, isBaseTemplateFilter])

  const getEmailTemplateData = useSelector(
    (state) => state.getEmailTemplateData
  )
  const [emailTemplateData, setEmailTemplateData] = useState([])
  const [allEmailTemplateNames, setAllEmailTemplateNames] = useState([])
  const parser = new DOMParser()
  const [selectedTemplate, setSelectedTemplate] = useState(emailContent)

  useEffect(() => {
    const updatedHtml = selectedTemplate.replace(
      /<div id="email-content-placeholder">[\s\S]*?<\/div>/,
      `<div id="email-content-placeholder">${emailBody}</div>`
    )
    setSelectedTemplate(updatedHtml)
  }, [emailBody, selectedTemplate])

  useEffect(() => {
    if (
      !getEmailTemplateData?.loading &&
      getEmailTemplateData?.getEmailTemplateData
    ) {
      setEmailTemplateData(
        getEmailTemplateData?.getEmailTemplateData?.data || []
      )
      setAllEmailTemplateNames(
        getEmailTemplateData?.getEmailTemplateData?.all_template_names || []
      )
    }
    setTotalPages(
      Math.ceil(getEmailTemplateData?.getEmailTemplateData?.total_results / 10)
    )
  }, [getEmailTemplateData])

  const fetchEmailTemplates = async () => {
    const tag_filters = [
      {
        op: 'eq',
        field: 'is_chapter_specific',
        value: isChapter ? 'True' : 'False',
      },
      {
        op: 'eq',
        field:
          emailType === 'initial_email'
            ? 'is_initial_email'
            : 'is_reminder_email',
        value: 'True',
      },
      {
        op: 'eq',
        field: filterCheck ? 'is_public' : 'is_private',
        value: 'True',
      },
    ]
    let tag_base_filters=[];
    if (isBaseTemplateFilter) {
      tag_base_filters = [
        {
          op: 'eq',
          field: 'is_chapter_specific',
          value: isChapter ? 'True' : 'False',
        },
        {
          op: 'eq',
          field:
            emailType === 'initial_email'
              ? 'is_initial_email'
              : 'is_reminder_email',
          value: 'True',
        },
        {
          op: 'eq',
          field: 'is_base_template',
          value: 'True',
        }
      ]
    }
    setLoadingStatus({ status: true, message: 'Fetching email templates...' })
    const params = {
      page_size: 10,
      page_number: currentPage + 1,
      filters: JSON.stringify(filterCheck || isBaseTemplateFilter ? [] : [{ op: 'eq', field: 'created_by', value: user_email }]),
      tag_filters: JSON.stringify(isBaseTemplateFilter?tag_base_filters:tag_filters),
    }
    await dispatch(getEmailTemplate(endpoint, params)).finally(() => {
      setLoadingStatus({ status: false, message: '' })
    })
  }
  const handleEditEmailTemplate = (email) => {
    setSelectedEmail(email.id)
    setEmailTitle(email.template_name)
    setEmailSubject(email.subject)

    const doc = parser.parseFromString(email.body, 'text/html')
    const contentPlaceholder = doc.getElementById('email-content-placeholder')

    let emailBody = contentPlaceholder
      ? contentPlaceholder.innerHTML
      : email.body

    emailBody = emailBody.replace(
      /<a href="\{{SURVEY_LINK\}}"[^>]*>Survey Link<\/a>/,
      `{{SURVEY_LINK}}`
    )

    if (email.created_by === user_email) {
      let templateNameList = allEmailTemplateNames.filter(
        (t) => t !== email.template_name
      )
      setAllEmailTemplateNames(templateNameList)
    }
    setIsOwner(email.created_by === user_email)
    setEmailBody(emailBody)
    setIsBaseTemplate(email.created_by === user_email ? email.is_base_template : false);
    setIsPublic(email.is_private == true ? false : true)
    setIsEditMode(true)
    setEmailTitle(email.created_by === user_email ? email.template_name : '')
    setTabTitle(
      email.created_by === user_email
        ? 'Edit Email Template'
        : 'Create Email Template'
    )
    setActiveTab('createEmail')
  }
  const handleCardClick = (type) => {
    setEmailType(type === 'initial' ? 'initial_email' : 'reminder_email')
    setStep('emailForm')
  }

  const handleSubmit = () => {
    if (!selectedEmail) {
      toast.error(
        'Please select or create an email template before submitting!'
      )
      return
    }
    if (emailType == 'initial_email') {
      setEmailSelect((prevData) => ({
        ...prevData,
        initialEmail: {
          id: selectedEmail,
          title: selectEmailTitle,
        },
      }))
    } else {
      setEmailSelect((prevData) => ({
        ...prevData,
        reminderEmail: {
          id: selectedEmail,
          title: selectEmailTitle,
        },
      }))
    }
    toast.success('Email template submitted successfully!')
    setStep('selectCard')
  }

  const handleSaveEmail = () => {
    if (!emailTitle || !emailSubject || !emailBody) {
      toast.error('Please fill out all fields before saving!')
      return
    }

    const titleExists = allEmailTemplateNames.some((t) => t === emailTitle)
    if (titleExists) {
      toast.error('Template name already exists!')
      return
    }

    setLoadingStatus({ status: true, message: 'Fetching email template...' })
    const updatedHtml = emailBody.replace(
      '{{SURVEY_LINK}}',
      `<a href="{{SURVEY_LINK}}">Survey Link</a>`
    )
    setEmailBody(updatedHtml)
    bodyRef.current = updatedHtml


    const template_tags = [
      {
        name: 'is_initial_email',
        value: emailType === 'initial_email' ? true : false,
      },
      {
        name: 'is_reminder_email',
        value: emailType === 'reminder_email' ? true : false,
      },
      {
        name: 'is_public',
        value: isBaseTemplate ? true : isPublic,
      },
      {
        name: 'is_private',
        value: isBaseTemplate ? true : !isPublic,
      },
      {
        name: 'is_chapter_specific',
        value: isChapter ? true : false,
      },
      {
        name: 'is_base_template',
        value: isBaseTemplate,
      },
    ]

    if (isEditMode) {
      const updateTemplate = {
        id: selectedEmail,
        template_name: emailTitle,
        subject: emailSubject,
        body: bodyRef.current,
        template_tags: template_tags,
      }
      dispatch(updateEmailTemplate(endpoint, updateTemplate))
        .then(() => {
          resetEmailForm()
          fetchEmailTemplates()
        })
        .finally(() => {
          setLoadingStatus({ status: false })
        })
    } else {
      const newTemplate = {
        template_name: emailTitle,
        subject: emailSubject,
        body: bodyRef.current,
        template_tags: template_tags,
      }

      dispatch(createEmailTemplate(endpoint, newTemplate))
        .then(() => {
          resetEmailForm()
          fetchEmailTemplates()
        })
        .finally(() => {
          setLoadingStatus({ status: false })
        })
    }
  }

  const resetEmailForm = () => {
    setEmailTitle('')
    setEmailSubject('')
    setEmailBody('')
    setIsPublic(false)
    setIsEditMode(false)
    setActiveTab('selectEmail')
    setIsEditMode(false)
    setIsOwner(false)
    setTabTitle('Create Email Template')
  }

  const handleEmailSelect = (email) => {
    if(selectedEmail === email.id){
      setSelectedEmail(null)
      setSelectEmailTitle(null)
      setEmailSelect((prev)=>({
        ...prev,
        [emailType==='initial_email'? 'initialEmail' : 'reminderEmail']:{
          id: null,
          title: '',
        }
      }))
    }else{
      setSelectedEmail(email.id)
      setSelectEmailTitle(email.template_name)
      setEmailSelect((prev)=>({
        ...prev,
        [emailType==='initial_email'? 'initialEmail' : 'reminderEmail']:{
          id: email.id,
          title: email.template_name,
        }
      }))
    }
  }

  const handleTitleChange = (e) => {
    setEmailTitle(e.target.value)
  }

  const handlePageChange = (data) => {
    setCurrentPage(data.selected)
  }

  const handlePreview = (email) => {
    const previewContent = selectedTemplate.replace(
      /<div id="email-content-placeholder">[\s\S]*?<\/div>/,
      `<div id="email-content-placeholder">${email.body}</div>`
    )
    setPreviewEmail({ ...email, body: previewContent })
    setShowPreviewModal(true)
  }

  useEffect(() => {
    if (activeTab === 'selectEmail') {
      resetEmailForm()
    }
  }, [activeTab])

  const handleDelete = () => {
    const payload = {
      template_id: deleteTemplateId,
    }

    dispatch(deleteEmailTemplate(endpoint, payload)).then(() => {
      setDeleteModalShow(false)
      setDeleteTemplateId('')
      fetchEmailTemplates()
    })
  }
  const handleCloseDeleteModal = () => {
    setDeleteModalShow(false)
  }

  const handleDeleteTemplate = (e, id) => {
    e.stopPropagation()
    setDeleteModalShow(true)
    setDeleteTemplateId(id)
  }

  return (
    <>
      <ToastContainer />
      <Modal
        show={show}
        className="email-nps-modal"
        onHide={() => handleClose()}
        size="xl"
      >
        <Modal.Header closeButton>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'grey',
                borderRadius: '5px',
                width: '40px',
                height: '40px',
                marginRight: '10px',
              }}
            >
              <FaEnvelope style={{ color: 'white' }} size={16} />
            </div>
            <div style={{ lineHeight: '0px' }}>
              <h5>
                {step === 'selectCard'
                  ? 'Email Configuration'
                  : emailType === 'initial_email'
                    ? 'Configure Initial Email'
                    : 'Configure Reminder Email'}
              </h5>
              {step === 'selectCard' ? (
                <p>Select an email type below to set up your email template.</p>
              ) : emailType === 'initial_email' ? (
                <p>
                  Create or select an email for sending notifications when the
                  survey is active.
                </p>
              ) : (
                <p>
                  Create or select an email to remind users who haven&apos;t
                  completed the survey.
                </p>
              )}
            </div>
          </div>
        </Modal.Header>

        {loadingStatus.status ? (
          <LoadingStatus status_message={loadingStatus.message} />
        ) : (
          <>
            <Modal.Body style={{ overflowY: 'scroll' }}>
              {step === 'selectCard' && (
                <>
                  <Card
                    className="mb-3 border-start border-4 border-warning email_card"
                    onClick={() => handleCardClick('initial')}
                  >
                    <Card.Body
                      className="d-flex justify-content-between align-items-center gap-3"
                      style={{ flexDirection: 'column' }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <FaEnvelope className="text-warning fs-3" />
                          <span className="ms-3 fw-bold text-dark">
                            Initial Notification Email
                          </span>
                          {emailSelect.initialEmail.title && (
                            <>
                              <ArrowRightAltIcon />
                              {emailSelect.initialEmail.title.length > 45
                                ? `${emailSelect.initialEmail.title.substring(
                                  0,
                                  45
                                )}` + '...'
                                : emailSelect.initialEmail.title}{' '}
                              <CheckCircleOutlineIcon
                                style={{ marginLeft: '8px', color: '#1b84ff' }}
                              />
                            </>
                          )}
                        </div>
                        <FaChevronRight className="fs-4 text-secondary" />
                      </div>
                      <Card.Text className="text-muted">
                        This email is sent when the survey becomes active. It
                        serves as an invitation to the users, notifying them
                        that a survey is available and encourage them to
                        participate.
                      </Card.Text>
                    </Card.Body>
                  </Card>

                  <Card
                    className="mb-3 border-start border-4 border-success email_card"
                    onClick={() => handleCardClick('reminder')}
                  >
                    <Card.Body
                      className="d-flex justify-content-between align-items-center gap-3"
                      style={{ flexDirection: 'column' }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <FaClock className="text-success fs-3" />
                          <span className="ms-3 fw-bold text-dark">
                            Reminder Notification Email
                          </span>
                          {emailSelect.reminderEmail.title && (
                            <>
                              <ArrowRightAltIcon />
                              {emailSelect.reminderEmail.title.length > 45
                                ? `${emailSelect.reminderEmail.title.substring(
                                  0,
                                  45
                                )}` + '...'
                                : emailSelect.reminderEmail.title}{' '}
                              <CheckCircleOutlineIcon
                                style={{ marginLeft: '8px', color: '#1b84ff' }}
                              />
                            </>
                          )}
                        </div>
                        <FaChevronRight className="fs-4 text-secondary" />
                      </div>
                      <Card.Text className="text-muted">
                        <p>
                          The reminder email is sent to those who have not yet
                          completed the survey, reinforcing the importance of
                          their feedback and encouraging them to complete the
                          survey before the closing date. It usually follows a
                          few days before the deadline and serves as a gentle
                          nudge.
                        </p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </>
              )}

              {step === 'emailForm' && (
                <div>
                  <Tabs
                    id="email-template-tabs"
                    activeKey={activeTab}
                    onSelect={(k) => setActiveTab(k)}
                    className="mb-3"
                  >
                    <Tab eventKey="selectEmail" title="Select Email Template">
                      <div style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '4px' }}>
                        <BootstrapTooltip
                          title={
                            'Turn this on to view public email templates shared by others. Turn it off to see only your private email templates.'
                          }
                        >
                          <Form.Group controlId="formPublicPrivateCheck">
                            <Form.Check
                              type="checkbox"
                              label={'Show Public Templates'}
                              checked={filterCheck}
                              onChange={() => setFilterCheck(!filterCheck)}
                            />
                          </Form.Group>
                        </BootstrapTooltip>
                        <BootstrapTooltip
                          title={
                            'Turn this on to view only the predefined base email templates.'
                          }
                        >
                          <Form.Group controlId="formBaseTemplateCheck">
                            <Form.Check
                              type="checkbox"
                              label={'Show Base Templates'}
                              checked={isBaseTemplateFilter}
                              onChange={() => setIsBaseTemplateFilter(!isBaseTemplateFilter)}
                            />
                          </Form.Group>
                        </BootstrapTooltip>
                      </div>
                      <div className="d-flex flex-column">
                        {Array.isArray(emailTemplateData) &&
                          emailTemplateData.map((email, index) => (
                            <Card
                              key={index}
                              className="email_card mb-3"
                              style={{
                                border: `1px solid #1b84ff`,
                                borderBottom: `4px solid #1b84ff`,
                                padding: '0px',
                              }}
                            >
                              <Card.Header
                                className="bg-light d-flex align-items-center justify-content-between"
                                style={{ borderRadius: '0.75rem' }}
                              >
                                <div className="d-flex align-items-center" style={{ width: '100%' }}>
                                  <input
                                    type="checkbox"
                                    id={`emailRadio${index}`}
                                    name="emailRadio"
                                    value={email.template_name}
                                    onChange={() => handleEmailSelect(email)}
                                    checked={selectedEmail === email.id}
                                    className="me-2"
                                  />
                                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                    <label
                                      htmlFor={`emailRadio${index}`}
                                      className="mb-0 fw-bold text-dark"
                                    >
                                      {email.template_name}
                                    </label>
                                    {email.is_base_template &&
                                      <span
                                        style={{ color: 'blue', fontWeight: '600', marginRight: '14px', fontSize: '14px' }}
                                      >
                                        (Base Template)
                                      </span>
                                    }
                                  </div>
                                </div>
                              </Card.Header>
                              <Card.Body>
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                  <div className="text-muted">
                                    {email.subject}
                                  </div>
                                  <div className="d-flex align-items-center gap-1">
                                    <Button
                                      className="text-primary icon-wrapper"
                                      title="Preview Email Template"
                                      variant="outline-primary"
                                      onMouseEnter={(e) =>
                                      (e.currentTarget.querySelector(
                                        'svg'
                                      ).style.color = 'white')
                                      }
                                      onMouseLeave={(e) =>
                                      (e.currentTarget.querySelector(
                                        'svg'
                                      ).style.color = '')
                                      }
                                      onClick={() => handlePreview(email)}
                                    >
                                      <FaEye size={16} />
                                    </Button>
                                    <Button
                                      className="text-warning icon-wrapper"
                                      title={
                                        email.created_by === user_email
                                          ? 'Edit Email Template'
                                          : `create new email template from this ${email.is_base_template ? 'base' : 'public'} template`
                                      }
                                      onMouseEnter={(e) =>
                                      (e.currentTarget.querySelector(
                                        'svg'
                                      ).style.color = 'white')
                                      }
                                      onMouseLeave={(e) =>
                                      (e.currentTarget.querySelector(
                                        'svg'
                                      ).style.color = '')
                                      }
                                      variant="outline-warning"
                                      onClick={() =>
                                        handleEditEmailTemplate(email)
                                      }
                                    >
                                      {email.created_by === user_email ? (
                                        <FaPen size={16} />
                                      ) : (
                                        <FaPlus size={16} />
                                      )}
                                    </Button>
                                    {email.created_by === user_email && (email.is_base_template ? false : true) ? (
                                      <Button
                                        className="text-danger"
                                        title="Delete Email Template"
                                        variant="outline-danger"
                                        onMouseEnter={(e) =>
                                        (e.currentTarget.querySelector(
                                          'svg'
                                        ).style.color = 'white')
                                        }
                                        onMouseLeave={(e) =>
                                        (e.currentTarget.querySelector(
                                          'svg'
                                        ).style.color = '')
                                        }
                                        onClick={(e) =>
                                          handleDeleteTemplate(e, email.id)
                                        }
                                      >
                                        <FaTrash size={16} />
                                      </Button>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>
                                <Card.Text className="text-muted"></Card.Text>
                              </Card.Body>
                            </Card>
                          ))}
                      </div>
                    </Tab>

                    <Tab eventKey="createEmail" title={tabTitle}>
                      <div
                        className="d-flex align-items-center mb-3"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          className="titleEmail"
                          style={{ display: 'flex', flexDirection: 'row' }}
                        >
                          <>
                            <FaEnvelope
                              style={{
                                width: '24px',
                                height: '32px',
                                marginRight: '10px',
                                color: '#ff6600',
                              }}
                            />
                            <InputGroup className="w-100 d-flex gap-2">
                              <FormControl
                                type="text"
                                placeholder="Enter a new template name"
                                value={emailTitle}
                                onChange={handleTitleChange}
                                // onBlur={toggleTitleEdit}
                                autoFocus
                              />
                            </InputGroup>
                          </>
                        </div>
                        <Placeholder placeHolderValues={placeHolderValues} />
                      </div>
                      <InputGroup className="mb-3">
                        <FormControl
                          placeholder="Enter email subject"
                          value={emailSubject}
                          onChange={(e) => setEmailSubject(e.target.value)}
                        />
                      </InputGroup>
                      <ReactQuill
                        placeholder="Enter email body text here..."
                        value={emailBody}
                        onChange={setEmailBody}
                        theme="snow"
                        modules={{
                          toolbar: [
                            [{ header: '1' }, { header: '2' }, { font: [] }],
                            [{ size: ['small', false, 'large', 'huge'] }],
                            [{ color: [] }, { background: [] }],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            ['bold', 'italic', 'underline'],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                          ],
                        }}
                      />
                      {!isBaseTemplate ?
                        <Form.Group
                          controlId="formPublicPrivateToggle"
                          className="mt-3"
                        >
                          <Form.Check
                            type="switch"
                            id="public-private-switch"
                            label={'Public'}
                            checked={isPublic}
                            onChange={() => setIsPublic(!isPublic)}
                          />
                          <Form.Text className="text-muted">
                            {isPublic
                              ? 'This template will be available for all users.'
                              : 'This template will be private and only visible to you.'}
                          </Form.Text>
                        </Form.Group>
                        : <></>
                      }
                      <hr />
                      <h3>Email Preview:</h3>
                      <div
                        dangerouslySetInnerHTML={{ __html: selectedTemplate }}
                        style={{
                          border: '1px solid #ddd',
                          padding: '20px',
                          marginTop: '20px',
                        }}
                      />
                    </Tab>
                  </Tabs>
                </div>
              )}
            </Modal.Body>

            <Modal.Footer>
              {step === 'selectCard' && (
                <Button variant="secondary" onClick={() => handleClose()}>
                  Close
                </Button>
              )}
              {step === 'emailForm' && (
                <>
                  {activeTab == 'selectEmail' && (
                    <div style={{ display: 'flex', marginRight: 'auto' }}>
                      <ReactPaginate
                        pageCount={totalPages}
                        onPageChange={handlePageChange}
                        forcePage={currentPage}
                        breakLabel={'...'}
                        previousLabel={'<<'}
                        nextLabel={'>>'}
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                      />
                    </div>
                  )}

                  <div
                    className="btn-container"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '5px',
                    }}
                  >
                    {isEditMode ? (
                      <Button variant="secondary" onClick={resetEmailForm}>
                        Cancel
                      </Button>
                    ) : (
                      <Button
                        variant="secondary"
                        onClick={() => setStep('selectCard')}
                      >
                        Back
                      </Button>
                    )}
                    {activeTab == 'createEmail' ? (
                      <Button variant="primary" onClick={handleSaveEmail}>
                        {isEditMode && isOwner ? 'Update Email' : 'Save Email'}
                      </Button>
                    ) : (
                      <Button variant="primary" onClick={handleSubmit}>
                        Submit
                      </Button>
                    )}
                  </div>
                </>
              )}
            </Modal.Footer>
          </>
        )}
      </Modal>

      <Modal
        show={showPreviewModal}
        onHide={() => setShowPreviewModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Email Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {previewEmail && (
            <div>
              <h5>{previewEmail.title}</h5>
              <h6>Subject: {previewEmail.subject}</h6>
              <div dangerouslySetInnerHTML={{ __html: previewEmail.body }} />
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={deleteModalShow} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Template</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: 'hidden' }}>
          Are you sure to delete template ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleDelete}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ConfigureEmailModal
