import image_path from '../../../media/nps_media/banner-kraftheinz-ph-1786x642-2021.jpg'
import image_path1 from '../../../media/nps_media/logo-kraftheinz.png'

const emailContent = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </head>
    <style>
    .ql-align-center {
        text-align: center;
    }
    .ql-align-right {
        text-align: right;
    }
    .ql-align-justify {
        text-align: justify;
    }
    .ql-size-small {
        font-size: 12px;
    }
    .ql-size-normal {
        font-size: 16px;
    }
    .ql-size-large {
        font-size: 20px;
    }
    .ql-size-huge {
        font-size: 24px;
    }
    </style>

    <body style="
                font-family: 'Helvetica Neue', Arial, sans-serif;
                margin: 0;
                padding: 0;
                background-color: #ffffff;
                box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4);
            ">
    <div style="
                max-width: 600px;
                margin: auto;
                background-color: #ffffff;
                border: 1px solid #dddddd;
                padding: 20px;
                ">
        <div id="image1" style="
                    background-color: #ffffff;
                    padding: 10px;
                    text-align: center;
                    border-bottom: 1px solid #dddddd;
                ">
        <img src=${image_path} alt="Kraft Heinz" style="width: 100%; height: auto; max-height: 500px" />
        </div>
        <div id="email-content-placeholder">
        <h1 style="color: #0033a0; text-align: center; font-size: 20px">
            Action Required
        </h1>
        <h2 style="color: #333333; text-align: center; font-size: 18px">
            <span>You are invited to participate in the Emergency Survey 3</span>
        </h2>
        <div style="padding: 20px;">
            <p style="color: #333333; font-size: 14px; margin-bottom: 15px">
                Colleagues,
            </p>
            <p style="color: #333333; font-size: 14px; margin-bottom: 15px">
                We want to hear from you! Let us know how we are doing!.
            </p>
            <p style="color: #333333; font-size: 14px; margin-bottom: 15px">
                This survey looks to measure the current services we are providing.
                While we are continually enhancing our platform and processes, we
                please ask that you evaluate the team based on our commitments to
                improve and provide better visibility on our practice.
            </p>
            <p style="color: #333333; font-size: 14px; margin-bottom: 15px">
            v   We appreciate your feedback!
            </p>
            <p style="color: #333333; font-size: 16px; margin-bottom: 15px">
                <center><a href="[Link to Survey]">Survey Link</a></center>
            </p>
            <p style="color: #333333; font-size: 14px; margin-bottom: 15px">
                Should you have any questions or concerns, feel free to contact
                <a href="mailto:[owner_email]">[owner_email]</a>
            </p>
            <p style="color: #333333; font-size: 14px; margin-bottom: 15px">
                Thank you,
            </p>
        </div>
        </div>
        <div id="image2" style="
                    padding: 10px;
                    text-align: center;
                    border-top: 1px solid #dddddd;
                    padding-top: 20px;
                ">
        <img src=${image_path1} alt="Kraft Heinz" style="object-fit: contain" />
        </div>
    </div>
    </body>

    </html>
    `

export default emailContent;
