import { faTruckMedical } from '@fortawesome/free-solid-svg-icons'
import {
  GET_BDM_METRICS,
  GET_BDM_METRICS_LOAD,
  GET_BDM_METRICS_ERROR,
} from '../../../constants/types'

const initialState = {
  bdmMetrics: {},
  loading: true,
  error: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_BDM_METRICS_LOAD:
      return {
        ...state,
        loading: faTruckMedical,
        bdmMetrics: action.payload,
      }
    case GET_BDM_METRICS:
      return {
        ...state,
        loading: false,
        bdmMetrics: action.payload,
      }
    case GET_BDM_METRICS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
