import React, { useEffect, useMemo, useContext } from 'react'
import '../../RefData/RefTable.css'
import TooltipHover from '@mui/material/Tooltip'
import { toast } from 'react-toastify'
import { toastTopRightError } from '../../../Helper/ToastObjects'
import '../index.css'
import LoadingStatus from '../../../Helper/LoadingStatus'
import {
  useTable,
  usePagination,
  useRowSelect,
  useFilters,
  useGlobalFilter,
  useSortBy,
} from 'react-table'
import { Table, Col, Row, Form, Button } from 'react-bootstrap'
import { AuthContext } from '../../../context/authProvider'
import { TableHandler } from '../../../Utils'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch, useSelector } from 'react-redux'
import { ForumHandler } from '../../../Utils'
import Modal from 'react-bootstrap/Modal'
import { MdHighlightOff, MdOutlineDone } from 'react-icons/md'
import { getSurveyResponse } from '../../../../redux/actions/npsActions/npsSurveyAction'
import UserResponseForm from './userResponseForm'
import { DateHandler } from '../../Utilities/dateutils'
import MentionHandler from '../../Utilities/quillSearch'
export default function SurveyResponses({ surveyId }) {
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })

  const [tableData, setTableData] = React.useState([])
  const [totalPages, setTotalPages] = React.useState(1)
  /* eslint-disable no-unused-vars */
  const [totalRows, setTotalRows] = React.useState(0)
  const [tableColumns, setTableColumns] = React.useState([])
  const [localPageIndex, setLocalPageIndex] = React.useState(0)
  const [columnFilters, setColumnFilters] = React.useState([])
  const [columnSort, setColumnSortValue] = React.useState([])
  const [isSort, setSort] = React.useState(false)
  const [showModal, setshowModal] = React.useState(false)
  const [addusermodal, setshowUserModal] = React.useState(false)
  const [editorContent, setEditorContent] = React.useState('')
  const [declineCheck, setDeclineCheck] = React.useState(false)
  const [checkBox, setCheckBox] = React.useState(false)
  const [cosmosId, setCosmosId] = React.useState('')
  const [userEmail, setUserEmail] = React.useState('')
  const [authCreds] = useContext(AuthContext)
  const dispatch = useDispatch()

  const endpoint = authCreds.restEndpoint

  const getSurveyResponseData = useSelector(
    (state) => state.getSurveyResponseData
  )
  const namesArray = getSurveyResponseData?.getSurveyResponseData?.data

  function findIndexByKey(array, key, value) {
    for (let i = 0; i < array?.length; i++) {
      if (array[i][key] === value) {
        return i;
      }
    }
    return -1; // Not found
  }
  let currentIndex = findIndexByKey(namesArray, 'cosmos_response_id', cosmosId);

  const handleNext = () => { 
    if(namesArray?.length === 1){ 
      toast("Only one response available", toastTopRightError)
    }
    else {
    currentIndex = (currentIndex + 1) % namesArray?.length;
    let values = namesArray[currentIndex]
    setUserEmail(values?.user_email)
    setCosmosId(values?.cosmos_response_id)
    fetchData({ pageSize, pageIndex }, JSON.stringify(columnFilters))
    }
  } 
  const handlePrevious = () => { 
    if(namesArray?.length === 1){ 
      toast("Only one response available", toastTopRightError)
    }
    else {
    currentIndex = (currentIndex - 1 + namesArray.length) % namesArray?.length;
    let values = namesArray[currentIndex]
    setUserEmail(values?.user_email)
    setCosmosId(values?.cosmos_response_id)
    fetchData({ pageSize, pageIndex }, JSON.stringify(columnFilters))
    }

  }
  let userFetchTimeOut

  const table_name = 'USER'
  const columnMetadata = {
    USER: {
      fields: [
        {
          name: 'user_email',
          headerTitle: 'USER EMAIL',
          is_editable: true,
          is_filterable: true,
          size: 20,
        },
        {
          name: 'created_on',
          headerTitle: 'FILLED ON',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
        {
          name: 'status',
          headerTitle: 'STATUS',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
      ],
      tag: 'user',
    },
  }
  const hiddenColumns = {
    USER: [
      'KH_UID',
      'KH_ETL_IS_DELETED',
      'KH_MODIFIED_ON',
      'KH_MODIFIED_BY',
      'KH_CREATED_ON',
      'KH_CREATED_BY',
    ],
  }

  useEffect(() => {
    localStorage.setItem('menu', 'badge-users')
  }),
    []

  const fetchData = async (
    { pageSize = 50, pageIndex = 1 },
    filters = [],
    sort_value = columnSort
  ) => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    setLocalPageIndex(pageIndex)

    const params = {
      page_size: pageSize,
      page_number: pageIndex + 1, //handle 0 indexing
      filters: filters || JSON.stringify(columnFilters),
      sort: sort_value,
      survey_id: surveyId,
    }
    dispatch(getSurveyResponse(endpoint, params))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
  }
  useEffect(() => {
    let table_data = getSurveyResponseData?.getSurveyResponseData?.data
    if (table_data?.length > 0) {
      setTableColumns(
        TableHandler.createColumnMappingforKholab(
          ['user_email', 'filled_on', 'question_attemted'],
          columnMetadata,
          table_name
        )
      )
      setTotalPages()
      setTotalRows()
      setTableData(table_data)
    } else {
      setTotalPages(0)
      setTotalRows(0)
      setTableData([])
    }
  }, [setTableData, getSurveyResponseData])

  const handleShowUserModal = (value) => {
    setshowUserModal(true)
  }

  const handleCloseModal = () => {
    setshowModal(false)
  }

  const handleCloseUserModal = () => {
    setshowUserModal(false)
  }
  const handleQuill = (value) => {
    setEditorContent(value)
  }
  const quillSearch = (searchTerm, renderList, mentionChar) => {
    clearTimeout(userFetchTimeOut)
    if (searchTerm.length >= 3) {
      userFetchTimeOut = setTimeout(() => {
        ForumHandler.fetchUserData(
          //   endpoint,
          searchTerm,
          renderList,
          mentionChar
        )
      }, 1000)
    }
  }

  const module = MentionHandler.mentionconfig(quillSearch)
  const handleSubmit = async () => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })
    const regex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
    const matches = editorContent.match(regex)
    let mentionVal = matches ? matches.map((mention) => mention.slice(0)) : []
    const payload = {
      //   survey_group_id: groupId,
      user_emails: mentionVal,
    }
      // dispatch(addUserstoSurveyGroup(endpoint, payload))
      //   .then(() => {
      //     setLoadingStatus({
      //       status: false,
      //       message: 'Fetching table records...',
      //     })

      //     setshowUserModal(false)

      //     fetchData({ pageSize, pageIndex }, JSON.stringify(columnFilters))
      //   })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
  }

  //handle sorting on columns by updating fetch data sort value
  const handleSorting = (header) => {
    setSort(true)

    if (columnSort.length !== 0 && columnSort[0].field !== header) {
      setColumnSortValue([
        { field: `${header}`, direction: columnSort[0].direction },
      ])
    } else {
      if (columnSort.length === 0) {
        setColumnSortValue([{ field: `${header}`, direction: 'asc' }])
      } else if (columnSort[0].direction === 'asc') {
        setColumnSortValue([{ field: `${header}`, direction: 'desc' }])
      } else {
        setColumnSortValue([])
      }
    }
  }
  const handleOpen = (formData) => {
    setCosmosId(formData.original.cosmos_response_id)
    setUserEmail(formData.original.user_email)
    handleShowUserModal()
  }

  // contains list of setTimout ids
  let timeoutIds = []

  // clears all the setTimeout from timeoutIds array
  const clearAllTimeout = () => {
    for (let i = 0; i < timeoutIds.length; i++) {
      clearTimeout(timeoutIds[i])
    }
    timeoutIds = []
  }
  // Custome Checkbox component
  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      )
    }
  )

  // Deafult Column

  function DefaultColumnFilter({
    column: { filterValue, setFilter },
    headers,
  }) {
    const [initLoad, setInitLoad] = React.useState(true)

    useEffect(() => {
      if (initLoad === true) {
        return
      }
      clearAllTimeout()

      let timeoutId = setTimeout(() => {
        let filter_payload = []
        headers.forEach((header_obj, index) => {
          if (header_obj.filterValue !== undefined && index >= 1) {
            let multipleFilter = header_obj.filterValue.split(',')
            multipleFilter.forEach((value) => {
              if (value.length !== 0) {
                if (value[0] === '"' && value[value.length - 1] === '"') {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'like' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] =
                      '%' + value.slice(1, value.length - 1) + '%'
                    filter_payload.push(local_obj)
                  }
                } else if (
                  value[0] === '*' &&
                  value[value.length - 1] === '*'
                ) {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = value.slice(1, value.length - 1)
                    filter_payload.push(local_obj)
                  }
                } else {
                  let local_obj = { op: 'ilike' }
                  local_obj['field'] = header_obj.id
                  local_obj['value'] = '%' + value + '%'
                  filter_payload.push(local_obj)
                }
              } else {
                let local_obj = { op: 'ilike' }
                local_obj['field'] = header_obj.id
                local_obj['value'] = '%' + value + '%'
                filter_payload.push(local_obj)
              }
            })
          }
        })
        setInitLoad(true)
        setColumnFilters(filter_payload)
        fetchData({ pageSize, pageIndex }, JSON.stringify(filter_payload))
      }, 2200)

      timeoutIds.push(timeoutId)
    }, [filterValue, pageSize])

    return (
      <input
        className="form-control"
        value={filterValue || ''}
        onFocus={() => {
          setInitLoad(false)
        }}
        placeholder="type to search..."
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
      />
    )
  }
  const defaultColumn = {
    Filter: DefaultColumnFilter,
  }

  const available_page_size = ['50', '100', '200', '500']
  const data = useMemo(() => tableData, [hiddenColumns, tableData])
  const columns = useMemo(() => tableColumns, [hiddenColumns, tableColumns])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: localPageIndex,
        hiddenColumns: hiddenColumns[table_name],
        pageSize: 50,
        selectedRowIds: { 1: false },
      },
      manualPagination: true,
      manualFilters: true,
      pageCount: totalPages,
      autoResetSortBy: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns])
    }
  )

  // used for manual pagination
  const onNext = () => {
    gotoPage(pageIndex + 1)
  }
  const onPrevious = () => {
    gotoPage(pageIndex - 1)
  }
  const handleClickCheckbox = (e) => {
    if (e.target.checked) {
      setCheckBox(true)
    } else {
      setCheckBox(false)
    }
  }
  // updates editRows state on select checkbox event
  const handleDeclineClickCheckbox = (e) => {
    if (e.target.checked) {
      setDeclineCheck(true)
    } else {
      setDeclineCheck(false)
    }
  }
 
  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })
    if (declineCheck == false) {
      fetchData(
        { pageSize, pageIndex },
        JSON.stringify([{ op: 'eq', field: 'status', value: 'Completed' }]),
        JSON.stringify(columnSort)
      )
    } else {
      fetchData(
        { pageSize, pageIndex },
        JSON.stringify([{ op: 'eq', field: 'status', value: 'Not filled' }]),
        JSON.stringify(columnSort)
      )
    }
  }, [pageSize, pageIndex, columnSort, declineCheck])
  // updates localStorage with new list of hiddenColumns
  useEffect(() => {
    localStorage.setItem('hidden_columns', JSON.stringify(hiddenColumns))
  }, [hiddenColumns])

  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
          <div style={{ background: '#fff' }}>
            <div style={{ display: 'flex', padding: '10px' }}>
              <input
                type="checkbox"
                onClick={handleDeclineClickCheckbox}
                checked={declineCheck}
              />
              <p style={{ marginLeft: '5px' }}>
                {' '}
                Show declined survey responses
              </p>
            </div>
            <div className="table-container" style={{ textAlign: 'center' }}>
              <Table {...getTableProps()}>
                <thead className="sticky-table-header">
                  {headerGroups.map((headerGroup, i) => (
                    <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, i) =>
                        column?.id === 'selection' ? (
                          <th key={column.id}>
                            <div
                              {...column.getHeaderProps(
                                column.getSortByToggleProps(),
                                { style: { width: column.size } }
                              )}
                              className="table-header-badge"
                            >
                              {column.render('Header')}

                              {columnSort[0]?.field === column['Header'] ? (
                                <span>
                                  {columnSort[0]?.direction === 'asc'
                                    ? ' 🔽'
                                    : '' || columnSort[0]?.direction === 'desc'
                                    ? ' 🔼'
                                    : ''}
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                            <div>
                              {column.canFilter
                                ? column.render('Filter')
                                : null}
                            </div>
                          </th>
                        ) : column.id == 'is_active' ? (
                          <th key={i}>
                            <div className="table-header-badge">
                              {column.render('Header')}
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                onClick={handleClickCheckbox}
                                checked={checkBox}
                              />
                            </div>
                          </th>
                        ) : (
                          <th key={i}>
                            <div
                              onClick={() => handleSorting(column.id)}
                              className="table-header-badge"
                            >
                              {column.render('Header')}

                              {columnSort[0]?.field === column['id'] ? (
                                <span>
                                  {columnSort[0]?.direction === 'asc'
                                    ? ' 🔽'
                                    : '' || columnSort[0]?.direction === 'desc'
                                    ? ' 🔼'
                                    : ''}
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                            <div>
                              {column.canFilter
                                ? column.render('Filter')
                                : null}
                            </div>
                          </th>
                        )
                      )}
                    </tr>
                  ))}
                </thead>
                <tbody
                  className="tbody npsgroupmembers"
                  {...getTableBodyProps()}
                >
                  {page.map((row, index) => {
                    prepareRow(row)
                    return (
                      <TooltipHover
                        key={index}
                        placement="bottom"
                        arrow
                        title={
                          declineCheck == false
                            ? 'Click to view survey response'
                            : 'Survey not filled'
                        }
                      >
                        <tr
                          key={index}
                          onClick={() => {
                            declineCheck == false ? handleOpen(row) : <></>
                          }}
                        >
                          {row.cells.map((cell) => {
                            return cell.column.id === 'is_active' ? (
                              cell.value == true ? (
                                <td
                                  key={cell}
                                  {...cell.getCellProps({
                                    style: {
                                      width: cell.column.size + '%',
                                    },
                                  })}
                                >
                                  <MdOutlineDone
                                    style={{
                                      fontSize: '25px',
                                      color: 'green',
                                    }}
                                  />
                                </td>
                              ) : (
                                <td
                                  key={cell}
                                  {...cell.getCellProps({
                                    style: {
                                      width: cell.column.size + '%',
                                    },
                                  })}
                                >
                                  <MdHighlightOff
                                    style={{
                                      fontSize: '25px',
                                      color: 'red',
                                    }}
                                  />
                                </td>
                              )
                            ) : cell.column.id == 'created_on' ? (
                              <td
                                key={cell}
                                {...cell.getCellProps({
                                  style: {
                                    width: cell.column.size + '%',
                                  },
                                })}
                              >
                                {DateHandler.dateTimetoDateConverter(
                                  cell.value
                                )}
                              </td>
                            ) : (
                              <td
                                key={cell}
                                {...cell.getCellProps({
                                  style: {
                                    width: cell.column.size + '%',
                                  },
                                })}
                              >
                                {cell.render('Cell')}
                              </td>
                            )
                          })}
                        </tr>
                      </TooltipHover>
                    )
                  })}
                </tbody>
              </Table>
            </div>
            {/* Bottom ToolBar of table */}
            <Row>
              <Col sm={6}>
                <div className="page-control">
                  <div className="page-of">
                    Page{' '}
                    <em>
                      {pageIndex + 1} of {totalPages}
                    </em>
                  </div>
                  <div className="prev-next-btn">
                    <button
                      className="badge-btn"
                      onClick={() => onPrevious()}
                      disabled={!canPreviousPage}
                    >
                      {' '}
                      Prev{' '}
                    </button>
                    <button
                      onClick={() => onNext()}
                      className="badge-btn"
                      disabled={!canNextPage}
                    >
                      {' '}
                      Next{' '}
                    </button>
                  </div>
                  <div className="second-control">
                    <span>Go to page:</span>
                    <input
                      className="page-number-input"
                      type="number"
                      defaultValue={pageIndex || 1}
                      onBlur={(e) => {
                        const page = e.target.value ? Number(e.target.value) : 0
                        // handling zero indexing
                        gotoPage(page - 1)
                      }}
                    />
                    <select
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(Number(e.target.value))
                      }}
                    >
                      {available_page_size.map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div
                    style={{
                      display: 'inline-block',
                      marginLeft: '24px',
                      fontWeight: 600,
                      fontSize: '14px',
                    }}
                  >
                    {' '}
                    Total results:{' '}
                    {
                      getSurveyResponseData?.getSurveyResponseData
                        ?.total_results
                    }
                  </div>
                </div>
              </Col>
              {/* Bottom-right Buttons */}
              {/* <Col sm={6}>
                {editRows.length > 0 ? (
                  <Button
                    className="m_r-5 main-button btn-sm"
                    style={{ marginTop: '10px' }}
                    onClick={handleShowUserModal}
                  >
                    View survey feedback
                  </Button>
                ) : (
                  <></>
                )}
              </Col> */}
            </Row>

            <Modal size="xl" show={addusermodal} onHide={handleCloseUserModal}>
              <Modal.Header closeButton>
                <Modal.Title>
                  View Survey Response of <i>{userEmail}</i>
                  <Button style={{ marginLeft: "10px"}} onClick={handlePrevious}> Previous</Button>

                  <Button style={{ marginLeft: "10px"}} onClick={handleNext}> Next</Button>
                </Modal.Title>
              </Modal.Header>

              <Modal.Body className="modalBody">
                <Form>
                  <Row className="rowFlex" style={{ marginBottom: '20px' }}>
                    <Form.Group
                      className=""
                      as={Col}
                      controlId="validationFormik01"
                    >
                      <>
                        <UserResponseForm cosmosId={cosmosId} />
                      </>
                    </Form.Group>
                  </Row>
                </Form>
              </Modal.Body>
            </Modal>
          </div>
        </>
      )}
    </>
  )
}
