import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Navbar from '../../Navbar'
import NpsMenu from './npsMenu'
import HelpContent from './helpContent'

export default function index() {
  return (
    <>
      <Navbar title="NPS" HelpContent={HelpContent} info ="Give feedback! See insights from product owners, and stakeholders!"/>
      <NpsMenu />
      <div className="home-nps" style={{ flexDirection: 'column', }}>
        <div class="instructions">
          <ul>
            <li>
              Create Form: You can create new survey by clicking this button. You can choose to set your survey as private/public. Private surveys will be only accessible by the survey group members. Public surveys are accessible to all.
            </li>
            <ul>
              <li>
                The survey email templates can leverage
                supported Placeholders (values that are dynamically
                populated). Supported placeholders can be viewed inside
                the email notification design views (Placeholder button).
              </li>
              <li>
                NOTE: Design your questions to be clear, concise,
                and easy to understand. Avoid leading questions
                and bias.
              </li>
            </ul>
            <li>
              Email templates are also available for the initial email
              communication about the survey as well as the reminder
              emails for non-responders. These can be customized as
              necessary.
            </li>
            <li>
              Scheduling: Select a date range where the survey will be
              active. By default automated emails will be sent on the
              start date and automated reminders (to non-responders)
              will be sent based on number of days specified before
              the end date (will be sent daily until the end date).
            </li>
          </ul>
        </div>
        <div>
          <Link to="/create-survey">
            <Button variant="primary" style={{ width: '300px', margin: '8px' }}>
             Create Survey
            </Button>{' '}
          </Link>
          {/* <Link to="/view-surveys">
            <Button variant="secondary" style={{ width: '300px', margin: '8px' }}>
              View Forms
            </Button>
          </Link> */}
        </div>
      </div>
    </>
  )
}
