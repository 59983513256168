import React, { Component }  from 'react';
import {
  ResponsiveContainer, ComposedChart, CartesianGrid, XAxis, YAxis,Cell,
  Tooltip, Bar, Line
} from 'recharts';

class DynamicComposedChart extends Component {
    static defaultProps = {
      yAxisDomain: [-100, 100],
      yAxisTicks: Array.from({ length: 21 }, (_, i) => i * 10 - 100),
      barColors: {},
      lineColor: "blue",
      minPointSize: 10,
    };
  
    render() {
      const {
        data,
        tooltipContent,
        intervals,
        xAxisKey,
        yAxisLabel,
        yAxisDomain,
        yAxisTicks,
        barDataKeys,
        barColors,
        lineDataKey,
        lineColor,
        minPointSize,
      } = this.props;
  
      return (
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart data={data} barCategoryGap={40}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={xAxisKey} />
            <YAxis
              domain={yAxisDomain}
              ticks={yAxisTicks}
              interval={intervals} 
              label={{
                value: yAxisLabel,
                angle: -90,
                position: 'insideLeft',
                style: { textAnchor: 'middle' },
              }}
            />
            <Tooltip content={tooltipContent} />
  
            {barDataKeys.map((key, i) => (
              <Bar
                key={`bar-${i}`}
                dataKey={key}
                stackId="a"
                minPointSize={minPointSize}
              >
                {data?.map((record, index) => {
                  const isZero = record[key] === 0;
                  return (
                    <Cell
                      key={`cell-${index}-${i}`}
                      fill={isZero ? '#ffff' : (barColors[key] || '#cccccc')}
                    />
                  );
                })}
              </Bar>
            ))}
  
            {lineDataKey && (
              <Line
                type="monotone"
                dataKey={lineDataKey}
                stroke={lineColor}
                strokeWidth={3}
                dot={{ r: 5 }}
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      );
    }
  }

export {DynamicComposedChart}
