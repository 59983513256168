import React from 'react'
import Modal from 'react-bootstrap/Modal'
import LoadingStatus from '../../Helper/LoadingStatus'
import { Button } from 'react-bootstrap'
import {AnnouncementCategoryLabel } from '../Announcements/announcementFunctions'
import parse from 'html-react-parser'
import Link from '@mui/material/Link'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

const AnnouncementItemModal = ({
    showAnnouncementItemModal,
    setShowAnnouncementItemModal,
    announcementItem,
    announcementTitle,
    BootstrapTooltip,
    handleExternalLink
}) => {
    const loadingMsg = 'Fetching Announcement Detail...'

    return (
        <Modal size="lg" show={showAnnouncementItemModal} onHide={() => setShowAnnouncementItemModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{announcementTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
                {(announcementItem && announcementItem.id) ? (
                    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                        <div className='announcement-header-popup'>
                            <span className='announcement-header-popup-start'>
                                {AnnouncementCategoryLabel(announcementItem.category)}
                                {announcementItem.attachments_path && (
                                    <BootstrapTooltip title="Click to Open Extenal Link">
                                        <Link onClick={(e) => handleExternalLink(e, announcementItem.attachments_path)} className="external-link-button">
                                            <OpenInNewIcon /> Link
                                        </Link>
                                    </BootstrapTooltip>
                                )}
                            </span>
                            <span className='audience-announcement-popup'>{announcementItem.target_audience == 'kholab' ? 'Kholab (General)':announcementItem.target_audience}</span>
                        </div>                                   
                    </div>
                ) : <div style={{minHeight:'10em'}}><LoadingStatus status_message={loadingMsg} /></div>}
                
                {(announcementItem && announcementItem.description) ? parse(announcementItem.description) : '' }
                <span className='announcement-sender announcement-sender-popup'>- {announcementItem?.created_by.split('@')[0]} (Announcer)</span>
                <Modal.Footer>
                    <Button variant="danger" type="submit" className="addModalBtn"
                    onClick={()=>setShowAnnouncementItemModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
                    
            </Modal.Body>
        </Modal>
    )
}

export default AnnouncementItemModal;
