import * as React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import jwt_decode from 'jwt-decode'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import SummaryHelpContent from '../Utilities/summaryHelpContent'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import GroupsIcon from '@mui/icons-material/Groups'
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'
import CampaignIcon from '@mui/icons-material/Campaign'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import DashboardIcon from '@mui/icons-material/Dashboard'
import AnalyticsIcon from '@mui/icons-material/Analytics'
import RuleIcon from '@mui/icons-material/Rule'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import CelebrationIcon from '@mui/icons-material/Celebration'
import WorkIcon from '@mui/icons-material/Work'
import { MdHelpOutline } from 'react-icons/md'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { Button, Badge } from 'react-bootstrap'
import Slide from '@mui/material/Slide'
import { useNavigate } from 'react-router-dom'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
import './appbar.css'
import SendKudosForm from '../Kudos/sendKudos'
import CollabHelperComponent from '../Collab/components/HelperComponent'
import BadgeHelperComponent from '../Badge/HelperComponent'
import GlossaryHelperComponent from '../Glossary/HelperComponent'
import PodsHelperComponent from '../Pods/HelperComponent'
import IdeasHelpContent from '../Ideas/HelperComponent'
import ChapterNpsHelpContent from '../ChapterNPS/helpContent'
import KpiHelperComponent from '../Kpis/HelperComponent'

const drawerWidth = 240

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: '1px',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    marginTop: '63px',
    zIndex: '0 !important',

    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
)

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: '#1d3b6d !important',
  marginTop: '61px',
  position: 'absolute !important',
  zIndex: '0 !important',
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    backgroundColor: '#1d3b6d !important',
    marginTop: '61px',
    position: 'absolute !important',
    zIndex: '0 !important',
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const DrawerHeader = styled('div')(() => ({
  // display: 'flex',
  // alignItems: 'center',
  // padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
  // justifyContent: 'flex-end',

  position: 'fixed',
  top: '61vh',
  left: '201px',
  zIndex: '99999',
  backgroundColor: '#f0f1f2',
  padding: '3px',
}))

export default function PersistentDrawerLeft({
  Page,
  setChannel,
  setBadgeFilter,
  channel,
  Index,
  collabMetadata,
  setCurrentPage,
  ifMyIdeas = '',
  setIfMyIdeas = '',
  ifMyLikedIdeas = '',
  setIfMyLikedIdeas = '',
  kudoModal = false,
  setkudoModal = '',
  totalCount = 0,
}) {
  const theme = useTheme()
  const [open, setOpen] = React.useState(true)
  const [openModal, setOpenModal] = React.useState(false)
  const [openModalCollab, setOpenModalCollab] = React.useState(false)
  const [openModalPods, setOpenModalPods] = React.useState(false)
  const [openModalNps, setOpenModalNps] = React.useState(false)
  const [openModalIdeas, setOpenModalIdeas] = React.useState(false)
  const [roleValue, setRolevalue] = React.useState(false)
  const [selectedIndex, setSelectedIndex] = React.useState(0)

  let tokenValue
  let tokenValues = localStorage.getItem('token')
  if (tokenValues) {
    tokenValue = jwt_decode(tokenValues)
  }
  const [kudoModalCollab, setKudoModalCollab] = React.useState(kudoModal)
  React.useEffect(() => {
    setKudoModalCollab(kudoModal)
  }, [kudoModal])
  React.useEffect(() => {
    if (typeof setkudoModal === 'function') {
      setkudoModal(kudoModalCollab)
    }
  }, [kudoModalCollab])

  const descriptionElementRef = React.useRef(null)

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  const handleOpenKudoModalCollab = () => {
    setKudoModalCollab(true)
  }

  const handleCloseKudoModalCollab = () => {
    setKudoModalCollab(false)
  }

  const handleClickOpen = () => {
    setOpenModal(true)
  }

  const handleClose = () => {
    setOpenModal(false)
  }
  const handleClickOpenCollab = () => {
    setOpenModalCollab(true)
  }

  const handleCloseCollab = () => {
    setOpenModalCollab(false)
  }

  const handleClickOpenPods = () => {
    setOpenModalPods(true)
  }

  const handleClosePods = () => {
    setOpenModalPods(false)
  }
  const handleClickOpenNps = () => {
    setOpenModalNps(true)
  }

  const handleCloseNps = () => {
    setOpenModalNps(false)
  }
  const history = useNavigate()
  const handleClick = (path) => {
    history(path)
  }
  const handleClickOpenIdeas = () => {
    setOpenModalIdeas(true)
  }

  const handleCloseIdeas = () => {
    setOpenModalIdeas(false)
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleChannelDropdown = (value) => {
    setChannel(value)
    localStorage.setItem('channel', value)
  }

  const handleDropdown = (value) => {
    setChannel(value)
    localStorage.setItem('channel', value)
    setBadgeFilter('')
  }
  const handleDropdownCollab = (value) => {
    localStorage.setItem('channel', value)
    setChannel(event.target.value)
    setCurrentPage(0)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  React.useEffect(() => {
    switch (true) {
      case window.location.pathname.startsWith('/kholab_home'):
        setSelectedIndex(9)
        break
      case window.location.pathname.startsWith('/forum'):
        setSelectedIndex(0)
        break
      case window.location.pathname.startsWith('/badge'):
        setSelectedIndex(1)
        break
      case window.location.pathname.startsWith('/glossary'):
        setSelectedIndex(2)
        break
      case window.location.pathname.startsWith('/users'):
        setSelectedIndex(3)
        break
      case window.location.pathname.startsWith('/pods') ||
        window.location.pathname.startsWith('/pod/detail'):
        setSelectedIndex(5)
        break
      case window.location.pathname.startsWith('/ideas'):
        setSelectedIndex(6)
        break
      case window.location.pathname.startsWith('/jobs'):
        setSelectedIndex(7)
        break
      case window.location.pathname.startsWith('/nps-survey'):
        setSelectedIndex(8)
        break
      case window.location.pathname.startsWith('/announcements'):
        setSelectedIndex(10)
        break
      case window.location.pathname.startsWith('/kpis'):
        setSelectedIndex(11)
        break
      default:
        break
    }
  }, [])
  React.useEffect(() => {
    if (
      tokenValue?.roles?.includes('khdapi.Admin') ||
      tokenValue?.roles?.includes('khdapi.collabadmin')
    ) {
      setRolevalue(true)
    } else {
      setRolevalue(false)
    }
  }, [])
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index)
    if (index === 0) {
      window.location.href = '/forum'
    } else if (index === 1) {
      window.location.href = '/badge'
    } else if (index === 2) {
      window.location.href = '/glossary'
    } else if (index === 3) {
      window.location.href = '/users'
    } else if (index === 5) {
      window.location.href = '/pods'
    } else if (index === 6) {
      handleClick('/ideas')
    } else if (index === 7) {
      window.location.href = '/jobs'
    } else if (index === 8) {
      window.location.href = '/nps-survey/view-surveys'
    } else if (index === 9) {
      window.location.href = '/kholab_home'
    } else if (index === 10) {
      window.location.href = '/announcements'
    } else if (index === 11) {
      window.location.href = '/kpis'
    }
  }

  return Index !== undefined ? (
    <Box className="box" sx={{ display: 'flex', minHeight: '100vh' }}>
      <CssBaseline />
      {Page === 'dashboard' || Page === 'pod_detail_page' ? (
        <></>
      ) : (
        <AppBar position="fixed" open={open}>
          <Toolbar className={Page === 'Ideas' ? 'ideas_toolbar' : ''}>
            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{ mr: 2, ...(open && { display: 'none' }) }}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap component="div">
                  {Page}
                  {Page === 'Badge' ||
                  Page === 'My Badges' ||
                  Page === 'Admin Badge' ||
                  Page === 'Pending Requests' ? (
                    <>
                      <select
                        style={{
                          padding: '2px',
                          margin: '5px',
                          borderRadius: '12px',
                          fontSize: '13px',
                        }}
                        value={channel}
                        onChange={(e) => handleDropdown(e.target.value)}
                      >
                        {JSON.parse(localStorage.getItem('badge_metadata'))
                          ?.channels?.sort()
                          ?.map?.((chapter, index) => {
                            return (
                              <option value={chapter} key={index}>
                                {chapter}
                              </option>
                            )
                          })}
                      </select>
                    </>
                  ) : (
                    <> </>
                  )}
                  {Page && Page == 'Q&A Forum' && (
                    <>
                      <select
                        style={{
                          padding: '2px',
                          margin: '5px',
                          borderRadius: '12px',
                          fontSize: '13px',
                        }}
                        value={channel}
                        onChange={(e) => handleDropdownCollab(e.target.value)}
                      >
                        {collabMetadata?.channels
                          ?.sort()
                          ?.map((chapter, index) => {
                            //Check if user is a chapter admin or not if the chapter value is Head of Chapters. In this case we do not want to show user this chapter in dropdown
                            const isHeadOfChapters =
                              chapter === 'Head of Chapters'
                            const isAdmin =
                              collabMetadata?.channel_admin_emails?.includes(
                                tokenValue.preferred_username.toLowerCase()
                              )

                            // Render option conditionally
                            if (isHeadOfChapters && !isAdmin) {
                              return (
                                <>
                                  <option value={chapter} key={index} disabled>
                                    {chapter} [Private]
                                  </option>
                                </>
                              )
                            }

                            return (
                              <option value={chapter} key={index}>
                                {chapter}
                              </option>
                            )
                          })}
                      </select>
                    </>
                  )}
                  {Page && Page == 'Glossary' && (
                    <select
                      style={{
                        padding: '2px',
                        margin: '5px',
                        borderRadius: '12px',
                        fontSize: '13px',
                      }}
                      value={channel}
                      onChange={(e) => handleChannelDropdown(e.target.value)}
                    >
                      {collabMetadata?.channels
                        ?.sort()
                        ?.map((chapter, index) => {
                          return (
                            <option value={chapter} key={index}>
                              {chapter}
                            </option>
                          )
                        })}
                    </select>
                  )}
                </Typography>
                {(Page === 'Ideas' ||
                  Page === 'Review/Approve Ideas' ||
                  Page === 'Job Postings') && (
                  <select
                    style={{
                      padding: '2px',
                      margin: '5px',
                      borderRadius: '4px',
                    }}
                    value={channel}
                    onChange={(e) => handleChannelDropdown(e.target.value)}
                  >
                    {JSON.parse(localStorage.getItem('channel_metadata'))
                      ?.channels?.sort()
                      ?.map?.((chapter, index) => {
                        return (
                          <option value={chapter} key={index}>
                            {chapter}
                          </option>
                        )
                      })}
                  </select>
                )}
                {Page === 'Ideas' && (
                  <div
                    style={{
                      marginLeft: '10px',
                      marginTop: '5px',
                      display: 'flex',
                    }}
                  >
                    <Box sx={{ minWidth: 120 }}>
                      <FormGroup>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip">
                              Show only my ideas, see the ideas you are part of
                              it.
                            </Tooltip>
                          }
                        >
                          <FormControlLabel
                            control={
                              <Switch
                                checked={ifMyIdeas}
                                onChange={(e) =>
                                  setIfMyIdeas(e.currentTarget.checked)
                                }
                                sx={{
                                  paddingRight: '8px',
                                  letterSpacing: '1px',
                                }}
                                color="default"
                                className="ideas_switch"
                              />
                            }
                            label="My Ideas"
                          />
                        </OverlayTrigger>
                      </FormGroup>
                    </Box>
                    <Box sx={{ minWidth: 120 }}>
                      <FormGroup>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip">
                              Click to view only the Ideas liked by me.
                            </Tooltip>
                          }
                        >
                          <FormControlLabel
                            control={
                              <Switch
                                checked={ifMyLikedIdeas}
                                onChange={(e) =>
                                  setIfMyLikedIdeas(e.currentTarget.checked)
                                }
                                sx={{
                                  paddingRight: '8px',
                                  letterSpacing: '1px',
                                }}
                                color="default"
                                className="ideas_switch"
                              />
                            }
                            label="My Liked Ideas"
                          />
                        </OverlayTrigger>
                      </FormGroup>
                    </Box>
                  </div>
                )}
                {Page === 'NPS' && (
                  <select
                    style={{
                      padding: '2px',
                      margin: '5px',
                      borderRadius: '12px',
                    }}
                    value={channel}
                    onChange={(e) => handleChannelDropdown(e.target.value)}
                  >
                    {collabMetadata?.channels?.sort()?.map((chapter, index) => {
                      return (
                        <option value={chapter} key={index}>
                          {chapter}
                        </option>
                      )
                    })}
                  </select>
                )}
              </div>
              {(Page === 'Ideas' ||
                Page === 'Announcements' ||
                Page === 'Job Postings' ||
                Page === 'Badge' ||
                Page === 'Q&A Forum' ||
                Page === 'Glossary' ||
                Page === 'PODs' ||
                Page === 'NPS') && (
                <div
                  style={{
                    display: 'flex',
                    fontSize: '.875rem',
                    lineHeight: '1.25rem',
                    marginLeft: open ? '' : '3.2em',
                  }}
                >
                  {Page === 'PODs'
                    ? 'Get POD team info, easily contact the team, and get insights on what they are delivering!'
                    : Page === 'Q&A Forum'
                    ? 'Ask questions! Share your expertise! Build up our knowledge base!  Collaborate on solutions to challenges!'
                    : Page === 'Badge'
                    ? 'Find mentors and experts!  Request badges to showcase your practical expertise!'
                    : Page === 'Ideas'
                    ? 'Submit! LIKE! Get your Chapter ideas developed!'
                    : Page === 'Glossary'
                    ? 'Learn Kraft Heinz key terms and acronyms! Discover Applications and Solutions! Enrich or correct existing terms as a community!'
                    : Page === 'Job Postings'
                    ? 'Learn about internal job opportunities within and across Chapters!'
                    : Page === 'NPS'
                    ? 'Give feedback! See insights from Chapter members, product owners, and stakeholders!'
                    : Page === 'KPIs'
                    ? 'View critical indicators!'
                    : 'Highlight key events or info for the Kholab community or for a Chapter!'}
                </div>
              )}
            </div>

            <>
              {Page === 'Badge' ||
              Page === 'My Badges' ||
              Page === 'Admin Badge' ||
              Page === 'Pending Requests' ? (
                <>
                  <div style={{ marginLeft: 'auto' }}>
                    <OverlayTrigger
                      overlay={<Tooltip id="tooltip">What is Badges ?</Tooltip>}
                    >
                      <Button
                        aria-label="what is badges ?"
                        variant="outlined"
                        label="fd"
                        onClick={handleClickOpen}
                      >
                        <MdHelpOutline
                          style={{ fontSize: '28px', color: '#F9D949' }}
                        />
                      </Button>
                    </OverlayTrigger>

                    <BadgeHelperComponent
                      openModel={openModal}
                      closeModel={handleClose}
                      transition={Transition}
                    />
                  </div>
                </>
              ) : (
                <>
                  {Page === 'Q&A Forum' ? (
                    <>
                      <div style={{ marginLeft: 'auto' }}>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip">What is Q&A Forum ?</Tooltip>
                          }
                        >
                          <>
                            <Button
                              aria-label="what is Collab ?"
                              variant="outlined"
                              label="fd"
                              onClick={handleClickOpenCollab}
                            >
                              <MdHelpOutline
                                style={{ fontSize: '28px', color: '#F9D949' }}
                              />
                            </Button>
                          </>
                        </OverlayTrigger>

                        {/* HelperCoponent */}
                        <CollabHelperComponent
                          openModel={openModalCollab}
                          closeModel={handleCloseCollab}
                          transition={Transition}
                        />
                      </div>
                    </>
                  ) : Page === 'Glossary' ? (
                    <>
                      <div style={{ marginLeft: 'auto' }}>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip">
                              What is the Glossary?
                            </Tooltip>
                          }
                        >
                          <>
                            <Button
                              aria-label="What is the Glossary?"
                              variant="outlined"
                              label="fd"
                              onClick={handleClickOpenCollab}
                            >
                              <MdHelpOutline
                                style={{ fontSize: '28px', color: '#F9D949' }}
                              />
                            </Button>
                          </>
                        </OverlayTrigger>

                        <GlossaryHelperComponent
                          openModel={openModalCollab}
                          closeModel={handleCloseCollab}
                          transition={Transition}
                        />
                      </div>
                    </>
                  ) : Page === 'KPIs' ? (
                    <>
                      <select
                        style={{
                          padding: '2px',
                          margin: '5px',
                          borderRadius: '12px',
                        }}
                        value={channel}
                        onChange={(e) => handleChannelDropdown(e.target.value)}
                      >
                        {collabMetadata?.channels
                          ?.sort()
                          ?.map((chapter, index) => {
                            return (
                              <option value={chapter} key={index}>
                                {chapter}
                              </option>
                            )
                          })}
                      </select>

                      <div style={{ marginLeft: 'auto' }}>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip">What are KPIs ?</Tooltip>
                          }
                        >
                          <Button
                            aria-label="what are KPIs?"
                            variant="outlined"
                            label="fd"
                            onClick={handleClickOpen}
                          >
                            <MdHelpOutline
                              style={{ fontSize: '28px', color: '#F9D949' }}
                            />
                          </Button>
                        </OverlayTrigger>

                        <KpiHelperComponent
                          openModel={openModal}
                          closeModel={handleClose}
                          transition={Transition}
                        />
                      </div>
                    </>
                  ) : Page === 'PODs' || Page == 'pods-pending-request' ? (
                    <>
                      <div style={{ marginLeft: 'auto' }}>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip">What is the Pods?</Tooltip>
                          }
                        >
                          <>
                            <Button
                              aria-label="What is the Pods?"
                              variant="outlined"
                              label="fd"
                              onClick={handleClickOpenPods}
                            >
                              <MdHelpOutline
                                style={{ fontSize: '28px', color: '#F9D949' }}
                              />
                            </Button>
                          </>
                        </OverlayTrigger>

                        <PodsHelperComponent
                          openModel={openModalPods}
                          closeModel={handleClosePods}
                          descriptionElementRef={descriptionElementRef}
                        />
                      </div>
                    </>
                  ) : Page === 'Ideas' ||
                    Page === 'View Idea Detail' ||
                    Page === 'Review/Approve Ideas' ? (
                    <div style={{ marginLeft: 'auto' }}>
                      {Page === 'Review/Approve Ideas' && roleValue && (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip">
                              Click to go to Ideas?
                            </Tooltip>
                          }
                        >
                          <Button
                            size="medium"
                            variant="default"
                            onClick={() => handleClick('/ideas')}
                            className="manage-button"
                          >
                            <LightbulbIcon /> Ideas
                          </Button>
                        </OverlayTrigger>
                      )}
                      {Page === 'Ideas' && roleValue && (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip">
                              Click to Review and Approve / Reject the new Ideas
                              Created.
                            </Tooltip>
                          }
                        >
                          <Button
                            size="medium"
                            variant="default"
                            onClick={() => handleClick('/ideas/manage-ideas')}
                            className="manage-button"
                          >
                            <RuleIcon /> Review/Approve Ideas{' '}
                            <Badge bg="primary">{totalCount}</Badge>{' '}
                          </Button>
                        </OverlayTrigger>
                      )}

                      <OverlayTrigger
                        overlay={<Tooltip id="tooltip">What is Ideas?</Tooltip>}
                      >
                        <Button
                          aria-label="What is Ideas?"
                          variant="outlined"
                          label="fd"
                          onClick={handleClickOpenIdeas}
                        >
                          <MdHelpOutline
                            style={{ fontSize: '28px', color: '#F9D949' }}
                          />
                        </Button>
                      </OverlayTrigger>
                      <IdeasHelpContent
                        openModel={openModalIdeas}
                        closeModel={handleCloseIdeas}
                        descriptionElementRef={descriptionElementRef}
                      />
                    </div>
                  ) : Page == 'NPS' ? (
                    <>
                      <div style={{ marginLeft: 'auto' }}>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip">What is the Nps?</Tooltip>
                          }
                        >
                          <>
                            <Button
                              aria-label="What is the Nps?"
                              variant="outlined"
                              label="fd"
                              onClick={handleClickOpenNps}
                            >
                              <MdHelpOutline
                                style={{ fontSize: '28px', color: '#F9D949' }}
                              />
                            </Button>
                          </>
                        </OverlayTrigger>

                        <ChapterNpsHelpContent
                          openModel={openModalNps}
                          closeModel={handleCloseNps}
                          descriptionElementRef={descriptionElementRef}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          </Toolbar>
        </AppBar>
      )}
      <Drawer
        className="drawer"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          {/* <div className="kholab_logo">
            <img src={Kholab} alt="kholab logo" />
          </div> */}
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        {/* <Divider /> */}
        <List>
          {/* <Link to="/app-home">  <ListItem key="Home" disablePadding onClick={showhomeFunc}>
            <ListItemButton>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItemButton>
          </ListItem></Link> */}
          <ListItem
            key="Kholab-dashboard"
            disablePadding
            selected={selectedIndex === 9}
            onClick={(event) => handleListItemClick(event, 9)}
          >
            <ListItemButton>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="HOME" />
            </ListItemButton>
          </ListItem>

          <ListItem
            key="pods"
            disablePadding
            selected={selectedIndex === 5}
            onClick={(event) => handleListItemClick(event, 5)}
          >
            <ListItemButton>
              <ListItemIcon>
                <SupervisedUserCircleIcon />
              </ListItemIcon>
              <ListItemText primary="PODs" />
            </ListItemButton>
          </ListItem>

          <ListItem
            key="Collab"
            disablePadding
            selected={selectedIndex === 0}
            onClick={(event) => handleListItemClick(event, 0)}
          >
            <ListItemButton>
              <ListItemIcon>
                <GroupsIcon />
              </ListItemIcon>
              <ListItemText primary="Q&A Forum" />
              {/* {openSubmenuBadge ? <ExpandLess /> : <ExpandMore />} */}
            </ListItemButton>
          </ListItem>
          <ListItem
            key="Badge"
            disablePadding
            selected={selectedIndex === 1}
            onClick={(event) => handleListItemClick(event, 1)}
          >
            <ListItemButton>
              <ListItemIcon>
                <MilitaryTechIcon />
              </ListItemIcon>
              <ListItemText primary="Badge" />
              {/* {openSubmenuBadge ? <ExpandLess /> : <ExpandMore />} */}
            </ListItemButton>
          </ListItem>
          {/* pods button */}
          <ListItem
            key="Ideas"
            disablePadding
            selected={selectedIndex === 6}
            onClick={(event) => handleListItemClick(event, 6)}
          >
            <ListItemButton>
              <ListItemIcon>
                <LightbulbIcon />
              </ListItemIcon>
              <ListItemText primary="Ideas" />
            </ListItemButton>
          </ListItem>
          <ListItem
            key="Glossary"
            disablePadding
            selected={selectedIndex === 2}
            onClick={(event) => handleListItemClick(event, 2)}
          >
            <ListItemButton>
              <ListItemIcon>
                <LibraryBooksIcon />
              </ListItemIcon>
              <ListItemText primary="Glossary" />
            </ListItemButton>
          </ListItem>
          <ListItem
            key="Send Kudos!"
            disablePadding
            selected={selectedIndex === 4}
            onClick={handleOpenKudoModalCollab}
          >
            <ListItemButton>
              <ListItemIcon>
                <CelebrationIcon />
              </ListItemIcon>
              <ListItemText primary="Send Kudos!" />
            </ListItemButton>
          </ListItem>
          <ListItem
            key="Job Postings"
            disablePadding
            selected={selectedIndex === 7}
            onClick={(event) => handleListItemClick(event, 7)}
          >
            <ListItemButton>
              <ListItemIcon>
                <WorkIcon />
              </ListItemIcon>
              <ListItemText primary="Job Postings" />
            </ListItemButton>
          </ListItem>
          <ListItem
            key="Chapter-nps"
            disablePadding
            selected={selectedIndex === 8}
            onClick={(event) => handleListItemClick(event, 8)}
          >
            <ListItemButton>
              <ListItemIcon>
                <CampaignIcon />
              </ListItemIcon>
              <ListItemText primary="NPS" />
            </ListItemButton>
          </ListItem>
          <ListItem
            key="KPIs"
            disablePadding
            selected={selectedIndex === 11}
            onClick={(event) => handleListItemClick(event, 11)}
          >
            <ListItemButton>
              <ListItemIcon>
                <AnalyticsIcon />
              </ListItemIcon>
              <ListItemText primary="KPIs" />
            </ListItemButton>
          </ListItem>
          {roleValue ? (
            <>
              <ListItem
                key="Users"
                disablePadding
                selected={selectedIndex === 3}
                onClick={(event) => handleListItemClick(event, 3)}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <PeopleAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="Users (Admin Only)" />
                  {/* {openSubmenuBadge ? <ExpandLess /> : <ExpandMore />} */}
                </ListItemButton>
              </ListItem>
              <ListItem
                key="Announcements"
                disablePadding
                selected={selectedIndex === 10}
                onClick={(event) => handleListItemClick(event, 10)}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <NotificationsActiveIcon />
                  </ListItemIcon>
                  <ListItemText primary="Announcements" />
                </ListItemButton>
              </ListItem>
            </>
          ) : (
            <></>
          )}
        </List>
      </Drawer>
      {(Page === 'dashboard' || Page === 'pod_detail_page') && !open ? (
        <DrawerHeader style={{ left: 0, border: '1px solid darkgray' }}>
          <IconButton onClick={handleDrawerOpen}>
            {theme.direction === 'ltr' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
      ) : (
        ''
      )}
      {Page === 'dashboard' || Page === 'pod_detail_page' ? (
        <Main
          open={open}
          style={{ backgroundColor: '#e9ecef', marginTop: '0' }}
        >
          {Index}
        </Main>
      ) : Index !== undefined ? (
        <Main open={open}>
          {(Page === 'Ideas' ||
            Page === 'Job Postings' ||
            Page === 'Badge' ||
            Page === 'Q&A Forum' ||
            Page === 'NPS' ||
            Page === 'PODs' ||
            Page === 'Glossary'||
            Page === 'KPIs') && <SummaryHelpContent Page={Page} />}

          {Index}
        </Main>
      ) : (
        ''
      )}
      <SendKudosForm
        kudosModal={kudoModalCollab}
        handleCloseKudosModal={handleCloseKudoModalCollab}
        setKudoModalCollab={setKudoModalCollab}
        channel={channel}
      />
    </Box>
  ) : (
    <></>
  )
}
