import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { Button } from 'react-bootstrap'
import TabView from '../Utilities/tabView'
import {AnnouncementCategoryLabel } from '../Announcements/announcementFunctions'
import PaginationFeature from '../Utilities/paginationFeature'
import parse from 'html-react-parser'
import Link from '@mui/material/Link'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
const available_page_size = ['10','50', '100', '200', '500']

const AnnouncementPopupModal = ({
    showAnnouncementModal,
    handleCloseAnnouncementModel,
    chapter,
    selectedAnnouncementTab,
    announcementData,
    chapterAnnouncementData,
    kholabPage,
    setKholabPage,
    kholabLimit,
    setKholabLimit,
    totalKholabPages,
    totalKholabAnnouncements,
    channelPage,
    setChannelPage,
    channelLimit,
    setChannelLimit,
    totalChannelPages,
    totalChannelAnnouncements,
    handleExternalLink,
    BootstrapTooltip
}) => {
    const [showMoreSelect, setShowMoreSelect] = React.useState('');
    const handleKholabPageChange = (selectedPage) => {
        setKholabPage(selectedPage.selected)
    }
    const handleChannelPageChange = (selectedPage) => {
        setChannelPage(selectedPage.selected)
    }
    const KholabViewContent = () => {
        return (
            <>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5em'}}>
                {(announcementData && announcementData.length > 0 )? 
                announcementData.map((item, index)=>{
                    const itemDescription = item.description;

                    return (
                        <div key={index} className='announcement-card'>
                            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                <div className='announcement-header-popup'>
                                    <span className='announcement-header-popup-start'>
                                        
                                        {AnnouncementCategoryLabel(item.category)}
                                        {item.attachments_path && (
                                            <BootstrapTooltip title="Click to Open Extenal Link">
                                                <Link className="external-link-button" onClick={(e) => handleExternalLink(e, item.attachments_path)} >
                                                    <OpenInNewIcon /> Link
                                                </Link>
                                            </BootstrapTooltip>
                                        )}
                                    </span>
                                    <span className='announcement-header-popup-end'>
                                        <span className='announcement-sender'>{item.created_by.split('@')[0]}(Announcer)</span>
                                    </span>
                                </div>                                  
                            </div>
                            <div style={{fontWeight: '600'}}>{item.title}</div>
                            {itemDescription.length > 240 ? 
                                showMoreSelect === index ? 
                                <>
                                    {parse(itemDescription)}
                                    <BootstrapTooltip title="Click to expand description">
                                        <Button size="small" variant="outlined" onClick={() => setShowMoreSelect('')} style={{textTransform: 'none', marginBottom: '1em', textDecoration: 'underline',color: '#1b75d0'}}>
                                            show less
                                        </Button>
                                    </BootstrapTooltip>
                                </>
                                : 

                                <>
                                    {parse(itemDescription.substring(0, 240)+'...')} <BootstrapTooltip title="Click to expand description">
                                        <Button size="small" variant="outlined" onClick={() => setShowMoreSelect(index)} style={{textTransform: 'none', textDecoration: 'underline', color: '#1b75d0'}}>
                                            show more
                                        </Button>
                                    </BootstrapTooltip>
                                </>
                            : parse(itemDescription) // if length is not higher display the desc
                            }
                        </div>
                        
                    )
                })
                : <span style={{fontWeight: 600}}>No Announcement Available</span>}
            </div>
            <PaginationFeature 
                totalPages={totalKholabPages}
                handlePageChange={handleKholabPageChange}
                currentPage={kholabPage}
                totalResults={totalKholabAnnouncements}
                pageSize={kholabLimit}
                setPageSize={setKholabLimit}
                availablePageSize={available_page_size}
            />
        </>
        )
    }

    const ChapterViewContent = () => {
        return (
            <>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5em'}}>
                    {(chapterAnnouncementData && chapterAnnouncementData.length > 0 )? 
                    chapterAnnouncementData.map((item, index)=>{
                        return (
                            <div key={index} className='announcement-card'>
                                <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                    <div className='announcement-header-popup'>
                                        <span className='announcement-header-popup-start'>
                                            {AnnouncementCategoryLabel(item.category)}
                                            {item.attachments_path && (
                                                <BootstrapTooltip title="Click to Open Extenal Link">
                                                    <Link className="external-link-button" onClick={(e) => handleExternalLink(e, item.attachments_path)}>
                                                        <OpenInNewIcon /> Link
                                                    </Link>
                                                </BootstrapTooltip>
                                            )}
                                        </span>
                                        <span className='announcement-header-popup-end'>
                                            <span className='announcement-sender'>{item.created_by.split('@')[0]}(Announcer)</span>
                                        </span>
                                        
                                    </div>                     
                                </div>
                                <div style={{fontWeight: '600'}}>{item.title}</div>
                                <p>{item.description}</p>
                            </div>
                        )
                    })
                    : <span style={{fontWeight: 600}}>No Chapter Announcement Available</span>}
                </div>
                <PaginationFeature 
                    totalPages={totalChannelPages}
                    handlePageChange={handleChannelPageChange}
                    currentPage={channelPage}
                    totalResults={totalChannelAnnouncements}
                    pageSize={channelLimit}
                    setPageSize={setChannelLimit}
                    availablePageSize={available_page_size}
                />
            </>
            
            
        )
    }

    const announcementContent = {
        defaultTab: selectedAnnouncementTab,
        dataSet: [
            {
                key: 'kholab',
                title: 'Kholab (General)',
                content: <KholabViewContent />
            },
            {
                key: 'channel',
                title: chapter,
                content: <ChapterViewContent />
            }
        ]
    }

    return(
        <Modal size="xl" show={showAnnouncementModal} onHide={() => handleCloseAnnouncementModel()}>
            <Modal.Header closeButton>
                <Modal.Title>View All Announcements</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
                <TabView dataArr={announcementContent} />
                <Modal.Footer>
                    <Button variant="danger" type="submit" className="addModalBtn"
                    onClick={()=>handleCloseAnnouncementModel()}>
                        Close
                    </Button>
                </Modal.Footer>
                    
            </Modal.Body>
        </Modal>
    )
}

export default AnnouncementPopupModal;
