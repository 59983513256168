import React, { useContext, useEffect } from 'react'
import parse from 'html-react-parser'
import { useParams } from 'react-router-dom'
import {
  getGlossaryTermByID,
  getRelatedTerms,
} from '../../../../redux/actions/glossaryAction'
import { getKholabChapterAdmin } from '../../../../redux/actions/kholabMetadataAction'
import { AuthContext } from '../../../context/authProvider'
import { useDispatch, useSelector } from 'react-redux'
import LoadingStatus from '../../../Helper/LoadingStatus'
import EditIcon from '@mui/icons-material/Edit'
import EditTermModal from '../editTerm'
import { IconButton } from '@mui/material'
import CardList from '../../Utilities/cardList'
import 'react-quill/dist/quill.snow.css'
import { GlossaryHomeButton } from '../GlossaryUtils/glossaryHomeButton'
import KhcLogo from '../../../../media/kraftheinzlogo.png'

export default function ViewTerm({ isAdmin, channel, userEmail }) {
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: true,
    message: 'Fetching table record...',
  })
  const { id } = useParams()
  const [authCreds] = useContext(AuthContext)
  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint
  const glossaryTermByID = useSelector(
    (state) => state.glossaryTermByID.term.data
  )
  const channels = useSelector(
    (state) => state.forumMetadata.forumMetadata.channels
  )
  const isChapterAdmin = useSelector(
    (state) => state.kholabChapterAdmin.chapterAdmin.is_admin
  )
  const token = localStorage.getItem('token')
  const [openModal, setOpenModal] = React.useState(false)
  const relatedTermData = useSelector((state) => state.getGlossaryRelatedTerms)

  const fetchData = async () => {
    setLoadingStatus({ status: true, message: 'Fetching table record...' })
    await Promise.all([
      dispatch(getRelatedTerms(endpoint, id)),
      dispatch(getGlossaryTermByID(endpoint, id)),
      dispatch(getKholabChapterAdmin(endpoint, channel, userEmail)),
    ])
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table record...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table record...',
        })
      })
  }

  useEffect(() => {
    if (token != null) {
      fetchData()
    }
  }, [dispatch, id])

  function DateMetadataSection({ title, by, on }) {
    if (!by || by.length === 0) {
      return (
        <div>
          <strong>{title}:</strong>
        </div>
      )
    }
    return (
      <div>
        <strong>{title}:</strong> {by ? `${by} - ` : ''}
        {on}
      </div>
    )
  }

  function MetadataSection({ title, content }) {
    if (!content) {
      return (
        <div>
          <strong>{title}</strong>
        </div>
      )
    } else {
      return (
        <div>
          <strong>{title}:</strong> {content}
        </div>
      )
    }
  }

  function parseHtml() {
    const definition = glossaryTermByID?.definition
    if (!definition || definition.length === 0) {
      return ''
    }
    return parse(definition)
  }

  function TagsSection({ title, content }) {
    if (!content || content.length === 0) {
      return (
        <div>
          <strong>{title}:</strong>
        </div>
      )
    }
    return (
      <div>
        <strong>{title}:</strong>{' '}
        {content.map((item) => item.tag_name).join(', ')}
      </div>
    )
  }

  const handleModalOpen = () => {
    setOpenModal(true)
  }

  const handleModalClose = () => {
    setOpenModal(false)
    fetchData()
  }

  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <div className="glossary-term-view-container">
          <GlossaryHomeButton />
          <div className="glossary-term-header-container">
            <h1>{glossaryTermByID?.term}</h1>
            {glossaryTermByID?.is_owner || isAdmin || isChapterAdmin ? (
              <div>
                <IconButton onClick={() => handleModalOpen()}>
                  <EditIcon
                    className="glossary-term-edit-icon"
                    fontSize="large"
                  />
                </IconButton>
                <EditTermModal
                  onOpen={openModal}
                  onClose={handleModalClose}
                  channels={channels}
                  tokenValue={token}
                  existingTermData={glossaryTermByID}
                  endpoint={endpoint}
                />
              </div>
            ) : null}
            {glossaryTermByID?.is_khc_term ? (
              <img
                src={KhcLogo}
                alt="Kraft Heinz Logo"
                className="glossary-term-khc-logo"
              />
            ) : null}
          </div>
          <hr className="glossary-term-border-line" />
          <div className="glossary-term-info-container">
            {glossaryTermByID?.is_khc_term ? (
              <MetadataSection title="Kraft Heinz Related Term" />
            ) : null}
            <MetadataSection
              title="Channel"
              content={glossaryTermByID?.role_type}
            />
            <TagsSection title="Tags" content={glossaryTermByID?.tags} />
          </div>
          <hr className="glossary-term-border-line" />

          <div className="glossary-term-definition-container">
            <div className="ql-editor">
              {parseHtml(glossaryTermByID?.definition)}
            </div>
          </div>
          <hr className="glossary-term-border-line" />
          <div className="glossary-term-info-container">
            <DateMetadataSection
              title="Created"
              by={glossaryTermByID?.created_by}
              on={glossaryTermByID?.created_on}
            />
            <DateMetadataSection
              title="Modified"
              by={glossaryTermByID?.modified_by}
              on={glossaryTermByID?.modified_on}
            />
            <DateMetadataSection
              title="Approved"
              by={glossaryTermByID?.approved_by}
              on={glossaryTermByID?.approved_on}
            />
            <div>
              <strong>Approval Status:</strong>{' '}
              {glossaryTermByID?.approval_status}
            </div>
            <hr className="glossary-term-border-line" />
            <h4>Other related terms to review:</h4>
            <CardList
              cardData={relatedTermData?.relatedTerms?.data}
              link={'/glossary/term/'}
              containerClass="glossary-card-list"
              cardClass="glossary-card"
              logoClass="glossary-card-khc-logo"
              useLogo={true}
            />
          </div>
        </div>
      )}
    </>
  )
}
