import React, { useState, useEffect } from 'react'
import { kholabSections } from '../KholabDashboard/kholabData'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
export default function SummaryHelpContent({ Page }) {
  const sectionData = kholabSections.filter((element) => element.title == Page)
  const [sectionContent, setSectionContent] = useState(
    'Summary Content is loading...'
  )
  useEffect(() => {
    if (sectionData.length > 0) {
      setSectionContent(sectionData[0].content)
    } else if (Page == 'NPS Enterprise') {
      setSectionContent(
        'A mechanism to enable the execution of NPS surveys focusing on transparency and providing automations for efficient survey execution and analysis. The system ensures streamlined processes, improved data collection, and actionable insights to enhance overall functionality and decision-making.'
      )
    } else {
      setSectionContent('Summary Content is not available...')
    }
  }, [sectionData])

  return (
    <div style={{ background: '#fff', display: 'flex' }}>
      <div style={{ marginBottom: '5px' }}>
        <Accordion
          defaultExpanded
          style={{
            flexDirection: 'column',
            display: 'flex',
            background: '#f1f6ff',
          }}
        >
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>
              {' '}
              <p style={{ fontWeight: 700, fontFamily: 'sans-serif' }}>
                Description{' '}
              </p>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{sectionContent}</Typography>
          </AccordionDetails>
        </Accordion>
      </div>
      {/* <div style={{ backgroundColor: '#f1f6ff', color: '#071437', padding: '10px', margin:"10px"}}>
            {sectionContent}
            <div className='show-more-text'>
           <Link color="#000" onClick={(e)=>handleSummaryView(e)} sx={{ mt: 3 }} >Show Less</Link>
           </div>
        </div> */}
    </div>
  )
}
