import {
  GET_GLOSSARY_TERMS,
  GET_GLOSSARY_TERMS_LOAD,
  GET_GLOSSARY_TERMS_ERROR,
  CREATE_GLOSSARY_TERM_POST,
  CREATE_GLOSSARY_TERM_LOAD,
  CREATE_GLOSSARY_TERM_ERROR,
  GET_GLOSSARY_TERM_ID,
  GET_GLOSSARY_TERM_ID_LOAD,
  GET_GLOSSARY_TERM_ID_ERROR,
  APPROVE_GLOSSARY_TERM_ERROR,
  APPROVE_GLOSSARY_TERM_LOAD,
  APPROVE_GLOSSARY_TERM_POST,
  REJECT_GLOSSARY_TERM_ERROR,
  REJECT_GLOSSARY_TERM_POST,
  REJECT_GLOSSARY_TERM_LOAD,
  GET_PENDING_GLOSSARY_TERMS,
  GET_PENDING_GLOSSARY_TERMS_LOAD,
  GET_PENDING_GLOSSARY_TERMS_ERROR,
  GET_SEARCH_GLOSSARY_TERMS,
  GET_SEARCH_GLOSSARY_TERMS_LOAD,
  GET_SEARCH_GLOSSARY_TERMS_ERROR,
  EDIT_GLOSSARY_TERM_ERROR,
  EDIT_GLOSSARY_TERM_POST,
  EDIT_GLOSSARY_TERM_LOAD,
  GET_TERM_STATUS,
  GET_TERM_STATUS_LOAD,
  GET_TERM_STATUS_ERROR,
  GET_RELATED_GLOSSARY_TERMS,
  GET_RELATED_GLOSSARY_TERMS_LOAD,
  GET_RELATED_GLOSSARY_TERMS_ERROR,
} from '../constants/types'
import {
  toastTopRightError,
  toastTopRightSuccess,
} from '../../components/Helper/ToastObjects'
import axios from 'axios'
import { toast } from 'react-toastify'

// manually construct query params for duplicate keys
function constructQueryParams(params) {
  const queryParams = []
  for (const key in params) {
    if (Array.isArray(params[key])) {
      params[key].forEach((value) =>
        queryParams.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
      )
    } else {
      queryParams.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
    }
  }
  return queryParams.join('&')
}

function stringifyValueForKey(dict, key) {
  if (key in dict) {
    dict[key] = JSON.stringify(dict[key])
  }
}

export const getGlossaryTerms = (endpoint, get_params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_GLOSSARY_TERMS_LOAD,
    })
    let get_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }
    // starting_type can have an array so we need to manually construct the params into a string
    const queryString = constructQueryParams(get_params)
    await axios
      .get(`${endpoint}/api/glossary/terms?${queryString}`, {
        headers: get_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_GLOSSARY_TERMS,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_GLOSSARY_TERMS_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const createGlossaryTerm = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_GLOSSARY_TERM_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .post(`${endpoint}/api/glossary/terms`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: CREATE_GLOSSARY_TERM_POST,
          payload: res.data,
        })
        toast('Term Created', toastTopRightSuccess)
      })
  } catch (e) {
    dispatch({
      type: CREATE_GLOSSARY_TERM_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const getGlossaryTermByID = (endpoint, term_id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_GLOSSARY_TERM_ID_LOAD,
    })
    let get_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/glossary/terms/${term_id}`, {
        headers: get_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_GLOSSARY_TERM_ID,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_GLOSSARY_TERM_ID_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const approveGlossaryTerm =
  (endpoint, payload, term_id) => async (dispatch) => {
    try {
      dispatch({
        type: APPROVE_GLOSSARY_TERM_LOAD,
      })
      const put_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }

      await axios
        .put(`${endpoint}/api/glossary/terms/${term_id}/approve`, payload, {
          headers: put_headers,
        })
        .then((res) => {
          dispatch({
            type: APPROVE_GLOSSARY_TERM_POST,
            params: term_id,
            payload: res.data,
          })
          toast('Term Approved', toastTopRightSuccess)
        })
    } catch (e) {
      dispatch({
        type: APPROVE_GLOSSARY_TERM_ERROR,
        payload: e.response.data.detail,
      })
      toast(e.response.data.detail, toastTopRightError)
    }
  }

export const rejectGlossaryTerm =
  (endpoint, payload, term_id) => async (dispatch) => {
    try {
      dispatch({
        type: REJECT_GLOSSARY_TERM_LOAD,
      })
      const put_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }
      const params = {
        glossary_term_id: term_id,
      }

      await axios
        .put(`${endpoint}/api/glossary/terms/${term_id}/approve`, payload, {
          params: params,
          headers: put_headers,
        })
        .then((res) => {
          dispatch({
            type: REJECT_GLOSSARY_TERM_POST,
            params: term_id,
            payload: res.data,
          })
          toast('Term Rejected', toastTopRightSuccess)
        })
    } catch (e) {
      dispatch({
        type: REJECT_GLOSSARY_TERM_ERROR,
        payload: e.response.data.detail,
      })
      toast(e.response.data.detail, toastTopRightError)
    }
  }

export const getPendingGlossaryTerms =
  (endpoint, params, channel) => async (dispatch) => {
    try {
      dispatch({
        type: GET_PENDING_GLOSSARY_TERMS_LOAD,
      })
      const get_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }
      // set default pending filters
      const pending_params = {
        filters: [{ field: 'approval_status', op: '==', value: 'Pending' }],
        is_active: true,
        role_type: channel,
      }
      const get_params = {
        ...params,
        ...pending_params,
        filters: (pending_params.filters || []).concat(params.filters || []),
      }
      stringifyValueForKey(get_params, 'filters')
      stringifyValueForKey(get_params, 'sort')
      await axios
        .get(`${endpoint}/api/glossary/terms`, {
          params: get_params,
          headers: get_headers,
        })
        .then((res) => {
          dispatch({
            type: GET_PENDING_GLOSSARY_TERMS,
            payload: res.data,
          })
        })
    } catch (e) {
      dispatch({
        type: GET_PENDING_GLOSSARY_TERMS_ERROR,
        payload: e.response.data.message,
      })
      toast(e.response.data.message, toastTopRightError)
    }
  }

export const getSearchGlossaryTerms =
  (endpoint, get_params) => async (dispatch) => {
    try {
      dispatch({
        type: GET_SEARCH_GLOSSARY_TERMS_LOAD,
      })
      let get_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }
      await axios
        .get(`${endpoint}/api/glossary/terms/search`, {
          params: get_params,
          headers: get_headers,
        })
        .then((res) => {
          dispatch({
            type: GET_SEARCH_GLOSSARY_TERMS,
            payload: res.data,
          })
        })
    } catch (e) {
      dispatch({
        type: GET_SEARCH_GLOSSARY_TERMS_ERROR,
        payload: e.response.data.message,
      })
      toast(e.response.data.message, toastTopRightError)
    }
  }

export const editGlossaryTerm =
  (endpoint, payload, term_id) => async (dispatch) => {
    try {
      dispatch({
        type: EDIT_GLOSSARY_TERM_LOAD,
      })
      let post_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }

      await axios
        .put(`${endpoint}/api/glossary/terms/${term_id}`, payload, {
          headers: post_headers,
        })
        .then((res) => {
          dispatch({
            type: EDIT_GLOSSARY_TERM_POST,
            payload: res.data,
          })
          toast('Term Edited', toastTopRightSuccess)
        })
    } catch (e) {
      dispatch({
        type: EDIT_GLOSSARY_TERM_ERROR,
        payload: e.response.data.message,
      })
      toast(e.response.data.message, toastTopRightError)
    }
  }

export const getLetterStatus = (endpoint, params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TERM_STATUS_LOAD,
    })
    const get_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }
    await axios
      .get(`${endpoint}/api/glossary/letter-status`, {
        params: params,
        headers: get_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_TERM_STATUS,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_TERM_STATUS_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const getRelatedTerms = (endpoint, term_id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_RELATED_GLOSSARY_TERMS_LOAD,
    })
    const get_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }
    await axios
      .get(`${endpoint}/api/glossary/terms/${term_id}/related`, {
        headers: get_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_RELATED_GLOSSARY_TERMS,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_RELATED_GLOSSARY_TERMS_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
