import { React, useState, useContext, useEffect } from 'react'
import './index.css'
import NpsMenu from './npsMenu'
import DeleteIcon from '@mui/icons-material/Delete'
import { useDispatch, useSelector } from 'react-redux'
import { AuthContext } from '../../context/authProvider'
import jwt_decode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import CardsView from '../Utilities/cardView'
import { deleteTemplate } from '../../../redux/actions/npsActions/npsChapterAction'
import { getSurveyTemplatesEnterprise } from '../../../redux/actions/npsActions/npsSurveyAction'
import LoadingStatus from '../../Helper/LoadingStatus'
import ReactPaginate from 'react-paginate'
import { Button, Modal } from 'react-bootstrap'
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
// import styled from '@mui/material/styles/styled'
// const BootstrapTooltip = styled(({ className, ...props }) => (
//   <Tooltip
//     {...props}
//     classes={{ popper: className }}
//     placement="bottom-start"
//   />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.arrow}`]: {
//     color: theme.palette.common.black,
//   },
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.black,
//     fontSize: '12px',
//     whiteSpace: 'break-spaces',
//   },
// }))
function CreateForm() {
  const [authCreds] = useContext(AuthContext)
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [showBaseTemp, setShowBaseTemp] = useState(false)
  const [deleteTemplateId, setDeleteTemplateId] = useState('')
  const [modalShow, setModalShow] = useState(false)
  const getEnterpriseTemplate = useSelector(
    (state) => state.getEnterpriseTemplate
  )
  const [loadingStatus, setLoadingStatus] = useState({
    status: true,
    message: 'Fetching table records...',
  })
  function truncate(str, n) {
    return str?.length > n ? str?.slice(0, n - 1) + '...' : str
  }
  let tokenValue = jwt_decode(localStorage.getItem('token'))

  useEffect(() => {
    if (getEnterpriseTemplate?.loading == false) {
      setTotalPages(
        Math.ceil(
          getEnterpriseTemplate?.getEnterpriseTemplate?.total_results / 10
        )
      )
    }
  }, [])

  const handlePageChange = (number) => {
    setCurrentPage(number.selected)
  }

  // get endpoint from authCreds
  const endpoint = authCreds.restEndpoint

  // useDispatch
  const dispatch = useDispatch()

  let history = useNavigate()

  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })
    if (showBaseTemp) {
      const params = {
        page_size: 10,
        page_number: currentPage + 1,
        filters: JSON.stringify([
          { op: 'eq', field: 'is_base_template', value: `${showBaseTemp}` },
        ]),
      }
      dispatch(getSurveyTemplatesEnterprise(endpoint, params)).then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
    } else {
      const params = {
        page_size: 10,
        page_number: currentPage + 1,
        filters: JSON.stringify([]),
      }
      dispatch(getSurveyTemplatesEnterprise(endpoint, params)).then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
    }
  }, [currentPage, showBaseTemp])

  const handleCardTemp = (base, template, createdby) => {
    if (base == true) {
      history(
        `/create-template?template=NPS Base Template - {{YEAR}} {{QUARTER}}`
      )
    }
    if (
      base == true &&
      tokenValue.preferred_username.toLowerCase() == createdby
    ) {
      history(
        `/edit-template?template=NPS Base Template - {{YEAR}} {{QUARTER}}`
      )
    }
    if (base == false) {
      history(`/edit-template?template=${template}`)
    }
  }

  const handleDeleteTemplate = (e, id) => {
    e.stopPropagation()
    setModalShow(true)
    setDeleteTemplateId(id)
  }
  const handleBaseCheck = () => {
    setShowBaseTemp(!showBaseTemp)
  }
  const handleCloseModal = () => {
    setModalShow(false)
  }
  const deleteTemplatefunc = () => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })
    const params = {
      id: deleteTemplateId,
    }
    dispatch(deleteTemplate(endpoint, params)).then(() => {
      setModalShow(false)
      setDeleteTemplateId('')
      dispatch(
        getSurveyTemplatesEnterprise(endpoint, {
          page_size: 10,
          page_number: currentPage + 1,
        })
      ).then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
    })
  }
  return (
    <>
      <div>
        <>
          {loadingStatus.status ? (
            <LoadingStatus status_message={loadingStatus.message} />
          ) : (
            <div className="nps">
              {/* <Navbar title="NPS" HelpContent={HelpContent} /> */}

              <NpsMenu />
              <div
                style={{
                  marginLeft: '14px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <input
                  type="checkbox"
                  checked={showBaseTemp}
                  onChange={handleBaseCheck}
                />
                <p style={{ marginLeft: '10px' }}>Show base template</p>
              </div>
              <div className="card-section-nps">
                {getEnterpriseTemplate?.getEnterpriseTemplate?.data?.map(
                  (result) => {
                    return (
                      <CardsView
                        key="test"
                        title_content={
                          <>
                            {' '}
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  flexDirection: 'column',
                                }}
                              >
                                <span className="survey_title">
                                  {result?.template_name}
                                </span>

                                <span className="survey_description">
                                  {truncate(result?.template_description, 100)}
                                </span>
                                {result?.is_base_template == true ? (
                                  <span
                                    className="survey_description"
                                    style={{ color: 'blue' }}
                                  >
                                    (Base Template)
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </div>

                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  height: 'fit-content',
                                  zIndex: '99999',
                                }}
                                onClick={(e) =>
                                  handleDeleteTemplate(e, result?.id)
                                }
                              >
                                {result?.is_base_template == true ? (
                                  <></>
                                ) : (
                                  <DeleteIcon style={{ color: 'red' }} />
                                )}
                              </div>
                            </div>
                            <div></div>
                          </>
                        }
                        border_status_class={
                          'border-primary card-height-chapter-nps'
                        }
                        action_content={<></>}
                        handleClick={() =>
                          handleCardTemp(
                            result?.is_base_template,
                            result?.template_name,
                            result?.created_by
                          )
                        }
                      />
                    )
                  }
                )}
              </div>
              <ReactPaginate
                pageCount={totalPages}
                onPageChange={handlePageChange}
                forcePage={currentPage}
                breakLabel={'...'}
                previousLabel={'<<'}
                nextLabel={'>>'}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
              />
            </div>
          )}
        </>
      </div>
      <Modal show={modalShow} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Template</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: 'hidden' }}>
          Are you sure to delete template ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteTemplatefunc}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default CreateForm
