import { React, useState, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import './css/Main.css'
import AllQuestions from './AllQuestions.js'
import { Link } from 'react-router-dom'
import LoadingStatus from '../../../../Helper/LoadingStatus'
import { AuthContext } from '../../../../context/authProvider'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import {
  getCollabEntries,
  searchInCollab,
} from '../../../../../redux/actions/collabAction'
import { useDispatch } from 'react-redux'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Popover from 'react-bootstrap/Popover'

function Main({
  questions,
  searchcollabName,
  setsearchCollabname,
  setQuestions,
  setTotalPages,
  filters,
  content,
  roles,
  totalQuestions,
  setTotalQuestions,
  channel,
  currentPage,
  setIsTag,
}) {
  const [authCreds] = useContext(AuthContext)

  const forumMetadata = useSelector((state) => state.forumMetadata)
  const searchData = useSelector((state) => state.searchData)

  let is_tag = false
  const distributionEmail =
    forumMetadata?.forumMetadata?.distribution_list[channel]
  const distributionEmailAnchor = 'mailto:' + distributionEmail

  const endpoint = authCreds.restEndpoint

  const dispatch = useDispatch()
  const [searchInput, setSearchInput] = useState('')

  const [loadingStatus, setLoadingStatus] = useState({
    status: false,
    message: 'Fetching table records...',
  })

  function handleSearch() {
    if (searchInput.length >= 3) {
      const delayDebounceFn = setTimeout(() => {
        fetchDataSearch(searchInput) // Send Axios request here
      }, 1000)
      // setLoading(true)

      return () => clearTimeout(delayDebounceFn)
    }
  }

  const handleSearchEnter = (e) =>{
    if (e.key === 'Enter') {
      handleSearch();
    }
  }

  function clearSearch() {
    if (searchcollabName.length > 0) {
      setsearchCollabname('')
      setLoadingStatus({ status: true, message: 'Fetching data....' })
      let url =
        endpoint +
        `/api/collab/get_all_posts?page=${1}&sort_order=desc&per_page=10&channel=${channel}`
      dispatch(getCollabEntries(url)).then(() => {
        setLoadingStatus({ status: false, message: 'Fetching data...' })
      })
    }
  }

  useEffect(() => {
    if (searchData?.searchData?.data) {
      setQuestions(searchData?.searchData?.data)
      setTotalPages(Math.ceil(searchData?.searchData?.record_count / 10))
      setTotalQuestions(searchData?.searchData?.record_count)
    }
  }, [searchData?.searchData?.data])

  const fetchDataSearch = async (data) => {
    let searchText = ''
    if (data[0] === '[' && data[data.length - 1] === ']') {
      is_tag = true
      setIsTag(true)
      searchText = data.slice(1, -1)
      setsearchCollabname(data)
    } else {
      is_tag = false
      setIsTag(false)
      searchText = data
      setsearchCollabname(data)
    }
    dispatch(
      searchInCollab(
        endpoint,
        searchText,
        channel,
        is_tag,
        ['created_on desc'],
        10,
        0,
        false
      )
    )
  }
  return (
    <div className="main-page">
      <div className="main-container">
        <div class="distribution-list">
          <h6 style={{ display: 'inline-block' }}>
            Original Post Distribution List:{' '}
          </h6>
          {distributionEmail ? (
            <a
              style={{
                'margin-left': '5px',
                color: 'blue',
                fontWeight: 'bold',
                fontSize: '14px',
              }}
              href={distributionEmailAnchor}
            >
              {distributionEmail}
            </a>
          ) : (
            <span
              style={{
                'margin-left': '5px',
                color: 'red',
                fontWeight: 'bold',
                fontSize: '14px',
              }}
            >
              No notifications sent on original posts
            </span>
          )}
        </div>
        <div className="main-top">
          <>
            {searchcollabName.length >= 3 ? (
              <h2>Filtered Questions</h2>
            ) : (
              <h2>All Questions</h2>
            )}
          </>
          <OverlayTrigger
            trigger="click"
            key="bottom"
            placement="bottom"
            overlay={
              <Popover id={`popover-positioned-bottom`}>
                <Popover.Body>
                  To search with a tag type <strong>[tag]</strong>
                </Popover.Body>
              </Popover>
            }
          >
            <div className="search-bar-collab">
              <div className="collab-search-container">
                <input
                  placeholder="search..."
                  type="text"
                  defaultValue={searchcollabName}
                  onChange={(e) => {
                    setSearchInput(e.target.value)
                  }}
                  onKeyDown={(e)=>handleSearchEnter(e)}
                  className="collab-search"
                />
                <>
                  {searchcollabName.length > 0 ? (
                    <OverlayTrigger
                      overlay={<Tooltip id="tooltip">Clear Search</Tooltip>}
                    >
                      <button onClick={clearSearch} className="clearbtn">
                        <ClearIcon />
                      </button>
                    </OverlayTrigger>
                  ) : (
                    <></>
                  )}
                </>
              </div>
              <button onClick={handleSearch} className="search-button">
                <SearchIcon />
              </button>
            </div>
          </OverlayTrigger>
          <Link to="/add-question">
            <button
              className="Askbutton"
              style={{ padding: '5px', fontSize: '15px' }}
            >
              Ask Question
            </button>
          </Link>

          {/* <a href="/add-question"> */}

          {/* </a> */}
        </div>

        {loadingStatus.status || searchData?.loading ? (
          <LoadingStatus status_message={loadingStatus.message} />
        ) : (
          <>
            <div className="main-desc">
              {10 * (currentPage + 1) > totalQuestions ? (
                <p>
                  Showing Questions {totalQuestions} of {totalQuestions}
                </p>
              ) : (
                <p>
                  Showing Questions {questions.length * (currentPage + 1)} of{' '}
                  {totalQuestions}
                </p>
              )}

              {filters}
            </div>
            <div className="questions">
              {questions?.map((_q) => (
                <div key={_q} className="question">
                  <AllQuestions
                    data={_q}
                    roles={roles}
                    setsearchCollabname={setsearchCollabname}
                    setQuestions={setQuestions}
                    setTotalPages={setTotalPages}
                    setLoadingStatus={setLoadingStatus}
                    channel={channel}
                    setTotalQuestions={setTotalQuestions}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      {content}
    </div>
  )
}

export default Main
