import React from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import SummaryHelpContent from '../Utilities/summaryHelpContent'

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: '12px',
    whiteSpace: 'break-spaces',
  },
}))
export default function npsMenu({ setSort, sort, setStatusSort, statusSort }) {
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  let history = useNavigate()

  React.useEffect(() => {
    switch (window.location.pathname) {
      case '/nps':
        setSelectedIndex(3)
        break
      case '/view-surveys':
        setSelectedIndex(0)
        break
      case '/survey-admin':
        setSelectedIndex(1)
        break
      case '/create-view-groups':
        setSelectedIndex(2)
        break
      case '/create-survey':
        setSelectedIndex(3)
        break
      case '/template':
        setSelectedIndex(4)
        break
      case '/create-template':
        setSelectedIndex(4)
        break
      default:
        break
    }
  }, [])
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index)
    if (index === 0) {
      history('/view-surveys')
    } else if (index === 1) {
      history('/survey-admin')
    } else if (index === 2) {
      history('/create-view-groups')
    } else if (index === 3) {
      history('/nps')
    } else if (index === 4) {
      history('/template')
    } else {
      history('/')
    }
  }

  const handleSort = (value) => {
    setSort(value)
  }
  const handleStatusSort = (value) => {
    setStatusSort(value)
  }
  return (
    <>
      <div>
        <SummaryHelpContent Page={'NPS Enterprise'} />
      </div>
      <div className="nav-menu-nps">
        {/* <Button
          className={
            selectedIndex == 0
              ? 'm_r-5 m_t-10 nav-btn  btn-sm active-btn'
              : 'm_r-5 m_t-10 nav-btn  btn-sm badge-nav-btn'
          }
          onClick={(event) => handleListItemClick(event, 0)}
        >
          Home
        </Button> */}

        <BootstrapTooltip
          title={
            'Click to see all the list of surveys that has been assigned to you. You can see different card for each survey with their start and end date'
          }
        >
          <Button
            className={
              selectedIndex == 0
                ? 'm_r-5 m_t-10 nav-btn  btn-sm active-btn'
                : 'm_r-5 m_t-10 nav-btn  btn-sm badge-nav-btn'
            }
            onClick={(event) => handleListItemClick(event, 0)}
          >
            {' '}
            My Surveys
          </Button>
        </BootstrapTooltip>
        <BootstrapTooltip
          title={
            'Survey admin page will show you completion rate and nps score (if applicable). In this page admins can edit current survey, send reminders and see survey responses'
          }
        >
          <Button
            className={
              selectedIndex == 1
                ? 'm_r-5 m_t-10 nav-btn  btn-sm active-btn'
                : 'm_r-5 m_t-10 nav-btn btn-sm badge-nav-btn'
            }
            onClick={(event) => handleListItemClick(event, 1)}
          >
            Survey Admin
          </Button>
        </BootstrapTooltip>
        <div className="menu-nps-divider"> </div>
        <BootstrapTooltip
          title={
            'Create survey page will allow you to create new survey by filling up the required details or selecting the templates to get pre filled survey form.'
          }
        >
        <Button
            className={
              selectedIndex == 3
                ? 'm_r-5 m_t-10 nav-btn  btn-sm active-btn'
                : 'm_r-5 m_t-10 nav-btn btn-sm badge-nav-btn'
            }
            onClick={(event) => handleListItemClick(event, 3)}
          >
            Create Survey
          </Button>
          </BootstrapTooltip>
        <BootstrapTooltip
          title={
            'Survey group admin page will show you number of groups list where you can add or disable user directly'
          }
        >
          <Button
            className={
              selectedIndex == 2
                ? 'm_r-5 m_t-10 nav-btn  btn-sm active-btn'
                : 'm_r-5 m_t-10 nav-btn  btn-sm badge-nav-btn'
            }
            onClick={(event) => handleListItemClick(event, 2)}
          >
            Survey Group Admin
          </Button>
        </BootstrapTooltip>
        <BootstrapTooltip
          title={
            'Click to see different templates including base and your created templates. You can customise your own template by editing base template.'
          }
        >
         
          <Button
            className={
              selectedIndex == 4
                ? 'm_r-5 m_t-10 nav-btn  btn-sm active-btn'
                : 'm_r-5 m_t-10 nav-btn btn-sm badge-nav-btn'
            }
            onClick={(event) => handleListItemClick(event, 4)}
          >
            Create Template
          </Button>
        </BootstrapTooltip>
        {selectedIndex == 0 ? (
          <div style={{ marginLeft: '14px' }}>
            <Box sx={{ minWidth: 180 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Sort By</InputLabel>

                <Select
                  sx={{
                    width: '100%',
                    height: '37px',
                    marginTop: '6px',
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-standard"
                  value={sort}
                  label="badge-name"
                  onChange={(e) => handleSort(e.target.value)}
                >
                  {/* {badgeLevels?.map((res) => {
              return ( */}
                  <MenuItem key="test" value="asc">
                    Asc
                  </MenuItem>
                  <MenuItem key="test1" value="desc">
                    Desc
                  </MenuItem>
                  <MenuItem key="test2" value="latest">
                    Latest
                  </MenuItem>
                  {/* )
            })} */}
                </Select>
              </FormControl>
            </Box>
          </div>
        ) : (
          <></>
        )}

        {selectedIndex == 0 ? (
          <div style={{ marginLeft: '14px' }}>
            <Box sx={{ minWidth: 180 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>

                <Select
                  sx={{
                    width: '100%',
                    height: '37px',
                    marginTop: '6px',
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-standard"
                  value={statusSort}
                  label="badge-name"
                  onChange={(e) => handleStatusSort(e.target.value)}
                >
                  <MenuItem key="test1" value="Pending">
                    Pending
                  </MenuItem>
                  <MenuItem key="test1" value="Completed">
                    Complete
                  </MenuItem>
                  <MenuItem key="test1" value="Not filled">
                    Not filled
                  </MenuItem>
                  {/* )
            })} */}
                </Select>
              </FormControl>
            </Box>
          </div>
        ) : (
          <></>
        )}
      </div>{' '}
    </>
  )
}
