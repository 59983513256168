import {
  GET_USER_DETAIL_DATA,
  GET_USER_DETAIL_ERROR,
  GET_USER_DETAIL_LOAD,
} from '../../constants/types'

const initialState = {
  userDetailsData: [],
  loading: true,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_DETAIL_LOAD:
      return {
        ...state,
        loading: true,
      }

    case GET_USER_DETAIL_DATA:
      return {
        ...state,
        userDetailsData: action.payload,
        loading: false,
      }
    case GET_USER_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
