import React, { useMemo } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend
} from 'recharts';
import './LineChart.css';

const CustomLegend = ({ payload }) => {
  if (!payload || !payload.length) return null;

  return (
    <div className="line-chart-chart-legend">
      {payload.map((entry, index) => (
        <div key={`legend-${index}`} className="line-chart-legend-item">
          <div 
            className="line-chart-legend-color-dot"
            style={{ backgroundColor: entry.color }}
          />
          <span className="line-chart-legend-label">{entry.value}</span>
        </div>
      ))}
    </div>
  );
};

const DefaultTooltip = ({ active, payload, label, valueTitle = "Value" }) => {
  if (!active || !payload || !payload.length) return null;
  
  return (
    <div className="line-chart-tooltip-wrapper">
      <div className="line-chart-tooltip-title">{label}</div>
      <div className="line-chart-tooltip-content">
        {payload.map((entry) => (
          <div key={entry?.dataKey} className="line-chart-tooltip-item">
            <div className="line-chart-tooltip-item-header">
              <div 
                className="line-chart-tooltip-color-dot"
                style={{ backgroundColor: entry?.color }}
              />
              <span>{entry?.name}</span>
            </div>
            <div className="line-chart-tooltip-item-content">
              <div>{valueTitle}: {entry?.value}</div>
              {entry?.payload?.[`${entry.dataKey}_data`] && 
                Object.entries(entry.payload[`${entry.dataKey}_data`]).map(([key, value]) => (
                  <div key={key}>
                    {key.charAt(0).toUpperCase() + key.slice(1)}: {value}
                  </div>
                ))
              }
            </div>
            {entry !== payload[payload.length - 1] && <div className="line-chart-tooltip-divider" />}
          </div>
        ))}
      </div>
    </div>
  );
};

const DynamicLineChart = ({
  data,
  lines,
  xAxisKey = 'x',
  xAxisLabel = '',
  yAxisLabel = '',
  height = 400,
  width = 400,
  customTooltip,
  connectNulls = false,
  dotConfig = { r: 4 },
  activeDotConfig = { r: 6 },
  strokeWidth = 2,
  lineType = 'monotone',
  yAxisDomain = [0, 'dataMax'],
  gridConfig = { strokeDasharray: '3 3' },
  colorMatchingEnabled = true,
  // valueMatchingThreshold = 0.0001,
  customColors = [
    '#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd',
    '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf'
  ],
  legendProps = {},
  valueTitle = "Value"
}) => {
  const TooltipComponent = ({ active, payload, label }) => 
    customTooltip ? 
      customTooltip({ active, payload, label }) : 
      DefaultTooltip({ active, payload, label, valueTitle });

      const colorMapping = useMemo(() => {
        if (!colorMatchingEnabled) {
          return new Map(lines.map((line, index) => [
            line.dataKey, 
            customColors[index % customColors.length]
          ]));
        }
        const lineColorMap = new Map(); 
        const lineSignatures = lines.map(line => 
          data.map(timePoint => timePoint[line.dataKey]).join('|')
        );
    
        const uniqueSignatures = [...new Set(lineSignatures)];      
        uniqueSignatures.forEach((signature, index) => {
          const matchingLines = lines.filter((_, lineIndex) => 
            lineSignatures[lineIndex] === signature
          );
      
          matchingLines.forEach(line => {
            lineColorMap.set(
              line.dataKey, 
              customColors[index % customColors.length]
            );
          });
        });
      
        return lineColorMap;
      }, [data, lines, colorMatchingEnabled, customColors]);

  return (
    <div className="line-chart-chart-container">
      <ResponsiveContainer height={height} width={width}>
        <LineChart 
          data={data}
        >
          <CartesianGrid {...gridConfig} className="line-chart-chart-grid" />
          <XAxis
            dataKey={xAxisKey}
            label={xAxisLabel ? { 
              value: xAxisLabel, 
              position: 'bottom',
              className: 'line-chart-axis-label'
            } : null}
          />
          <YAxis
            label={{
              value: yAxisLabel,
              angle: -90,
              position: 'insideLeft',
              style: { textAnchor: 'middle' },
            }}
            domain={yAxisDomain}
          />
          <Tooltip
            content={TooltipComponent}
            cursor={{ strokeDasharray: '3 3' }}
            wrapperStyle={{pointerEvents: 'auto'}}
          />
          <Legend 
            content={CustomLegend}
            {...legendProps}
          />
          {lines.map((line) => (
            <Line
              key={line.dataKey}
              className="line-chart-chart-line"
              type={lineType}
              dataKey={line.dataKey}
              name={line.name || line.dataKey}
              stroke={colorMapping.get(line.dataKey)}
              strokeWidth={strokeWidth}
              dot={{ ...dotConfig, className: 'chart-dot' }}
              activeDot={activeDotConfig}
              connectNulls={connectNulls}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DynamicLineChart;
