import React, { useState, useEffect, useContext } from 'react';
import Sidebar from '../AppBar/Appbar';
import Kpi from './Main';
import LoadingStatus from '../../Helper/LoadingStatus';
import { AuthContext } from '../../context/authProvider';
import { useDispatch, useSelector } from 'react-redux';
import { getForumMetadata } from '../../../redux/actions/forumMetadataAction';
import 'react-toastify/dist/ReactToastify.css';
import { getNpsMetrics ,getBdmMetrics} from '../../../redux/actions/npsActions/npsChapterAction';

export function AnalyticsView() {
  const [authCreds] = useContext(AuthContext);
  const [forumLoadingStatus, setForumLoadingStatus] = useState({
    status: true,
    message: 'Fetching forum metadata...',
  });
  
  const [npsLoadingStatus, setNpsLoadingStatus] = useState({
    status: true,
    message: 'Fetching KPI records...',
  });


  const [bdmLoadingStatus, setBdmLoadingStatus] = useState({
    status: true,
    message: 'Fetching BDM records...',
  });


  const [channel, setChannel] = useState(
    localStorage.getItem('channel') == null
      ? ''
      : localStorage.getItem('channel')
  );

  const forumMetadata = useSelector((state) => state.forumMetadata);
  const dispatch = useDispatch();
  const endpoint = authCreds.restEndpoint;
  const [collabMetadata, setCollabMetadata] = useState('');
  const [surveys, setSurveys] = useState([
    { title: 'Chapter Members NPS', data: [] },
    { title: 'Stakeholders NPS', data: [] }
  ]);



  const npsMetrics = useSelector(
    (state) => state.getNpsChapterMetrics
  );

  const bdmMetrics = useSelector(
    (state) => state.getBDMMetrics
  );
  
  useEffect(() => {
    if (npsMetrics?.npsMetrics?.data) {
      const updatedSurveys = [...surveys];
      updatedSurveys[0].data = npsMetrics?.npsMetrics?.data?.internal_nps_metrics || [];
      updatedSurveys[1].data = npsMetrics?.npsMetrics?.data?.external_survey_nps || [];
      setSurveys(updatedSurveys);
      setNpsLoadingStatus({ status: false, message: 'KPI data fetched' });
    }
  }, [npsMetrics]);


  useEffect(() => {
    if (bdmMetrics?.bdmMetrics?.data?.data) {
      setBdmLoadingStatus({ status: false, message: 'BDM data fetched' });
    }
  }, [bdmMetrics]);

  // Get Collab metadata for channels dropdown
  useEffect(() => {
    async function fetchForumMetadata() {
      setForumLoadingStatus({ status: true, message: 'Fetching forum metadata...' });

      let url = endpoint + `/api/collab/get_forum_metadata?forum_type=DEFAULT`;
      dispatch(getForumMetadata(url)).then(() => {
        setForumLoadingStatus({ status: false, message: 'Forum metadata fetched' });
      });
    }
    fetchForumMetadata();
  }, [dispatch, endpoint]);

  useEffect(() => {
    if (forumMetadata?.loading === false) {
      setCollabMetadata(forumMetadata?.forumMetadata);
    }
  }, [forumMetadata]);

  const getChapterMetrics = () => {
    setNpsLoadingStatus({ status: true, message: "Fetching KPI records..." });
    const url = `${endpoint}/api/survey/chapter_nps_metrics?filters=[{"op":"eq","field":"channel","value":"${channel}"}]`;
    dispatch(getNpsMetrics(url))
      .then(() => {
        setNpsLoadingStatus({
          status: false,
          message: "KPI records fetched.",
        });
      })
      .catch(() => {
        setNpsLoadingStatus({
          status: false,
          message: "Failed to fetch KPI data.",
        });
      });
  };

  const getBDMMetrics = () => {
    setNpsLoadingStatus({ status: true, message: "Fetching BDM records..." });
    const url = `${endpoint}/api/user/fetch_badge_density?filters=[{"op":"eq","field":"CHAPTER","value":"${channel}"}]`;
    dispatch(getBdmMetrics(url))
      .then(() => {
        setNpsLoadingStatus({
          status: false,
          message: "BDM records fetched.",
        });
      })
      .catch(() => {
        setNpsLoadingStatus({
          status: false,
          message: "Failed to fetch BDM data.",
        });
      });
  };
  useEffect(() => {
    getChapterMetrics();
    getBDMMetrics();
  }, [channel]);

  const isDataFetched = !forumLoadingStatus.status && !npsLoadingStatus.status && !bdmLoadingStatus.status;

  return (
    <>
      <Sidebar
        Page="KPIs"
        setChannel={setChannel}
        channel={channel}
        collabMetadata={collabMetadata}
        Index={isDataFetched ? <Kpi surveys={surveys} bdm={bdmMetrics?.bdmMetrics?.data?.data}/> : <LoadingStatus status_message={forumLoadingStatus.status ? forumLoadingStatus.message : npsLoadingStatus.message} />}
      />
    </>
  );
}

export default AnalyticsView;
