import {
  GET_PODS,
  GET_PODS_ERROR,
  GET_PODS_LOADING,
  GET_PODS_TABLE,
  GET_PODS_TABLE_ERROR,
  GET_PODS_TABLE_LOADING,
  ADD_POD_ENTRY,
  ADD_POD_ENTRY_LOADING,
  ADD_POD_ENTRY_ERROR,
  EDIT_POD_ENTRY_LOADING,
  EDIT_POD_ENTRY,
  EDIT_POD_ENTRY_ERROR,
  MY_PODS,
  MY_PODS_LOADING,
  MY_PODS_ERROR,
  EDIT_POD_APPROVAL,
  EDIT_POD_APPROVAL_ERROR,
  EDIT_POD_APPROVAL_LOADING,
  POD_FUNCTIONAL_AREA,
  POD_FUNCTIONAL_AREA_LOADING,
  POD_FUNCTIONAL_AREA_ERROR,
  GET_POD_MEMBERS,
  GET_POD_MEMBERS_ERROR,
  GET_POD_MEMBERS_LOADING,
  GET_POD_APPROVERS,
  GET_POD_APPROVERS_LOADING,
  GET_POD_APPROVERS_ERROR,
  GET_POD_BY_ID,
  GET_POD_BY_ID_ERROR,
  GET_POD_BY_ID_LOADING,
  DELETE_POD,
  DELETE_POD_ERROR,
  DELETE_POD_LOADING,
  POD_DETAILS,
  POD_DETAILS_ERROR,
  POD_DETAILS_LOADING,
  RECOVER_POD_LOADING,
  RECOVER_POD,
  RECOVER_POD_ERROR,
  POD_CONSUMED_CREDITS_LOADING,
  POD_CONSUMED_CREDITS,
  POD_CONSUMED_CREDITS_ERROR,
  POD_ASSETS_LOAD,
  POD_ASSETS,
  POD_ASSETS_ERROR,
  POD_SUMMARY_LOAD,
  POD_SUMMARY,
  POD_SUMMARY_ERROR,
  POD_ASSET_INFO_LOAD,
  POD_ASSET_INFO,
  POD_ASSET_INFO_ERROR,
  POD_ASSET_NAME_LOAD,
  POD_ASSET_NAME,
  POD_ASSET_NAME_ERROR,
} from '../../constants/types'
import axios from 'axios'
import {
  toastTopRightError,
  toastTopRightSuccess,
} from '../../../components/Helper/ToastObjects'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const getPods = (endpoint, user_email) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PODS_LOADING,
    })
    let pods_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/pods/get_pods_menu_data?user_email=${user_email}`, {
        headers: pods_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_PODS,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_PODS_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const getPodsTable = (endpoint, params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PODS_TABLE_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/pods/get_all_pods`, {
        params: params,
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_PODS_TABLE,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_PODS_TABLE_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const createPodEntry = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_POD_ENTRY_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .post(`${endpoint}/api/pods/create_pod`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: ADD_POD_ENTRY,
          payload: res.data,
        })
        toast(
          'A create request for this POD was submitted successfully. Check in pending requests',
          toastTopRightSuccess
        )
        return res
      })
  } catch (e) {
    dispatch({
      type: ADD_POD_ENTRY_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
    return Promise.reject(e)
  }
}

export const editPodEntry = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_POD_ENTRY_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .put(`${endpoint}/api/pods/edit_pod`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: EDIT_POD_ENTRY,
          payload: res.data,
        })
        toast('POD edited', toastTopRightSuccess)
      })
  } catch (e) {
    dispatch({
      type: EDIT_POD_ENTRY_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const getMyPods = (endpoint, params) => async (dispatch) => {
  try {
    dispatch({
      type: MY_PODS_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/pods/get_my_pods`, {
        params: params,
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: MY_PODS,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: MY_PODS_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const podApproval = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_POD_APPROVAL_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .put(`${endpoint}/api/pods/handle_pod_approval`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: EDIT_POD_APPROVAL,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: EDIT_POD_APPROVAL_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const getPodFunctionalArea = (endpoint) => async (dispatch) => {
  try {
    dispatch({
      type: POD_FUNCTIONAL_AREA_LOADING,
    })
    let pods_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/pods/get_functional_areas`, {
        headers: pods_headers,
      })
      .then((res) => {
        dispatch({
          type: POD_FUNCTIONAL_AREA,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: POD_FUNCTIONAL_AREA_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const getPodMembers = (endpoint, pod_id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_POD_MEMBERS_LOADING,
    })
    let pods_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/pods/get_pod_members?pod_id=${pod_id}`, {
        headers: pods_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_POD_MEMBERS,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_POD_MEMBERS_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const getPodApprovers = (endpoint) => async (dispatch) => {
  try {
    dispatch({
      type: GET_POD_APPROVERS_LOADING,
    })
    let pods_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/pods/get_pod_approvers`, {
        headers: pods_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_POD_APPROVERS,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_POD_APPROVERS_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const getPodsPendingRequests =
  (endpoint, params, user_email, is_admin) => async (dispatch) => {
    try {
      dispatch({
        type: GET_PODS_TABLE_LOADING,
      })
      let post_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }

      await axios
        .get(
          `${endpoint}/api/pods/get_pending_requests?user_email=${user_email}&is_admin=${is_admin}`,
          {
            params: params,
            headers: post_headers,
          }
        )
        .then((res) => {
          dispatch({
            type: GET_PODS_TABLE,
            payload: res.data,
          })
        })
    } catch (e) {
      dispatch({
        type: GET_PODS_TABLE_ERROR,
        payload: e.response.data.message,
      })
      toast(e.response.data.message, toastTopRightError)
    }
  }

export const getPodById = (endpoint, pod_id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_POD_BY_ID_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/pods/get_pod_by_id?pod_id=${pod_id}`, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_POD_BY_ID,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_POD_BY_ID_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const deletePod = (endpoint, pod_id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_POD_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .delete(`${endpoint}/api/pods/delete?pod_id=${pod_id}`, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: DELETE_POD,
          payload: res.data,
        })
        toast(res.data.message, toastTopRightError)
      })
  } catch (e) {
    dispatch({
      type: DELETE_POD_ERROR,
      payload: e.response.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const resetPodDetails = () => async (dispatch)=> {
  dispatch({
    type: POD_DETAILS_LOADING,
  })
  dispatch({
    type: GET_POD_BY_ID_LOADING,
  })
}

export const getPodDetails = (endpoint, podData) => async (dispatch) => {
  try {
    dispatch({
      type: POD_DETAILS_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .post(`${endpoint}/api/pods/get_member_details`, podData, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: POD_DETAILS,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: POD_DETAILS_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const getPodConsumedCredits = (endpoint, podData) => async (dispatch) => {
  try {
    dispatch({
      type: POD_CONSUMED_CREDITS_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/pods/get_pod_asset_credits_consumption_filtered_and_grouped`, {
        params: podData,
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: POD_CONSUMED_CREDITS,
          payload: res.data?.content?.data
        })
      })
  } catch (e) {
    dispatch({
      type: POD_CONSUMED_CREDITS_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const getPodAssets = (endpoint, podData) => async (dispatch) => {
  try {
    dispatch({
      type: POD_ASSETS_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }
    await axios
      .get(`${endpoint}/api/pods/get_pod_assets`, {
        params: podData,
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: POD_ASSETS,
          payload: res.data?.content,
        })
      })
  } catch (e) {
    dispatch({
      type: POD_ASSETS_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const getPodSummary = (endpoint, podData) => async (dispatch) => {
  try {
    dispatch({
      type: POD_SUMMARY_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/pods/get_pod_assets_summary_data`, {
        params: podData,
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: POD_SUMMARY,
          payload: res.data?.content?.data?.summary_data[0],
        })
      })
  } catch (e) {
    dispatch({
      type: POD_SUMMARY_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const getPodAssetInfo = (endpoint, podData) => async (dispatch) => {
  try {
    dispatch({
      type: POD_ASSET_INFO_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/pods/get_pod_asset_info`, {
        params: podData,
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: POD_ASSET_INFO,
          payload: res.data?.content?.data?.asset_credits[0],
        })
      })
  } catch (e) {
    dispatch({
      type: POD_ASSET_INFO_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const getPodAssetName = (endpoint, podData) => async (dispatch) => {
  try {
    dispatch({
      type: POD_ASSET_NAME_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/pods/get_pod_asset_names`, {
        params: podData,
        headers: post_headers,
      })
      .then((res) => {

        dispatch({
          type: POD_ASSET_NAME,
          payload: res.data?.data?.asset_names,
        })
      })
  } catch (e) {
    dispatch({
      type: POD_ASSET_NAME_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const recoverPod = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: RECOVER_POD_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .put(`${endpoint}/api/pods/recover`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: RECOVER_POD,
          payload: res.data,
        })
        toast(
          'An Edit request has been created for this POD',
          toastTopRightSuccess
        )
      })
  } catch (e) {
    dispatch({
      type: RECOVER_POD_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
