import Card from '@mui/material/Card'
import {Box} from '@mui/material'
import CardHeader from '@mui/material/CardHeader'
import CardActions from '@mui/material/CardActions'
import StatusBar from './statusBar'
import './cardView.css'

export default function CardsView({
  idea_status,
  title_content,
  border_status_class,
  status_bar_status_class,
  action_content,
  handleClick,
}) {
  return (
    <div className="card-col">
      <Card
        className={`idea_card ${border_status_class} border-bottom-none`}
        onClick={handleClick} >
        <CardHeader
          style={{padding:"8px 8px 2px 8px"}}
          title={title_content}
        ></CardHeader>
        <CardActions style={{ paddingTop: '0', paddingBottom: '2px', paddingLeft: '8px',paddingRight: '8px' }} className='idea-card-action'>
          {action_content}
        </CardActions>
        <Box className={`status-bar ${status_bar_status_class}`}>
            <StatusBar status = {idea_status}/>
        </Box>
      </Card>
    </div>
  )
}
