import { combineReducers } from 'redux'
import tables from '../reducers/refDataReducer'
import refTableData from '../reducers/refTableDataReducer'
import addRowRefData from '../reducers/addRowRefDataReducer'
import importFileData from '../reducers/ImportExportFileReducer'
import restoreDeleteData from '../reducers/restoreDeleteReducer'
import readSfTableData from '../reducers/snowFlakeCloneReducer'
import pgpUploadData from '../reducers/pgpEncryptionReducer'
import auditLogData from '../reducers/auditLogReducer'
import metaDataByPickList from '../reducers/metaDataPicklistReducer'
import metaDataByTable from '../reducers/metaDataByTableReducer'
import seedingData from '../reducers/seedingReducer'
import metaData from '../reducers/metaDataReducer'
import editRefTable from '../reducers/editRefTableDataReducer'
import modalMetaData from './addCapacityTrackerReducer'
import jiraAddMetadata from '../reducers/jiraAddMetadataReducer'
import currentSprint from '../reducers/jiraGetCurrentSprintReducer'
import collabData from '../reducers/collabReducer'
import postData from '../reducers/collabReducer'
import mostUpvotedPosts from '../reducers/collabReducer'
import recentPosts from '../reducers/collabReducer'
import tagData from '../reducers/popularTagReducer'
import ansData from '../reducers/popularAnswerReducer'
import editcollabData from '../reducers/editcollabReducer'
import getPostbyidcollabData from '../reducers/getpostbyidCollab'
import profileData from '../reducers/getTableProfileReducer'
import pdfUrl from '../reducers/azureOcrReducer'
import getCollabUser from '../reducers/getCollabuserReducer'
import models from '../reducers/azureOcrReducer'
import userBadges from '../reducers/getuserBadgesReducer'
import adminBadges from '../reducers/getAdminBadgeReducer'
import myBadges from './getMyBadgesReducer'
import pendingBadges from './getPendingBadge'
import fnf from '../reducers/fnfReducer'
import summaryData from './badgeSummaryReducer'
import badgeTopEarnersData from './badgeTopEarnerReducer'
import exportDataBadge from './exportBadgeReducer'
import badgeRequest from './submitBadgeRequest'
import isApprover from './isApproverReducer'
import badgeApprove from './badgeApprovalReducer'
import deleteBadgeData from './deleteBadgeReducer'
import dbtReportData from './dbtReportReducer'
import getAllUser from './getAlluserReducer'
import editUserData from './editUserReducer'
import forumMetadata from './forumMetadataReducer'
import countryData from './getAllCountryReducer'
import badgeRequestStatus from './badgeRequestStatusReducer'
import editBadgeAdmin from './editBadgeAdmin'
import glossaryTerms from './getGlossaryTermReducer'
import glossaryTermByID from './getGlossaryTermByIdReducer'
import rejectGlossaryTerm from './editGlossaryTermReducer'
import approveGlossaryTerm from './editGlossaryTermReducer'
import pendingGlossaryTerms from './getPendingGlossaryTermReducer'
import submitGlossaryTerm from './editGlossaryTermReducer'
import searchData from '../reducers/collabReducer'
import searchGlossaryTerms from './getGlossaryTermReducer'
import deFileUploadPaths from './deFileUploadReducer/deFileUploadPathReducer'
import deFileUploadRegex from './deFileUploadReducer/deFileUploadRegexReducer'
import deFileUploadPresentFiles from './deFileUploadReducer/deFileUploadPresentFileReducer'
import getAdminSurveyData from '../reducers/npsReducer/getAdminSurveyReducer'
import getSurveyGroupData from '../reducers/npsReducer/getSurveyGroupReducer'
import getSurveyGroupMemberData from '../reducers/npsReducer/getSurveyGroupUsersReducer'
import getSurveyData from '../reducers/npsReducer/getSurveyReducer'
import getUserSurveyData from '../reducers/npsReducer/getUserSurveyReducer'
import glossaryLetterStatus from './getGlossaryLetterStatusReducer'
import getSurveyGroupListData from '../reducers/npsReducer/getSurveyGroupListReducer'
import getSurveyResponseData from '../reducers/npsReducer/getSurveyResponseReducer'
import getSurveyResponsejsonData from '../reducers/npsReducer/getSurveyResponseJson'
import ingestionIntake from './ingestionIntakeReducer'
import getDbtProjectsReducer from '../reducers/dbtGradingReducer/getDbtProjectsReducer'
import getDbtModelsReducer from '../reducers/dbtGradingReducer/getDbtModelsReducer'
import getDbtScoresReducer from '../reducers/dbtGradingReducer/getDbtScoresReducer'
import getAvgAlgorithmsScoreReducer from '../reducers/dbtGradingReducer/getAvgAlgorithmsScoreReducer'
import podsMenuData from './podsPageReducer/podsReducer'
import podsData from './podsPageReducer/podsReducer'
import podFunctionalAreas from './podsPageReducer/podsFunctionalAreaReducer'
import podMembers from './podsPageReducer/podMembersReducer'
import podApprovers from './podsPageReducer/podsReducer'
import getAllIdeasReducer from './ideasReducer/getAllIdeasReducer'
import getIdeaDetailReducer from './ideasReducer/getIdeaDetailReducer'
import ideasFilterReducer from './ideasReducer/ideasFilterReducer'
import announcementReducer from './announcementReducer/announcementReducer'
import homeFilterReducer from './announcementReducer/homeFilterReducer'
import userKudos from '../reducers/getUserKudosReducer'
import podById from './podsPageReducer/podsReducer'
import podDelete from './podsPageReducer/podsReducer'
import podDetails from './podsPageReducer/podsReducer'
import getTemplateList from '../reducers/npsReducer/npsChapterReducer/getTemplateListReducer'
import previewTemplate from '../reducers/npsReducer/npsChapterReducer/previewTemplateReducer'
import getTemplatePlaceholderData from '../reducers/npsReducer/npsChapterReducer/getTemplatePlaceholder'
import getTemplate from '../reducers/npsReducer/npsChapterReducer/getTemplateReducer'
import getJobPostingsReducer from './jobPostingsReducer/getJobPostingsReducer'
import getGlossaryRelatedTerms from './getGlossaryRelatedTermsReducer'
import getSnfClusterData from '../reducers/SnfCluster/snfClusterDataReducer'
import getValidUserEmailData from '../reducers/npsReducer/getValidUserEmails'
import getNpsChapterMetrics from '../reducers/npsReducer/npsChapterReducer/getNpsChapterMetrics'
import getBDMMetrics from './npsReducer/npsChapterReducer/getBDMMetricsReducer'
import getClusterData from '../reducers/SnfCluster/clusterDataReducer'
import getEmailTemplateData from '../reducers/npsReducer/getEmailTemplateReducer'
import kholabChapterAdmin from '../reducers/kholabMetadataReducer'
import userDetailsData from '../reducers/badgesReducer/getUserDetail'
import getEnterpriseTemplate from '../reducers/npsReducer/getEnterpriseTemplate'
export default combineReducers({
  tables,
  metaData,
  refTableData,
  addRowRefData,
  editRefTable,
  importFileData,
  restoreDeleteData,
  readSfTableData,
  pgpUploadData,
  auditLogData,
  metaDataByPickList,
  metaDataByTable,
  seedingData,
  modalMetaData,
  jiraAddMetadata,
  currentSprint,
  collabData,
  tagData,
  postData,
  ansData,
  editcollabData,
  getPostbyidcollabData,
  profileData,
  pdfUrl,
  getCollabUser,
  models,
  userBadges,
  adminBadges,
  myBadges,
  pendingBadges,
  fnf,
  summaryData,
  badgeTopEarnersData,
  exportDataBadge,
  badgeRequest,
  isApprover,
  mostUpvotedPosts,
  recentPosts,
  badgeApprove,
  deleteBadgeData,
  dbtReportData,
  getAllUser,
  editUserData,
  forumMetadata,
  countryData,
  badgeRequestStatus,
  editBadgeAdmin,
  glossaryTerms,
  glossaryTermByID,
  rejectGlossaryTerm,
  approveGlossaryTerm,
  pendingGlossaryTerms,
  submitGlossaryTerm,
  searchData,
  searchGlossaryTerms,
  deFileUploadPaths,
  deFileUploadRegex,
  deFileUploadPresentFiles,
  getAdminSurveyData,
  getSurveyGroupData,
  getSurveyGroupMemberData,
  getSurveyData,
  getUserSurveyData,
  glossaryLetterStatus,
  getSurveyGroupListData,
  ingestionIntake,
  getSurveyResponseData,
  getSurveyResponsejsonData,
  getDbtProjectsReducer,
  getDbtModelsReducer,
  getDbtScoresReducer,
  getAvgAlgorithmsScoreReducer,
  podsMenuData,
  podsData,
  podFunctionalAreas,
  podMembers,
  podApprovers,
  getAllIdeasReducer,
  userKudos,
  podById,
  podDelete,
  podDetails,
  getIdeaDetailReducer,
  getTemplateList,
  getTemplate,
  getJobPostingsReducer,
  getGlossaryRelatedTerms,
  ideasFilterReducer,
  getSnfClusterData,
  announcementReducer,
  homeFilterReducer,
  getTemplatePlaceholderData,
  getValidUserEmailData,
  getNpsChapterMetrics,
  getClusterData,
  previewTemplate,
  getEmailTemplateData,
  getBDMMetrics,
  kholabChapterAdmin,
  userDetailsData,
  getEnterpriseTemplate,
})
