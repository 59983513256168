import React, { useState, createContext, useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import axios from 'axios'

var rest_endpoint = 'http://localhost:8000'
if (process.env.NODE_ENV === 'production') {
  rest_endpoint = process.env.REACT_APP_REST_ENDPOINT
}

export const AuthContext = createContext()

export const AuthProvider = (props) => {
  const { instance } = useMsal()

  // let token = localStorage.getItem('token')

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        if (error.response.status === 401) {
          localStorage.setItem('login_redirect_path', window.location.href)
          // if (token == null) {
            window.location = '/home'
            localStorage.removeItem('is_login')
          // }
        }

        return Promise.reject(error)
      }
    )
  }, [instance])

  const [authCreds, setAuthCreds] = useState({
    accessToken: null,
    graphToken: null,
    username: null,
    restEndpoint: rest_endpoint,
  })

  return (
    <AuthContext.Provider value={[authCreds, setAuthCreds]}>
      {props.children}
    </AuthContext.Provider>
  )
}
