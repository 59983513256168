import { React, useState, useRef, useContext, useEffect } from 'react'
import Input from './input'
import './indexChapter.css'
import Sidebar from '../AppBar/Appbar'
import Placeholder from '../Utilities/placeholderValues'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import PreviewForm from './previewForm'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { toast } from 'react-toastify'
import NpsMenu from './chapterNpsMenu'
import { Form, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import LoadingStatus from '../../Helper/LoadingStatus'
import { AuthContext } from '../../context/authProvider'
import { createSurvey } from '../../../redux/actions/npsActions/npsSurveyAction'
// import SelectAsyncPaginate from '../Utilities/selectPaginate'
// import HelpContent from '../NPS/helpContent'

import ReactQuill from 'react-quill'
import { getForumMetadata } from '../../../redux/actions/forumMetadataAction'
import {
  getSurveyTemplate,
  getSurveyTemplateList,
  getTemplatePlaceholder,
  previewSurveyData,
} from '../../../redux/actions/npsActions/npsChapterAction'
import { useNavigate } from 'react-router-dom'
import EditFormChapterInput from './editFormChapterInput'
import {
  format,
  subDays,
  isBefore,
  isAfter,
  parseISO,
  isEqual,
  differenceInDays,
} from 'date-fns'
import ConfigureEmailModal from '../Utilities/ConfigureEmailModal'
function CreateForm() {
  const [authCreds] = useContext(AuthContext)
  const [formValues, setFormValues] = useState([])
  const [editMode, setEditMode] = useState(false)

  const [toggle, setToggle] = useState(false)
  // const [groupChoiceList, setGroupChoiceList] = useState([])
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [fieldValue, setFieldValue] = useState('text')
  const [fieldValueRating, setFieldValueRating] = useState('star')
  const [placeHolderValues, setPlaceHolderValues] = useState([])
  const [preview, setPreview] = useState(false)
  const [remCount, setRemCount] = useState(1)
  const [placeholderValue, setPlaceHolder] = useState('Enter your answer')
  const [labelValue, setFormLabel] = useState('')

  const [counter, setCounter] = useState(1)
  // const [isPrivate, setIsprivate] = useState(null)
  const [description, setDescription] = useState('')
  const [titlePreview, setTitlePreview] = useState('')
  const [indexValue, setIndex] = useState(0)

  const [descriptionPreview, setDescriptionPreview] = useState('')
  const [formValuesPreview, setFormValuesPreview] = useState('')
  const [npsCounter, setNpsCounter] = useState(0)
  // const [active, setActive] = useState(false)
  const [defaultTemplateName, setDefaultTemplateName] = useState('')
  const [activeCheck, setActiveCheck] = useState(false)
  const [title, setTitle] = useState('')
  const [templateName, setTemplateName] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [initialCheckbox, setInitialCheckbox] = useState(true)
  const [reminderCheckbox, setReminderCheckbox] = useState(true)
  const [loadingStatus, setLoadingStatus] = useState({
    status: false,
    message: 'Fetching Metadata details...',
  })

  const [collabMetadata, setCollabMetadata] = useState('')
  const [channel, setChannel] = useState(
    localStorage.getItem('channel') == null
      ? ''
      : localStorage.getItem('channel')
  )
  const [emailSelect, setEmailSelect] = useState({
    initialEmail: {
      id: '',
      title: '',
    },
    reminderEmail: {
      id: '',
      title: '',
    },
  })
  const forumMetadata = useSelector((state) => state.forumMetadata)
  const getTemplate = useSelector((state) => state.getTemplate)
  const previewTemplate = useSelector((state) => state.previewTemplate)
  const getTemplatePlaceholderData = useSelector(
    (state) => state.getTemplatePlaceholderData
  )
  const getTemplateList = useSelector((state) => state.getTemplateList)

  const handleShowModal = () => setShowModal(true)
  const handleCloseModalEmail = () => setShowModal(false)
  useEffect(() => {
    async function forumMetadata() {
      // setLoadingStatus({ status: true, message: 'Fetching data...' })

      let url = endpoint + `/api/collab/get_forum_metadata?forum_type=DEFAULT`
      dispatch(getForumMetadata(url)).then(() => {
        // setLoadingStatus({ status: false, message: 'Fetching data...' })
      })
    }
    forumMetadata()
    dispatch(getTemplatePlaceholder(endpoint))
  }, [])
  useEffect(() => {
    if (getTemplatePlaceholderData?.loading === false) {
      setPlaceHolderValues(
        getTemplatePlaceholderData?.getTemplatePlaceholderData?.data
      )
    }
  }, [getTemplatePlaceholderData])
  const handlePreview = () => {
    const payload = {
      template_json: formValues,
      template_name: title,
      template_description: description,
      template_channel: channel,
    }
    dispatch(previewSurveyData(endpoint, payload)).then(() => {
      setPreview(true)
    })
  }
  const handleInitialCheckbox = () => {
    setInitialCheckbox(!initialCheckbox)
  }
  const handleReminderCheckbox = () => {
    setReminderCheckbox(!reminderCheckbox)
  }
  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })
    if (previewTemplate.loading == false) {
      setLoadingStatus({ status: false, message: 'Fetching table records...' })

      setTitlePreview(previewTemplate?.previewTemplate?.template_name)
      setDescriptionPreview(
        previewTemplate?.previewTemplate?.template_description
      )
      setFormValuesPreview(previewTemplate?.previewTemplate?.template_json)
    }
  }, [previewTemplate])

  const handleCloseModal = () => {
    setPreview(false)
  }
  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })
    if (defaultTemplateName !== '') {
      dispatch(getSurveyTemplate(endpoint, defaultTemplateName)).then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
    }
  }, [defaultTemplateName, channel])
  useEffect(() => {
    setFormValues(formValues)
  }, [labelValue, editMode, placeholderValue, indexValue, fieldValue])

  const handleEditField = (e, index) => {
    formValues.map((obj, i) => {
      if (i === index) {
        e.preventDefault()
        clearArray()
        setFieldValue(obj.type)
        setFieldValueRating(obj.ratingType)
        setFormLabel(obj.label)
        setPlaceHolder(obj.placeholder)
        setEditMode(!editMode)
        setActiveCheck(obj.required)
        setIndex(index)
      }
    })
  }
  const handleEditSave = (e) => {
    e.preventDefault()
    const values = [...formValues]

    let options = inputRefs?.current?.map((n) => {
      return n?.value
    })
    let newOption = options.filter(function (element) {
      return element !== undefined
    })
    let newOption1 = [...new Set(newOption)]
    if (newOption1[0] == '') {
      toast.error('Please enter options')
    } else {
      (values[indexValue].label = inputRef.current.value || 'label'),
        (values[indexValue].placeholder = placeholderRef?.current?.value || ''),
        (values[indexValue].type = selectRef.current.value || 'text'),
        (values[indexValue].value = ''),
        (values[indexValue].required = activeCheck),
        (values[indexValue].ratingType = fieldValueRating),
        (values[indexValue].options = newOption1 || [])

      setFormValues(values)
      setFormValuesPreview(values)
      // setToggle(false)
      setFieldValue(selectRef.current.value)
      setFieldValueRating('star')
      clearArray()
      setEditMode(false)
      // setToggle(false)
    }
  }

  useEffect(() => {
    if (getTemplate?.loading === false) {
      setFormValues(getTemplate?.getTemplate?.template_json)
      setFormValuesPreview(getTemplate?.getTemplate?.template_json)
      setTitlePreview(getTemplate?.getTemplate?.template_name)
      setDescriptionPreview(getTemplate?.getTemplate?.template_description)
      setTitle(getTemplate?.getTemplate?.template_name)
      setDescription(getTemplate?.getTemplate?.template_description)
    }
  }, [templateName, getTemplate, channel])
  useEffect(() => {
    if (getTemplate?.loading === false) {
      const namesArray = getTemplate?.getTemplate?.template_json?.map(
        (obj) => obj.type
      )
      if (namesArray?.includes('Nps')) {
        setNpsCounter(1)
      }
    }
  }, [getTemplate, npsCounter])
  useEffect(() => {
    if (formValues?.length >= 0) {
      const namesArray = formValues?.map((obj) => obj.type)
      if (namesArray.includes('Nps')) {
        setNpsCounter(1)
      } else {
        setNpsCounter(0)
      }
    }
  }, [formValues, npsCounter])
  const Surveytype = localStorage.getItem('surveyType')
  useEffect(() => {
    if (Surveytype == 'internal') {
      setLoadingStatus({ status: true, message: 'Fetching table records...' })
      const params = {
        page_size: 10,
        page_number: 1,
        is_internal: true,

        filters: JSON.stringify([
          { op: 'eq', field: 'template_channel', value: `${channel}` },
        ]),
      }

      dispatch(getSurveyTemplateList(endpoint, params, 'internal')).then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
        setDefaultTemplateName(
          'Internal {{CHAPTER}} Member NPS - {{YEAR}} {{QUARTER}}'
        )
      })
    } else {
      const params = {
        page_size: 10,
        page_number: 1,
        is_internal: false,
        filters: JSON.stringify([
          { op: 'eq', field: 'template_channel', value: `${channel}` },
        ]),
      }
      dispatch(getSurveyTemplateList(endpoint, params, 'external')).then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
        setDefaultTemplateName(
          '{{CHAPTER}} Stakeholder NPS - {{YEAR}} {{QUARTER}}'
        )
      })
    }
  }, [channel])
  useEffect(() => {
    if (getTemplateList?.loading === false) {
      setTemplateName(getTemplateList?.getTemplateList?.data)
    }
  }, [getTemplateList])
  const handleChangeTemplate = (event) => {
    setDefaultTemplateName(event.target.value)
    dispatch(getSurveyTemplate(endpoint, event.target.value))
  }

  useEffect(() => {
    if (forumMetadata?.loading === false) {
      setCollabMetadata(forumMetadata?.forumMetadata)
    }
  }, [forumMetadata])
  // get endpoint from authCreds
  const endpoint = authCreds.restEndpoint
  // useRef for input and placeholder
  const inputRef = useRef()
  const placeholderRef = useRef()
  const inputRefs = useRef([])
  const pushRef = (el) => {
    inputRefs.current.push(el)
  }
  const selectRef = useRef()
  const selectRefRating = useRef()
  const navigate = useNavigate()
  const isDifferenceGreaterThanOne = (startDate, endDate) => {
    if (!startDate || !endDate) return false
    const start = parseISO(startDate)
    const end = parseISO(endDate)
    return differenceInDays(end, start) > 1
  }
  const isRemDateWithInRange = (startDate, endDate) => {
    if (isDifferenceGreaterThanOne(startDate, endDate) && reminderCheckbox) {
      const RemStartDate = format(
        subDays(parseISO(endDate), remCount),
        'yyyy-MM-dd'
      )
      return (
        isAfter(parseISO(RemStartDate), parseISO(startDate)) &&
        (isBefore(parseISO(RemStartDate), parseISO(endDate)) ||
          isEqual(parseISO(RemStartDate), parseISO(endDate)))
      )
    }
    return true
  }
  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link'],
      ['clean'],
      ['code'],
    ],
  }

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'code',
  ]
  // useDispatch
  const dispatch = useDispatch()
  // functions for handling form fields
  const handleChange = (e, index) => {
    const values = [...formValues]
    values[index].value = e.target.value
    setFormValues(values)
  }
  const handleDeleteField = (e, index) => {
    const values = [...formValues]
    values.splice(index, 1)
    setFormValues(values)
    setFormValuesPreview(values)
  }

  const clearArray = () => {
    inputRefs.current.length = 0
  }
  const handleAddField = (e) => {
    if (
      inputRef.current.value === '' ||
      inputRef.current.value === null ||
      placeholderRef?.current?.value === '' ||
      placeholderRef?.current?.value === null
    ) {
      toast.error('Please enter values')
    } else {
      e.preventDefault()
      const values = [...formValues]

      let options = inputRefs?.current?.map((n) => {
        return n?.value
      })
      let newOption = options.filter(function (element) {
        return element !== undefined
      })
      let newOption1 = [...new Set(newOption)]
      if (newOption1[0] == '') {
        toast.error('Please enter options')
      } else {
        values.push({
          label: inputRef.current.value || 'label',
          placeholder: placeholderRef?.current?.value || '',
          type: selectRef.current.value || 'text',
          value: '',
          required: activeCheck,
          ratingType: fieldValueRating,
          options: newOption1 || [],
        })
        setFormValues(values)
        setFormValuesPreview(values)
        setToggle(false)
        setFieldValue(selectRef.current.value)
        setFieldValueRating('star')
        clearArray()
      }
    }
  }

  const addBtnClick = (e) => {
    e.preventDefault()
    setToggle(true)
    clearArray()
    setFieldValue('text')
    setCounter(1)
  }
  function optionFunc(c) {
    var MultiGold = []
    Array.from(Array(c), (e, i) => {
      MultiGold.push(
        <input
          className="options"
          type="text"
          placeholder="Add option"
          ref={pushRef}
          key={i}
          name={'option' + i}
        />
      )
    })
    return <div>{MultiGold}</div>
  }
  const handleDropdown = (selectRef) => {
    setFieldValue(selectRef.current.value)
  }
  const handleDropdownRating = (selectRefRating) => {
    setFieldValueRating(selectRefRating.current.value)
  }

  function toggleSwitch() {
    setActiveCheck(!activeCheck)
  }
  const handleSubmit = () => {
    const start = new Date(startDate)
    const end = new Date(endDate)
    const dayDiff = (end.getTime() - start.getTime()) / (1000 * 3600 * 24)

    if ((dayDiff == 0 || dayDiff == 1) && !emailSelect.initialEmail.id) {
      toast.error('Please select an initial emails.')
    } else if (
      dayDiff > 1 &&
      (!emailSelect.initialEmail.id || !emailSelect.reminderEmail.id)
    ) {
      toast.error('Please select both initial and reminder emails.')
    } else if (startDate == undefined || endDate == undefined) {
      toast.error('Please add start and end date')
    } else if (!isRemDateWithInRange(startDate, endDate)) {
      toast.error('Reminder days should be between start date and end date')
    } else {
      setLoadingStatus({ status: true, message: 'Adding Details...' })

      const params = {
        start_date: startDate,
        end_date: endDate,
        form_json: formValues,
        form_name: title,
        description: description,
        is_internal: Surveytype == 'internal' ? true : false,
        is_external: Surveytype == 'external' ? true : false,
        is_private: true,
        channel: channel,
        scheduled_rem_days_count:
          isDifferenceGreaterThanOne(startDate, endDate) && reminderCheckbox
            ? remCount
            : null,
        initial_email_template_id: emailSelect.initialEmail.id,
        reminder_email_template_id: emailSelect.reminderEmail.id,
        survey_tags: [
          {
            name: 'is_initial_email_checkbox',
            value: initialCheckbox,
          },
          {
            name: 'is_reminder_email_checkbox',
            value: isDifferenceGreaterThanOne(startDate, endDate)
              ? reminderCheckbox
              : false,
          },
        ],
      }
      dispatch(createSurvey(endpoint, params)).then(() => {
        setLoadingStatus({ status: false, message: 'Adding Details...' })
        setFormValues([])
        setNpsCounter(0)
        navigate('/nps-survey')
        setEmailSelect({
          initialEmail: {
            id: '',
            title: '',
          },
          reminderEmail: {
            id: '',
            title: '',
          },
        })
      })
    }
  }

  // const handleChangeCheck = () => {
  //   setActive(!active)
  // }
  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
          <div>
            <Sidebar
              Page="NPS"
              setChannel={setChannel}
              channel={channel}
              collabMetadata={collabMetadata}
              Index={
                <>
                  <div className="nps">
                    {/* <Navbar title="NPS" HelpContent={HelpContent} /> */}
                    <NpsMenu placeHolderValues={placeHolderValues} />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <button
                        onClick={() => navigate('/nps-survey')}
                        class="button-2"
                      >
                        Go back
                      </button>
                      <div style={{ marginRight: '15%' }}>
                        <Placeholder placeHolderValues={placeHolderValues} />
                      </div>
                    </div>

                    <form className="nps-form-main">
                      <div
                        style={{
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <Box sx={{ minWidth: 180 }}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Select Template
                            </InputLabel>

                            <Select
                              sx={{
                                width: '100%',
                                height: '37px',
                                marginTop: '6px',
                              }}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select-standard"
                              value={defaultTemplateName}
                              label="template"
                              onChange={handleChangeTemplate}
                            >
                              {templateName?.length > 0 ? (
                                templateName?.map((item) => {
                                  return (
                                    <MenuItem
                                      value={item?.template_name}
                                      key={item?.template_name}
                                    >
                                      {item?.template_name}
                                    </MenuItem>
                                  )
                                })
                              ) : (
                                <></>
                              )}
                            </Select>
                          </FormControl>
                        </Box>
                      </div>

                      <input
                        type="text"
                        placeholder="Add Title"
                        onChange={(e) => setTitle(e.target.value)}
                        className="titleInput"
                        defaultValue={title}
                      />
                      <textarea
                        id="w3review"
                        className="descInput"
                        name="w3review"
                        rows="4"
                        defaultValue={description}
                        cols="50"
                        placeholder="Add Description"
                        onChange={(e) => setDescription(e.target.value)}
                      />

                      {formValues?.map((obj, index) => (
                        <>
                          <Input
                            key={index}
                            objValue={obj}
                            onChange={handleChange}
                            index={index}
                            // Add this
                            editField={handleEditField}
                            deleteField={handleDeleteField}
                            disabled={true}
                          />
                          {indexValue == index && editMode ? (
                            <>
                              <EditFormChapterInput
                                editMode={editMode}
                                fieldValue={fieldValue}
                                inputRef={inputRef}
                                selectRefRating={selectRefRating}
                                handleDropdownRating={handleDropdownRating}
                                selectRef={selectRef}
                                handleDropdown={handleDropdown}
                                labelValue={labelValue}
                                placeholderRef={placeholderRef}
                                placeholderValue={placeholderValue}
                                handleEditSave={handleEditSave}
                                toggleSwitch={toggleSwitch}
                                activeCheck={activeCheck}
                                counter={counter}
                                setCounter={setCounter}
                                optionFunc={optionFunc}
                                modules={modules}
                                formats={formats}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ))}
                      {!toggle ? (
                        <>
                          <div className="center-nps">
                            <button
                              className="add-btn-nps-1"
                              onClick={addBtnClick}
                            >
                              Add new
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="dialog-box-nps">
                            <select
                              ref={selectRef}
                              onChange={() => handleDropdown(selectRef)}
                            >
                              <option value="text">Text</option>
                              <option value="textarea">Text Area</option>
                              <option value="text-read-only">
                                Read Only Text
                              </option>
                              <option value="radio">Radio</option>
                              <option value="checkbox">CheckBox</option>
                              <option value="rating">Rating</option>

                              {npsCounter > 0 ? (
                                <> </>
                              ) : (
                                <option value="Nps">Net Promoter Score</option>
                              )}
                            </select>
                            {fieldValue !== 'text-read-only' ? (
                              <input
                                type="text"
                                placeholder="Add Question"
                                className="form-fields"
                                ref={inputRef}
                              />
                            ) : (
                              <></>
                            )}

                            {fieldValue === 'text' ||
                              fieldValue == 'textarea' ? (
                              <input
                                type="text"
                                placeholder="Add placeholder"
                                defaultValue="Enter Your Answer"
                                ref={placeholderRef}
                                className="form-fields"
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                          <div
                            style={{ marginTop: '11px', marginBottom: '11px' }}
                          >
                            <Form.Check // prettier-ignore
                              type="switch"
                              id="custom-switch"
                              onChange={toggleSwitch}
                              checked={activeCheck}
                            />
                            <label for="start" className="require">
                              {' '}
                              Required*
                            </label>
                          </div>
                          {fieldValue == 'radio' ? (
                            <>
                              <div className="option-block">
                                {optionFunc(counter)}
                                <button
                                  type="button"
                                  onClick={() => setCounter(counter + 1)}
                                  className="option-btn"
                                >
                                  +
                                </button>
                                {counter > 1 ? (
                                  <button
                                    type="button"
                                    onClick={() => setCounter(counter - 1)}
                                    className="option-btn"
                                  >
                                    -
                                  </button>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {fieldValue == 'checkbox' ? (
                            <>
                              <div className="option-block">
                                {optionFunc(counter)}
                                <button
                                  type="button"
                                  onClick={() => setCounter(counter + 1)}
                                  className="option-btn"
                                >
                                  +
                                </button>
                                {counter > 1 ? (
                                  <button
                                    type="button"
                                    onClick={() => setCounter(counter - 1)}
                                    className="option-btn"
                                  >
                                    -
                                  </button>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {fieldValue == 'text-read-only' ? (
                            <>
                              <ReactQuill
                                theme="snow"
                                style={{ background: '#fff' }}
                                // value={formData.definition}
                                // onChange={handleRichTextChange}
                                modules={modules}
                                formats={formats}
                                ref={inputRef}
                                className="rich-text-editor"
                                required
                              />

                              {/* <textarea
                      id="w3review"
                      className="descInput"
                      name="w3review"
                      rows="4"
                      cols="50"
                      placeholder="Add Read only text"
                      ref={inputRef}
                      // onChange={(e) => setDescription(e.target.value)}
                    /> */}
                            </>
                          ) : (
                            <></>
                          )}
                          {fieldValue == 'rating' ? (
                            <>
                              <select
                                ref={selectRefRating}
                                onChange={() =>
                                  handleDropdownRating(selectRefRating)
                                }
                                style={{
                                  width: '20%',
                                  border: '1px solid #9d9eff',
                                  padding: '5px',
                                  outline: 'none',
                                }}
                              >
                                <option value="star">Star</option>
                                <option value="Heart">Heart</option>
                                <option value="faces">Faces</option>
                              </select>
                            </>
                          ) : (
                            <></>
                          )}
                          <button
                            className="add-btn-nps"
                            type="button"
                            onClick={handleAddField}
                          >
                            Add
                          </button>
                        </>
                      )}
                    </form>
                    {/* {formValues?.length > 0 ? (
                    <div className="nps-form-main">
                      <h2 className="sub-head-nps">Recipients</h2>

                      <div>
                        <input
                          type="radio"
                          name="privateoption"
                          className="radio-btn-select"
                          onChange={() => setIsprivate(true)}
                        />
                        <p style={{ margin: '7px' }}>Private</p>
                        <input
                          type="radio"
                          name="privateoption"
                          className="radio-btn-select"
                          style={{ marginLeft: '100px' }}
                          onChange={() => setIsprivate(false)}
                        />{' '}
                        <p style={{ margin: '7px' }}>Public </p>
                      </div>
                      {isPrivate || isPrivate == false ? (
                        <>
                          <div style={{ width: '100%' }}>
                            <SelectAsyncPaginate
                              onChange={(choice) => setGroupChoiceList(choice)}
                              endpointUrl="api/survey/get_survey_group_list"
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <></>
                  )} */}
                    {formValues.length > 0 ? (
                      <div className="nps-form-main">
                        <h2 className="sub-head-nps">
                          Survey Email Notification
                        </h2>
                        <>
                          <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            {emailSelect.initialEmail.title && (
                              <span>
                                <b>Initial Email:</b> {emailSelect.initialEmail.title}
                              </span>
                            )}
                            {emailSelect.reminderEmail.title && (
                              <span>
                                <b> Reminder Email:</b>{' '}
                                {emailSelect.reminderEmail.title}
                              </span>
                            )}
                          </div>
                          <div className="center-nps">
                            <button
                              className="add-btn-nps-1"
                              onClick={handleShowModal}
                            >
                              Configure Email
                            </button>
                          </div>
                        </>
                        <>
                          <ConfigureEmailModal
                            emailSelect={emailSelect}
                            setEmailSelect={setEmailSelect}
                            show={showModal}
                            handleClose={handleCloseModalEmail}
                            isChapter={true}
                          />
                        </>
                      </div>
                    ) : (
                      <></>
                    )}
                    {formValues?.length > 0 ? (
                      <div className="nps-form-main">
                        <h2 className="sub-head-nps">Scheduling</h2>
                        <>
                          <div style={{ marginTop: '20px' }}>
                            <label for="start" className="date-label">
                              Start date:
                            </label>
                            <DatePicker
                              selected={startDate ? startDate+'T19:20:30.45+01:00': ''}
                              placeholderText="YYYY-MM-DD"
                              minDate={new Date().setDate(new Date().getDate())}
                              dateFormat="yyyy-MM-dd"
                              onChange={(date) =>
                                setStartDate(format(date, 'yyyy-MM-dd'))
                              }
                              className="form-control date-picker-control"
                            />
                            {/* <input
                              type="date"
                              className="date-field"
                              value={startDate}
                              min={new Date().toISOString().split('T')[0]}
                              onChange={(event) =>
                                setStartDate(event.target.value)
                              }
                            />{' '} */}
                            <label for="start" className="date-label">
                              End date:
                            </label>
                            <DatePicker
                              selected={endDate ? endDate+'T19:20:30.45+01:00': ''}
                              placeholderText="YYYY-MM-DD"
                              minDate={new Date().setDate(new Date().getDate())}
                              dateFormat="yyyy-MM-dd"
                              onChange={(date) =>
                                setEndDate(format(date, 'yyyy-MM-dd'))
                              }
                              className="form-control date-picker-control"
                            />
                            {/* <input
                              className="date-field"
                              type="date"
                              value={endDate}
                              min={new Date().toISOString().split('T')[0]}
                              onChange={(event) =>
                                setEndDate(event.target.value)
                              }
                            />{' '} */}
                            {/* <input
                      type="checkbox"
                      className="check-field"
                      defaultChecked={active}
                      onChange={handleChangeCheck}
                    />
                    <label for="start" className="date-label">
                      Is active
                    </label> */}
                          </div>
                          <div style={{ marginTop: '20px' }}>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip">
                                  Select this option to automate sending of initial email on start date of survey.
                                </Tooltip>
                              }
                            >
                              <div style={{display:'flex'}}>
                                <input
                                  type="checkbox"
                                  className="check-field"
                                  defaultChecked={initialCheckbox}
                                  onChange={handleInitialCheckbox}
                                />
                                <label for="start" className="date-label">
                                  Send Initial Reminder
                                </label>
                              </div>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip">
                                  Select this option to automate sending of reminder email from specified number of days before end date of survey on daily basis.
                                </Tooltip>
                              }
                            >
                              <div style={{display:'flex'}}>
                                <input
                                  type="checkbox"
                                  className="check-field"
                                  defaultChecked={reminderCheckbox}
                                  onChange={handleReminderCheckbox}
                                  disabled={
                                    !isDifferenceGreaterThanOne(startDate, endDate)
                                  }
                                />
                                <label for="start" className="date-label">
                                  Automate Scheduled Reminder
                                </label>
                              </div>
                            </OverlayTrigger>
                          </div>
                          <br />
                          {(isDifferenceGreaterThanOne(startDate, endDate) &&
                            reminderCheckbox) && (
                              <div>
                                <label for="start" className="date-label">
                                  Send daily reminder
                                </label>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip">
                                      Enter a number, Default value: 1
                                    </Tooltip>
                                  }
                                >
                                  <input
                                    type="number"
                                    min="0"
                                    value={remCount}
                                    placeholder="enter a number"
                                    style={{ width: '120px' }}
                                    onChange={(e) =>
                                      setRemCount(e.target.value)
                                    }
                                  />
                                </OverlayTrigger>
                                <label for="start" className="date-label">
                                  days before end date
                                </label>
                              </div>
                            )}

                          <div className="nps-form-main-btn">
                            <button
                              type="button"
                              className="submit-btn-nps-chapter"
                              onClick={handlePreview}
                            >
                              Preview
                            </button>
                            <button
                              type="button"
                              className="submit-btn-nps-chapter"
                              onClick={handleSubmit}
                            >
                              Submit
                            </button>
                          </div>
                        </>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              }
            />
          </div>
          <Modal
            className="preview-nps-modal"
            show={preview}
            onHide={handleCloseModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>Preview Survey</Modal.Title>
            </Modal.Header>
            <Modal.Body className="preview-nps-modal-body">
              <PreviewForm
                formValues={formValuesPreview}
                formTitle={titlePreview}
                formDesc={descriptionPreview}
              />
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  )
}
export default CreateForm
