import React from 'react';
import { Box, Container, Divider, Typography, Grid, Paper } from '@mui/material';
import { DynamicComposedChart } from '../Utilities/DynamicComposedChart';
import { CustomTooltipContainer, useStyles, MatrixBox } from './npsStyling';
import BadgeDensityChart from './BadgeDensityChart';
import './main.css';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const { promoters, passives, detractors, recipients, responses } = payload[0].payload;

    return (
      <CustomTooltipContainer>
        <p className="kpi-tooltip-text-bold">{`${label}`}</p>
        <p className="kpi-tooltip-text-nps">{`NPS: ${payload[3]?.payload?.nps}%`}</p>
        <Divider className="kpi-divider" />
        <p className="kpi-tooltip-text-promoters">{`Promoters: ${promoters}%`}</p>
        <p className="kpi-tooltip-text-passives">{`Passives: ${passives}%`}</p>
        <p className="kpi-tooltip-text-detractors">{`Detractors: ${detractors}%`}</p>
        <Divider className="kpi-divider" />
        <p className="kpi-tooltip-text">{`Recipients: ${recipients}`}</p>
        <p className="kpi-tooltip-text">{`Responses: ${responses}`}</p>
      </CustomTooltipContainer>
    );
  }
  return null;
};

export default function Kpi({ surveys, bdm }) {
  const barDataKeys = ['promoters', 'passives', 'detractors'];
  const barColors = {
    promoters: '#70B77E',
    passives: '#FFDD57',
    detractors: '#E57373',
  };
  const lineDataKey = 'nps';
  const lineColor = 'blue';
  const classes = useStyles();

  const hasSurveysWithValidData = surveys?.some(
    (survey) => survey?.data?.length > 0
  );
  const hasBdmData = bdm && Object.keys(bdm).length > 0 && bdm?.chapters.length>0;

  if (!hasSurveysWithValidData && !hasBdmData) {
    return (
      <Box className="kpi-container">
        <MatrixBox>
          <Typography variant="h6" className="kpi-empty-state">
            More KPIs to come...
          </Typography>
        </MatrixBox>
      </Box>
    );
  }

  return (
    <Box className="kpi-container">
      <MatrixBox>
        <Grid container className="kpi-grid-container">
          {hasSurveysWithValidData && surveys.map((survey, index) => (
            survey?.data?.length > 0 && (
              <Grid 
                item 
                xs={12} 
                sm={hasBdmData ? 6 : 12} 
                key={index}
                className="kpi-grid-item"
              >
                <Paper className="kpi-chart-paper">
                  <Typography variant="h5" className="kpi-chart-title">
                    {survey.title}
                  </Typography>
                  <Box className="kpi-chart-box">
                    <DynamicComposedChart
                      data={survey.data}
                      intervals={1}
                      xAxisKey="created_on"
                      yAxisLabel="NPS SCORE PERCENTAGE"
                      yAxisDomain={[-100, 100]}
                      yAxisTicks={Array.from({ length: 21 }, (_, i) => i * 10 - 100)}
                      barDataKeys={barDataKeys}
                      barColors={barColors}
                      lineDataKey={lineDataKey}
                      lineColor={lineColor}
                      tooltipContent={CustomTooltip}
                    />
                  </Box>
                  <Container>
                    <Box className="kpi-legend-container">
                      <Box className={classes.boxContainer}>
                        <Box className={classes.nps} />
                        <Typography variant="body2" className={classes.annotation}>
                          NPS
                        </Typography>
                      </Box>
                      <Box className={classes.boxContainer}>
                        <Box className={classes.promoters} />
                        <Typography variant="body2" className={classes.annotation}>
                          Promoters
                        </Typography>
                      </Box>
                      <Box className={classes.boxContainer}>
                        <Box className={classes.passives} />
                        <Typography variant="body2" className={classes.annotation}>
                          Passives
                        </Typography>
                      </Box>
                      <Box className={classes.boxContainer}>
                        <Box className={classes.detractors} />
                        <Typography variant="body2" className={classes.annotation}>
                          Detractors
                        </Typography>
                      </Box>
                    </Box>
                  </Container>
                </Paper>
              </Grid>
            )
          ))}
          
          {hasBdmData && (
            <Grid 
              item 
              xs={12} 
              sm={hasSurveysWithValidData ? 6 : 12}
              className="kpi-grid-item"
            >
              <Paper className="kpi-chart-paper">
                <Typography variant="h5" className="kpi-chart-title">
                  Badge Density Metrics
                </Typography>
                <Box className="kpi-chart-box">
                  <BadgeDensityChart badgeData={bdm}  />
                </Box>
              </Paper>
            </Grid>
          )}
        </Grid>
      </MatrixBox>
    </Box>
  );
}
