import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  toastTopRightError,
  toastTopRightSuccess,
} from '../../components/Helper/ToastObjects'
export const uploadSeedingData =
  (endpoint, payload, tableName) => async (dispatch) => {
    try {
      dispatch(seedingDataBegin())
      let post_headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }
      await axios
        .post(
          `${endpoint}/api/ref_data/seeding?table_name=${tableName}`,
          payload,
          {
            headers: post_headers,
            timeout: 6000000,
          }
        )
        .then((response) => {
          dispatch(seedingDataSuccess(response.data))
          toast(response.data.message, toastTopRightSuccess)
        })
        .catch((error) => {
          dispatch(seedingDataFailed(error.response.data))
          toast(error.response.data.message, toastTopRightError)
        })
    } catch (e) {
      dispatch(seedingDataFailed(e))
    }
  }

export const SEEDING_DATA_BEGIN = 'SEEDING_DATA_BEGIN'
export const SEEDING_DATA_BEGIN_SUCCESS = 'SEEDING_DATA_BEGIN_SUCCESS'
export const SEEDING_DATA_BEGIN_FAIL = 'SEEDING_DATA_BEGIN_FAIL'

const seedingDataBegin = () => ({
  type: SEEDING_DATA_BEGIN,
})

const seedingDataSuccess = (getData) => ({
  type: SEEDING_DATA_BEGIN_SUCCESS,
  payload: getData,
})

const seedingDataFailed = (error) => ({
  type: SEEDING_DATA_BEGIN_FAIL,
  payload: error,
})
