import {
  ADD_COLLAB_ERROR,
  ADD_COLLAB_POST,
  ADD_COLLAB_POST_LOADING,
  GET_COLLAB_POST,
  GET_COLLAB_LOADING,
  GET_COLLAB_ERROR,
  GET_TAGS_LOADING,
  GET_TAG_ERROR,
  GET_POPULAR_TAGS,
  ADD_ANSWER_POST,
  ADD_ANSWER_LOAD,
  ADD_ANSWER_ERROR,
  ADD_COMMENT_ERROR,
  ADD_COMMENT_LOAD,
  ADD_COMMENT_POST,
  ADD_VOTE_ERROR,
  ADD_VOTE_POST,
  ADD_VOTE_LOAD,
  GET_POPULAR_ANSWER,
  GET_ANSWER_ERROR,
  GET_ANSWER_LOADING,
  EDIT_COLLAB_POST,
  EDIT_COLLAB_POST_LOADING,
  EDIT_COLLAB_ERROR,
  GET_POST_BY_ID_LOAD,
  GET_POST_BY_ID_DATA,
  GET_POST_BY_ID_ERROR,
  ADD_SUBSCRIPTION_LOADING,
  ADD_SUBSCRIPTION_ERROR,
  ADD_SUBSCRIPTION,
  GET_COLLAB_USER,
  GET_COLLAB_USER_LOAD,
  GET_COLLAB_USER_ERROR,
  COLLAB_RESET,
  GET_MOST_UPVOTED_POSTS,
  GET_MOST_UPVOTED_POSTS_LOADING,
  GET_MOST_UPVOTED_POSTS_ERROR,
  GET_COLLAB_SEARCH_RESULTS,
  GET_COLLAB_SEARCH_RESULTS_ERROR,
  GET_COLLAB_SEARCH_RESULTS_LOADING,
  ADD_KUDOS_ENTRY,
  ADD_KUDOS_ENTRY_LOADING,
  ADD_KUDOS_ERROR,
  GET_RECENT_POSTS,
  GET_RECENT_POSTS_LOADING,
  GET_RECENT_POSTS_ERROR,
  GET_KHOLAB_SUMMARY,
  GET_KHOLAB_SUMMARY_LOADING,
  GET_KHOLAB_SUMMARY_ERROR
} from '../constants/types'
import axios from 'axios'
import {
  toastTopRightError,
  toastTopRightSuccess,
} from '../../components/Helper/ToastObjects'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const createCollabEntry = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_COLLAB_POST_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .post(`${endpoint}/api/collab/create_post`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: ADD_COLLAB_POST,
          payload: res.data,
        })
        toast('Question added successfully', toastTopRightSuccess)
      })
  } catch (e) {
    dispatch({
      type: ADD_COLLAB_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const getCollabEntries = (endpoint) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COLLAB_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}`, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_COLLAB_POST,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_COLLAB_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const getpopulartags = (endpoint, channel) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TAGS_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(
        `${endpoint}/api/collab/get_tags?per_page=10&sort_by_count=true&channel=${channel}`,
        {
          headers: post_headers,
        }
      )
      .then((res) => {
        dispatch({
          type: GET_POPULAR_TAGS,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_TAG_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const getpopularAnswer = (endpoint, channel) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ANSWER_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(
        `${endpoint}/api/collab/get_answers?per_page=10&sort_by_view=true&channel=${channel}`,
        {
          headers: post_headers,
        }
      )
      .then((res) => {
        dispatch({
          type: GET_POPULAR_ANSWER,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_ANSWER_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const getMostUpvotedPosts = (endpoint) => async (dispatch) => {
  try {
    dispatch({
      type: GET_MOST_UPVOTED_POSTS_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}`, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_MOST_UPVOTED_POSTS,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_MOST_UPVOTED_POSTS_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const getRecentQuestions = (endpoint) => async (dispatch) => {
  try {
    dispatch({
      type: GET_RECENT_POSTS_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}`, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_RECENT_POSTS,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_RECENT_POSTS_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const getKholabSummary = (endpoint) => async (dispatch) => {
  try {
    dispatch({
      type: GET_KHOLAB_SUMMARY_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/collab/get_kholab_summary`, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_KHOLAB_SUMMARY,
          payload: res.data?.content?.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_KHOLAB_SUMMARY_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const getpostbyid = (endpoint, id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_POST_BY_ID_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/collab/get_post_by_id?post_id=${id}`, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_POST_BY_ID_DATA,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_POST_BY_ID_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const createcomment = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_COMMENT_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .post(`${endpoint}/api/collab/create_comment`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: ADD_COMMENT_POST,
          payload: res.data,
        })
        toast('Comment Added', toastTopRightSuccess)
      })
  } catch (e) {
    dispatch({
      type: ADD_COMMENT_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const createanswer = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_ANSWER_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .post(`${endpoint}/api/collab/create_answer`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: ADD_ANSWER_POST,
          payload: res.data,
        })
        toast('Answer Added', toastTopRightSuccess)
      })
  } catch (e) {
    dispatch({
      type: ADD_ANSWER_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const createvote = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_VOTE_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .post(`${endpoint}/api/collab/create_vote`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: ADD_VOTE_POST,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: ADD_VOTE_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const EditCollabEntry = (endpoint, id, payload) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_COLLAB_POST_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .put(`${endpoint}/api/collab/edit_post?post_id=${id}`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: EDIT_COLLAB_POST,
          payload: res.data,
        })
        toast('Question updated successfully', toastTopRightSuccess)
      })
  } catch (e) {
    dispatch({
      type: EDIT_COLLAB_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const subscribePost =
  (endpoint, payload, revoke) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_SUBSCRIPTION_LOADING,
      })
      let post_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }

      await axios
        .post(
          `${endpoint}/api/collab/create_subscription?revoke=${revoke}`,
          payload,
          {
            headers: post_headers,
          }
        )
        .then((res) => {
          dispatch({
            type: ADD_SUBSCRIPTION,
            payload: res.data,
          })
        })
    } catch (e) {
      dispatch({
        type: ADD_SUBSCRIPTION_ERROR,
        payload: e.response.data.message,
      })
      toast(e.response.data.message, toastTopRightError)
    }
  }

export const getusers = (endpoint) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COLLAB_USER_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/collab/get_users`, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_COLLAB_USER,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_COLLAB_USER_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const searchInCollab =
  (endpoint, searchText, channel, is_tag, order, top, skip, is_unanswered) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_COLLAB_SEARCH_RESULTS_LOADING,
      })
      let post_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }

      await axios
        .get(
          `${endpoint}/api/collab/search?query=${searchText}&is_tag=${is_tag}&channel=${channel}&sort_order=${order}&top=${top}&skip=${skip}&is_unanswered=${is_unanswered}`,
          {
            headers: post_headers,
          }
        )
        .then((res) => {
          dispatch({
            type: GET_COLLAB_SEARCH_RESULTS,
            payload: res.data,
          })
        })
    } catch (e) {
      dispatch({
        type: GET_COLLAB_SEARCH_RESULTS_ERROR,
        payload: e.response.data.message,
      })
      toast(e.response.data.message, toastTopRightError)
    }
  }

export const addDataReset = () => async (dispatch) => {
  dispatch({
    type: COLLAB_RESET,
  })
}

export const createKudosEntry = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_KUDOS_ENTRY_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .post(`${endpoint}/api/collab/send_kudos`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: ADD_KUDOS_ENTRY,
          payload: res.data,
        })
        const response_text = (res.data.message).replace("sent", "submitted")
        toast(response_text, toastTopRightSuccess)
      })
  } catch (e) {
    dispatch({
      type: ADD_KUDOS_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
