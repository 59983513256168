import StarsIcon from '@mui/icons-material/Stars'
import DangerousIcon from '@mui/icons-material/Dangerous'
import InfoIcon from '@mui/icons-material/Info'

export const AnnouncementCategoryLabel = (category) => {
    let categoryClass = "status-category-critical";
    let categoryIcon = <DangerousIcon  />;
    if(category == 'informative') {
        categoryClass = "status-category-info"
        categoryIcon = <InfoIcon />
    } else if (category =='important') {
        categoryClass = "status-category-imp"
        categoryIcon = <StarsIcon  />
    }
    return (
        <span className={`status-category ${categoryClass} `}>{categoryIcon}{category}</span>
    )
}

export const categoryArr = {
    informative: 'Informative',
    important: 'Important',
    critical: 'Critical'
}

export const statusArr = {
    active: 'Active',
    inactive: 'Inactive'
}

export const liveStatusArr = {
    live: 'Visible',
    archive: 'Hidden'
}
