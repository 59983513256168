import React from 'react'
import { Container, Navbar } from 'react-bootstrap'

export default function NavbarRow({ title, HelpContent }) {
  return (
    <>
      <Navbar variant="dark" expand="lg" className="mb-1 dark-blue bg-gradient">
        <Container fluid>
          <Navbar.Brand>{title}</Navbar.Brand>
          
          <Navbar.Toggle aria-controls="navbar-dark-example" />
        </Container>
        {HelpContent && <HelpContent />}
      </Navbar>
    </>
  )
}
