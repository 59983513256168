import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { Container, Form, InputGroup, Col, Row, Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {categoryArr} from './announcementFunctions'
import {format} from 'date-fns'
import ReactQuill from 'react-quill'


const AnnouncementModal = ({
    showModal,
    setshowModal,
    handleSubmit,
    UID,
    announcementTitle,
    setAnnouncementTitle,
    description,
    setDescription,
    audience,
    setAudience,
    category,
    setCategory,
    attachmentsPath,
    setAttachmentPath,
    isActive,
    setIsActive,
    startDate,
    setStartDate,
    expiryDate,
    setExpiryDate
}) => {
    // const inputRef = useRef()
    const handleRichTextChange = (def) => {
        setDescription(def);
    }
    const modules = {
        toolbar: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
            ],
            ['link'],
            ['clean'],
            ['code'],
        ],
    }
    
    const formats = [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'code',
    ]
    
    return(
        <Modal size="xl" show={showModal} onHide={() => setshowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{UID? 'Edit': 'Create'} Announcement</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
                <Form>
                    <Container className="m-0">
                        <div className="mb-3 mr-3 rowFlex" style={{gap: '1em'}}>
                            <Form.Group className="status-form-group" style={{display: 'flex', flexDirection: 'column'}} >
                                <Form.Label>Status<span className="text-danger">*</span></Form.Label>
                                <InputGroup>
                                    <Form.Check inline label="Active" type="radio" className="radio-button" checked={isActive ? true: false} onChange={()=>setIsActive(true)} />
                                    <Form.Check inline label="Inactive" type="radio" checked={!isActive ? true: false} onChange={()=>setIsActive(false)} />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group style={{display: 'flex', flexDirection: 'column'}}>
                                <Form.Label>Start Date<span className="text-danger">*</span></Form.Label>
                                <DatePicker
                                    selected={startDate ? startDate+'T19:20:30.45+01:00': ''}
                                    placeholderText="YYYY-MM-DD"
                                    minDate={new Date().setDate(new Date().getDate())}
                                    dateFormat="yyyy-MM-dd"
                                    onChange={(date) =>setStartDate(format(date, 'yyyy-MM-dd'))}
                                    className="form-control date-picker-control"
                                />
                            </Form.Group>
                            <Form.Group style={{display: 'flex', flexDirection: 'column'}}>
                                <Form.Label>Expiry Date</Form.Label>
                                <DatePicker
                                    selected={expiryDate ? expiryDate+'T19:20:30.45+01:00': ''}
                                    placeholderText="YYYY-MM-DD"
                                    minDate={new Date().setDate(new Date().getDate())}
                                    dateFormat="yyyy-MM-dd"
                                    onChange={(date) =>setExpiryDate(format(date, 'yyyy-MM-dd'))}
                                    className="form-control date-picker-control"
                                />
                            </Form.Group>
                        </div>
                        <Row className="mb-3 mr-3 rowFlex">
                            <Form.Group className="" as={Col} md="6" controlId="validationFormik01">
                                <Form.Label>Title<span className="text-danger">*</span></Form.Label>
                                <InputGroup>
                                    <Form.Control type="textarea" 
                                    placeholder="Enter Announcement Title"
                                    defaultValue={announcementTitle} onChange={(e) => setAnnouncementTitle(e.target.value)} />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group className="flexItem" as={Col} md="6">
                                <Form.Label>Target Audience<span className="text-danger">*</span></Form.Label>
                                <Form.Select value={audience} onChange={(e) => setAudience(e.target.value)} >
                                    <option value="kholab" >Kholab (General)</option>
                            {JSON.parse(localStorage.getItem('badge_metadata'))?.channels?.map((chapter, index) => {
                                return (
                                    <option value={chapter.toLowerCase()} key={index}>
                                        {chapter}
                                    </option>
                                )
                            })}
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3 mr-3 rowFlex">
                            <Form.Group className="flexItem" as={Col} md="6">
                                <Form.Label>Category<span className="text-danger">*</span></Form.Label>
                                <Form.Select value={category} onChange={(e) => setCategory(e.target.value)} >
                                    {Object.entries(categoryArr).map(([key, val]) => {
                                        return(<option key={key} value={key}>{val}</option>)
                                    })}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="" as={Col} md="6" controlId="validationFormik01">
                                <Form.Label>Website Link</Form.Label>
                                <InputGroup>
                                    <Form.Control type="textarea" 
                                    placeholder="Enter Website Link"
                                    defaultValue={attachmentsPath} onChange={(e) => setAttachmentPath(e.target.value)} />
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3 mr-3 rowFlex">
                            
                            <Form.Group className="flexItem" as={Col} md="6">
                                <Form.Label>Description<span className="text-danger">*</span></Form.Label>
                                <ReactQuill
                                    theme="snow"
                                    style={{ background: '#fff' }}
                                    value={description}
                                    onChange={handleRichTextChange}
                                    modules={modules}
                                    formats={formats}
                                    className="rich-text-editor announcement-desc-editor"
                                    required
                                />
                                
                                {/* <InputGroup>
                                    <Form.Control type="text" placeholder="Enter Announcement Description" 
                                    as="textarea" defaultValue={description} 
                                    rows={4}
                                    onChange={(e) => setDescription(e.target.value)} />
                                </InputGroup> */}
                            </Form.Group>
                        </Row>
                    </Container>
                </Form>
                <Modal.Footer>
                    <Button variant="primary" type="submit" className="addModalBtn"
                    onClick={handleSubmit}>
                        Submit
                    </Button>
                    <Button variant="danger" type="submit" className="addModalBtn"
                    onClick={()=>setshowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
                    
            </Modal.Body>
        </Modal>
    )
}

export default AnnouncementModal;
