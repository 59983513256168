import React, { useEffect, useMemo } from 'react'
import {
  useTable,
  usePagination,
  useRowSelect,
  useFilters,
  useGlobalFilter,
  useSortBy,
} from 'react-table'
import { Table, Modal, Button, Col, Row, Dropdown, Form } from 'react-bootstrap'
import EditTable from './EditTable'
import AddRow from './AddRow'
import ImportPreview from '../../Helper/ImportPreview'
import { AuthContext } from '../../context/authProvider'
import { RefDataContext } from '../../context/refDataProvider'
import { TableHandler } from '../../Utils'
import LoadingStatus from '../../Helper/LoadingStatus'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  toastTopRightError,
  toastTopRightSuccess,
} from '../../Helper/ToastObjects'
import { BiImport, BiExport } from 'react-icons/bi'
import { FaLevelDownAlt } from 'react-icons/fa'
import { MdDangerous } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import AuditLog from './AuditLog'
import './RefTable.css'
import { useDispatch, useSelector } from 'react-redux'
import {
  getRefTableData,
  DeleteRefTableData,
} from '../../../redux/actions/reftableDataAction'
import { editRefTableData } from '../../../redux/actions/editRefTableDataAction'
import { addRefTableData } from '../../../redux/actions/addRowRefDataAction'
import { getTablesProfile } from '../../../redux/actions/refDataAction'
import {
  importFile,
  exportFile,
} from '../../../redux/actions/importExportFileAction'
import { restoreDeleteRecord } from '../../../redux/actions/restoreDeleteAction'
import { uploadSeedingData } from '../../../redux/actions/seedingAction'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import ErrorDialog from './ErrorDialog'
import KeyIcon from '@mui/icons-material/Key'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
// import Typography from '@mui/material/Typography'
import Slide from '@mui/material/Slide'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
const SampleTable = ({
  table_name,
  ifTableChange,
  setIfTableChange,
  setIfTableAccess,
  picklist_value,
}) => {
  // context states
  const [authCreds] = React.useContext(AuthContext)
  const [refData, setRefData] = React.useContext(RefDataContext)
  const [show, setShow] = React.useState(false)
  const [profileUrl, setprofileUrl] = React.useState('')
  // const [failedRules, setFailedRules] = React.useState(false)
  const [showDelete, setShowDelete] = React.useState(false)
  const [addRow, setShowAddRow] = React.useState(false)
  const [openErrorLog, setOpenErrorLog] = React.useState(false)
  const [auditLog, setShowAuditLog] = React.useState(false)
  const [importPreview, setShowImportPreview] = React.useState(false)
  const [editRows, setEditRows] = React.useState([])
  const [ifEdit, setIfEdit] = React.useState(false)
  const [ifAdd, setIfAdd] = React.useState(false)
  const [addModalData, setAddModalData] = React.useState([])
  const [editModalData, setEditModalData] = React.useState([])
  // const [restoreDelete, setRestoreDelete] = React.useState(false)
  const [enabledCols, setEnabledCols] = React.useState([])
  const [enabledColsMap, setEnabledColsMap] = React.useState({})
  const [datatype, setDatatype] = React.useState({})

  const [tableData, setTableData] = React.useState([])
  const [totalPages, setTotalPages] = React.useState(1)
  /* eslint-disable no-unused-vars */
  const [totalRows, setTotalRows] = React.useState(0)
  const [modalData, setModalData] = React.useState([])
  const [tableColumns, setTableColumns] = React.useState([])
  const [localPageIndex, setLocalPageIndex] = React.useState(0)
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })
  const [loadBtnStatus, setLoadBtnStatus] = React.useState(false)
  const [columnFilters, setColumnFilters] = React.useState([])
  const [columnSort, setColumnSortValue] = React.useState([])
  const [isSort, setSort] = React.useState(false)
  const [importData, setImportData] = React.useState({})
  const [editModeState, setEditModeState] = React.useState(false)
  const [rowIndex, setRowIndex] = React.useState(null)
  const [inlineValue, setInlineValue] = React.useState([])
  const [checkedShowDelete, setCheckedShowDelete] = React.useState(false)
  const [showDeleted, setShowDeleted] = React.useState(false)
  const [idxvalue, setidxValue] = React.useState('')
  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint
  const refTableData = useSelector((state) => state?.refTableData)
  const addRowRefData = useSelector((state) => state.addRowRefData)
  const editRefTable = useSelector((state) => state.editRefTable)
  const importFileData = useSelector((state) => state.importFileData)
  const seedingData = useSelector((state) => state.seedingData)
  const profileData = useSelector((state) => state.profileData)
  const [open, setOpen] = React.useState(false)

  const navigate = useNavigate()
  const { restoreDeleteData } = useSelector((state) => ({
    restoreDeleteData: state.restoreDeleteData,
    loading: state.loading,
    error: state.error,
  }))
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleCloseValidation = () => {
    setOpen(false)
  }

  //useeffect for profiler data
  useEffect(() => {
    dispatch(
      getTablesProfile(
        endpoint,
        localStorage.getItem('token'),
        table_name.toUpperCase()
      )
    ).then(() => {
      setLoadingStatus({
        status: false,
        message: 'Fetching Picklists for the columns...',
      })
    })
  }, [])
  useEffect(() => {
    if (profileData.profileData.message !== 'Data profile not available.') {
      setprofileUrl(profileData?.profileData?.blob_sas_url)
    }
  }, [profileData])

  const handleShow = () => {
    setIfEdit(true)
    if (editRows.length > 0) {
      setShow(true)
    } else {
      toast('No records selected', toastTopRightError)
    }
  }
  const handleCloseDelete = () => setShowDelete(false)
  const handleShowDelete = () => setShowDelete(true)

  // function for modal open/close
  const handleClose = () => {
    setShow(false)
    setEditRows(selectedFlatRows.map((d) => d.original))
  }
  const handleCloseRestoreDelete = () => {
    closeRestoreDelete(false)
  }
  const showAddRow = () => {
    setShowAddRow(true)
    setIfAdd(true)
  }

  const closeAddRow = () => {
    setShowAddRow(false)
  }
  const showAuditLog = () => {
    setShowAuditLog(true)
  }
  const closeAuditLog = () => {
    setShowAuditLog(false)
  }
  const closeImportPreview = () => {
    setShowImportPreview(false)
  }
  // const closeFailedRules = () => {
  //   setFailedRules(false)
  // }

  const closeRestoreDelete = () => {
    // setRestoreDelete(false)
  }

  const handleEditableMode = (id, rowValue) => {
    setEditModeState(true)
    setInlineValue(rowValue)
    setRowIndex(id)
  }

  const handleShowSoftDelete = () => {
    setCheckedShowDelete(!checkedShowDelete)
    if (!checkedShowDelete) {
      let show_deleted = true
      setShowDeleted(true)
      fetchData({ pageSize, pageIndex }, [], show_deleted)
    } else {
      setShowDeleted(false)
      fetchData({ pageSize, pageIndex })
    }
  }

  const handleDeleteEditMode = () => {
    setEditModeState(false)
  }

  //Update using Inline Editing
  const handleUpdateEditMode = () => {
    setIfEdit(true)
    editRows.push(inlineValue)

    updateRecords_inline_editing()
    setEditModeState(false)
    setEditRows([])
  }
  const handleValueEditableMode = (idx) => (e) => {
    let value = e.target.value

    if (picklist_value && picklist_value.length > 0) {
      let data = picklist_value.find((col) => col.name == idx)
      if (e.target.value == '') {
        value = null
      } else if (
        (!isNaN(+value) && data.datatype === 'int') ||
        (!isNaN(+value) && data.datatype === 'float')
      ) {
        value = +e.target.value
      } else {
        value = e.target.value
      }
    }

    inlineValue[idx] = value

    // }

    // let data = picklist_value.find((col) => col.name == idx)
  }

  //handle sorting on columns by updating fetch data sort value
  const handleSorting = (header) => {
    setSort(true)

    if (columnSort.length !== 0 && columnSort[0].field !== header) {
      setColumnSortValue([
        { field: `${header}`, direction: columnSort[0].direction },
      ])
    } else {
      if (columnSort.length === 0) {
        setColumnSortValue([{ field: `${header}`, direction: 'asc' }])
      } else if (columnSort[0].direction === 'asc') {
        setColumnSortValue([{ field: `${header}`, direction: 'desc' }])
      } else {
        setColumnSortValue([])
      }
    }
  }

  // contains list of setTimout ids
  let timeoutIds = []

  // clears all the setTimeout from timeoutIds array
  const clearAllTimeout = () => {
    for (let i = 0; i < timeoutIds.length; i++) {
      clearTimeout(timeoutIds[i])
    }
    timeoutIds = []
  }
  // Custome Checkbox component
  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      )
    }
  )

  // Deafult Column

  function DefaultColumnFilter({
    column: { filterValue, setFilter },
    headers,
  }) {
    const [initLoad, setInitLoad] = React.useState(true)

    useEffect(() => {
      if (initLoad === true) {
        return
      }
      clearAllTimeout()
      setEditModeState(false)

      let timeoutId = setTimeout(() => {
        let filter_payload = []
        headers.forEach((header_obj, index) => {
          if (header_obj.filterValue !== undefined && index >= 1) {
            let multipleFilter = header_obj.filterValue.split(',')
            multipleFilter.forEach((value) => {
              if (value.length !== 0) {
                if (value[0] === '"' && value[value.length - 1] === '"') {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.Header
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.Header,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'like' }
                    local_obj['field'] = header_obj.Header
                    local_obj['value'] =
                      '%' + value.slice(1, value.length - 1) + '%'
                    filter_payload.push(local_obj)
                  }
                } else if (
                  value[0] === '*' &&
                  value[value.length - 1] === '*'
                ) {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.Header
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.Header,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.Header
                    local_obj['value'] = value.slice(1, value.length - 1)
                    filter_payload.push(local_obj)
                  }
                } else {
                  let local_obj = { op: 'ilike' }
                  local_obj['field'] = header_obj.Header
                  local_obj['value'] = '%' + value + '%'
                  filter_payload.push(local_obj)
                }
              } else {
                let local_obj = { op: 'ilike' }
                local_obj['field'] = header_obj.Header
                local_obj['value'] = '%' + value + '%'
                filter_payload.push(local_obj)
              }
            })
          }
        })
        setInitLoad(true)
        setColumnFilters(JSON.stringify(filter_payload))
        fetchData(
          { pageSize, pageIndex },
          JSON.stringify(filter_payload),
          showDeleted
        )
      }, 2200)

      timeoutIds.push(timeoutId)
    }, [filterValue, pageSize])

    return (
      <input
        //disabled={disableColumn}
        className="form-control"
        value={filterValue || ''}
        onFocus={() => {
          setInitLoad(false)
        }}
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
      />
    )
  }

  const defaultColumn = {
    Filter: DefaultColumnFilter,
  }

  const available_page_size = ['50', '100', '200', '500']
  const data = useMemo(() => tableData, [refData.hiddenColumns, tableData])
  const columns = useMemo(
    () => tableColumns,
    [refData.hiddenColumns, tableColumns]
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
    state: { pageIndex, pageSize },
    gotoPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: localPageIndex,
        hiddenColumns: refData.hiddenColumns[table_name],
        pageSize: 50,
      },
      manualPagination: true,
      manualFilters: true,
      pageCount: totalPages,
      autoResetSortBy: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  // used for manual pagination
  const onNext = () => {
    gotoPage(pageIndex + 1)
  }

  const onPrevious = () => {
    gotoPage(pageIndex - 1)
  }

  // given a column value, it updates hidden column list
  const hiddenColumnListHandler = (value) => {
    if (value == 'Select All') {
      if (refData.hiddenColumns[table_name].includes('SELECTALL')) {
        setRefData((prev_value) => {
          let obj = { ...prev_value.hiddenColumns }
          obj[table_name] = TableHandler.getAllColumns(
            refData.columnMetadata,
            table_name
          )
          return {
            ...prev_value,
            hiddenColumns: obj,
          }
        })
      } else {
        setRefData((prev_value) => {
          let obj = { ...prev_value.hiddenColumns }
          obj[table_name] = ['SELECTALL']
          return {
            ...prev_value,
            hiddenColumns: obj,
          }
        })
      }
      setTableColumns(
        TableHandler.createColumnMapping(
          Object.keys(tableData[0]),
          refData.columnMetadata,
          table_name
        )
      )
    } else {
      let item_index = -1
      let new_list = []
      refData.hiddenColumns[table_name].forEach((item, index) => {
        if (item === value) item_index = index
        else new_list.push(item)
      })

      if (item_index < 0) {
        setRefData((prev_value) => {
          let obj = { ...prev_value.hiddenColumns }
          obj[table_name] = [...prev_value.hiddenColumns[table_name], value]
          return {
            ...prev_value,
            hiddenColumns: obj,
          }
        })
      } else {
        setRefData((prev_value) => {
          let obj = { ...prev_value.hiddenColumns }
          obj[table_name] = new_list
          return {
            ...prev_value,
            hiddenColumns: obj,
          }
        })
      }

      setTableColumns(
        TableHandler.createColumnMapping(
          Object.keys(tableData[0]),
          refData.columnMetadata,
          table_name
        )
      )
    }
  }

  // Used to fetch new table data from server
  const fetchData = async (
    { pageSize = 50, pageIndex = 1 },
    filters = null,
    show_deleted = false,
    sort_value = columnSort
  ) => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    // if (pageIndex === 0) pageIndex += 1
    setEnabledCols(
      TableHandler.getEditableColumnList(refData?.columnMetadata, table_name)
    )
    setEnabledColsMap(
      TableHandler.getEditableColumnMap(refData?.columnMetadata, table_name)
    )
    setDatatype(TableHandler.getDatatype(refData?.columnMetadata, table_name))

    setLocalPageIndex(pageIndex)

    const params = {
      table_name: table_name.toUpperCase(),
      page_size: pageSize,
      page_number: addRow ? pageIndex : pageIndex + 1, //handle 0 indexing
      show_deleted: show_deleted,
      filters: filters,
      sort: sort_value,
    }

    dispatch(getRefTableData(endpoint, params))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
  }
  useEffect(() => {
    if (refTableData?.loading === false) {
      let table_data = refTableData?.refTableData?.data
      if (table_data?.length > 0) {
        for (let i = 0; i < table_data?.length; i++) {
          if (table_data[i] !== null) {
            if (table_data[i]['KH_ETL_IS_DELETED']) {
              table_data[i]['KH_ETL_IS_DELETED'] = 'true'
            } else {
              table_data[i]['KH_ETL_IS_DELETED'] = 'false'
            }
          }
        }
        // setTableColumns(
        //   TableHandler.createColumnMapping(
        //     Object.keys(table_data[0]),
        //     refData?.columnMetadata,
        //     table_name
        //   )
        // );
        setTotalPages(refTableData?.refTableData?.num_pages)
        setTotalRows(refTableData?.refTableData?.total_results)
        setTableData(refTableData?.refTableData?.data)
      } else {
        // toast("No records found!", toastTopRightError);
        // setTableColumns([])
        setTotalPages(0)
        setTotalRows(0)
        setTableData([])
      }
    }
  }, [refTableData, setTableData])

  useEffect(() => {
    if (refTableData?.error?.message.includes('Not an authorized user for')) {
      setIfTableAccess(false)
    } else {
      setIfTableAccess(true)
    }

    if (refTableData?.loading === false) {
      setTableColumns(
        TableHandler.createColumnMapping(
          refTableData.refTableData.columns,
          refData?.columnMetadata,
          table_name
        )
      )
    }
  }, [refTableData])

  // triggeres on a input change event. Calls bulk_import api
  const handleImportFile = (event) => {
    let file = event.target.files[0]
    let formData = new FormData()
    formData.append('file', file)

    setShowImportPreview(true)
    dispatch(importFile(endpoint, table_name.toUpperCase(), formData))
  }

  useEffect(() => {
    if (importFileData?.loading === false) {
      setImportData(importFileData?.importFileData)
    }
  }, [importFileData, importPreview])

  const handleSeedFile = (event) => {
    setIfTableChange(false)
    setLoadingStatus({ status: true, message: 'Uploading Data...' })

    let file = event.target.files[0]
    let formData = new FormData()
    formData.append('file', file)
    dispatch(
      uploadSeedingData(endpoint, formData, table_name.toUpperCase())
    ).then(() => {
      setLoadingStatus({ status: false, message: 'Uploading Data...' })
      fetchData({ pageSize, pageIndex })
    })
  }

  // updates editRows state on select checkbox event
  useEffect(() => {
    setEditRows(selectedFlatRows.map((d) => d.original))
  }, [selectedFlatRows])

  // fetches new table data on pageIndex, pageSize change
  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })
    fetchData(
      { pageSize, pageIndex },
      columnFilters,
      false,
      JSON.stringify(columnSort)
    )
  }, [pageSize, pageIndex, columnSort])

  // updates localStorage with new list of hiddenColumns
  useEffect(() => {
    localStorage.setItem(
      'hidden_columns',
      JSON.stringify(refData.hiddenColumns)
    )
  }, [refData.hiddenColumns])

  // UPDATE RECORDS......................
  const updateRecords_inline_editing = () => {
    setIfTableChange(false)
    let payload = []
    let editUri = table_name.toUpperCase()
    const allColumns = TableHandler.getAllColumns(
      refData.columnMetadata,
      table_name
    )

    // loops over editRow state and creates payload compatible with update_object api
    for (let i = 0; i < editRows.length; i++) {
      let local_object = {}
      local_object['KH_UID'] = editRows[i]['KH_UID']
      // add all uneditable columns in the local_object
      let uneditableColumns = allColumns.filter(x => !enabledCols.includes(x)); 

      for (let n=0; n < uneditableColumns.length; n++) {
        let value = editRows[i][uneditableColumns[n]]
        let key = uneditableColumns[n]
        local_object[key] = value
      }

      for (let j = 0; j < enabledCols.length; j++) {
        let value = editRows[i][enabledCols[j]]
        let key = enabledCols[j]
        local_object[key] = value
      }
      payload.push(local_object)
    }

    //Check if the the Edit rows are 1 or more
    // if (payload.length === 0) {
    //   toast('No records to update', toastTopRightError)
    //   return
    // } else if (payload.length === 1) {
    //   //for 1 row, pass key in url
    //   editUri += `&key=${editRows[0]['KH_UID']}`
    //   payload = payload[0]
    //   delete payload['KH_UID']
    // }
    setLoadingStatus({ status: true, message: 'Updating table record...' })
    dispatch(
      editRefTableData(endpoint, editUri, payload, 'pre_process=true')
    ).then(() => {
      if (refTableData.loading === true) {
        setLoadingStatus({ status: true, message: 'Updating table record...' })
      }
      handleClose()
    })
  }

  const updateRecords = () => {
    setIfTableChange(false)
    handleClose()
    let editUri = table_name.toUpperCase()
    const allColumns = TableHandler.getAllColumns(
      refData.columnMetadata,
      table_name
    )

    let payload = []

    // loops over editRow state and creates payload compatible with update_object api
    for (let i = 0; i < editRows.length; i++) {
      let local_object = {}
      local_object['KH_UID'] = editRows[i]['KH_UID']
      // add all uneditable columns in the local_object
      let uneditableColumns = allColumns.filter(x => !enabledCols.includes(x)); 

      for (let n=0; n < uneditableColumns.length; n++) {
        let value = editRows[i][uneditableColumns[n]]
        let key = uneditableColumns[n]
        local_object[key] = value
      }

      for (let j = 0; j < enabledCols.length; j++) {
        let value = editRows[i][enabledCols[j]]
        let key = enabledCols[j]
        local_object[key] = value
      }
      payload.push(local_object)
    }

    setLoadingStatus({ status: true, message: 'Updating table record...' })

    dispatch(
      editRefTableData(endpoint, editUri, payload, 'pre_process=true')
    ).then(() => {
      // setLoadingStatus({ status: true, message: "Updating table record..." })
      handleClose()
    })
  }

  useEffect(() => {
    if (!ifTableChange) {
      if (editRefTable?.error) {
        if (ifEdit) {
          //ifEdit= true: edit (inline or modal) is open
          if (editRefTable?.error?.list_of_failed_rules !== modalData) {
            if (editRefTable?.error?.message === 'Failed Rule Validation') {
              setOpenErrorLog(true)
              setModalData(editRefTable?.error?.list_of_failed_rules)
              setEditModalData(editRefTable?.error?.list_of_failed_rules)
            }
          }
        } else {
          //ifEdit= false: Import is open

          //insert all the error logs for add and edit
          let modalErrors = []
          if (addRowRefData?.error?.list_of_failed_rules !== addModalData) {
            if (addRowRefData?.error?.message === 'Failed Rule Validation') {
              addRowRefData?.error?.list_of_failed_rules.map((data) => {
                modalErrors.push(data)
              })
            }
          }

          if (editRefTable?.error?.list_of_failed_rules !== editModalData) {
            if (editRefTable?.error?.message === 'Failed Rule Validation') {
              editRefTable?.error?.list_of_failed_rules.map((data) => {
                modalErrors.push(data)
              })
            }
          }

          if (modalErrors.length !== 0) {
            setModalData(modalErrors)
            setOpenErrorLog(true)
          }
        }
        setLoadingStatus({ status: false, message: 'Updating table record...' })
      } else {
        setOpenErrorLog(false)
      }
    }
  }, [editRefTable?.error])

  useEffect(() => {
    if (ifEdit) {
      fetchData({ pageSize, pageIndex })
    }
  }, [editRefTable?.editRefTable])

  //Restore Deleted Record
  const updateRecordsRestoreDelete = () => {
    let editUri = table_name.toUpperCase()
    let payload = []
    setLoadingStatus({
      status: true,
      message: 'Restoring deleted table records...',
    })
    // loops over editRow state and creates payload compatible with update_object api
    for (let i = 0; i < editRows.length; i++) {
      let local_object = {}
      local_object['KH_UID'] = editRows[i]['KH_UID']
      for (let j = 0; j < enabledCols.length; j++) {
        let value = editRows[i][enabledCols[j]]
        let key = enabledCols[j]
        local_object[key] = value
      }
      payload.push(local_object)
    }

    //Check if the the Edit rows are 1 or more
    if (payload.length === 0) {
      toast('No records to update', toastTopRightError)
      return
    }

    dispatch(restoreDeleteRecord(endpoint, payload, editUri))
    Promise.resolve(restoreDeleteData)
      .then(() => {
        toast('Restored deleted records!', toastTopRightSuccess)
        handleCloseRestoreDelete()
        setLoadingStatus({
          status: false,
          message: 'Restoring deleted table records...',
        })
        fetchData({ pageSize, pageIndex })
        handleShowSoftDelete()
      })
      .catch(() => {
        toast('Error in restoring deleted record(s)', toastTopRightError)
        setLoadingStatus({
          status: false,
          message: 'Restoring deleted table records...',
        })
      })
  }

  // DELETE ROW
  const deleteRow = () => {
    let payload = []
    let deleteUri = table_name.toUpperCase()
    // provides key value in url param for deletion of 1 key else provides list of keys in body
    if (editRows.length === 0) {
      toast('No records to delete', toastTopRightError)
      return
    } else if (editRows.length === 1) {
      deleteUri += `&key=${editRows[0]['KH_UID']}`
    } else {
      payload = editRows.map((value) => {
        return value['KH_UID']
      })
    }

    setLoadingStatus({ status: true, message: 'Deleting table record(s)...' })
    dispatch(DeleteRefTableData(endpoint, deleteUri, payload)).then(() => {
      fetchData({ pageSize, pageIndex })
      setShowDelete(false)
    })
  }

  // Export Table Data
  // calls bulk_export api with columns to be visible and filters (if any)
  const exportTableData = () => {
    setLoadBtnStatus(true)

    let visibleCols = TableHandler.getVisibleColumns(
      refData.hiddenColumns[table_name],
      refData.columnMetadata,
      table_name
    )
    const params = {
      filters: columnFilters,
      sort: JSON.stringify(columnSort),
      show_deleted: showDeleted,
    }
    dispatch(
      exportFile(endpoint, visibleCols, params, table_name.toUpperCase())
    ).then(() => {
      setLoadBtnStatus(false)
    })
  }

  // Import File Data
  // handles import data after user confirms the changes. Calls create_collection and update_object apis for inserts and updates
  const publishImportData = () => {
    setIfEdit(false)
    closeImportPreview()
    setIfTableChange(false)

    if (Object.keys(importData).length < 1) return

    if (importData?.insert.length > 0) {
      setLoadingStatus({ status: true, message: 'creating table data...' })

      dispatch(
        addRefTableData(
          endpoint,
          table_name.toUpperCase(),
          importData?.insert,
          'enhanced_session=true'
        )
      ).then(() => {
        setLoadingStatus({ status: false, message: 'creating table data...' })
        fetchData({ pageSize, pageIndex })
      })
    }

    if (importData?.update.length > 0) {
      setLoadingStatus({ status: true, message: 'updating table data...' })

      dispatch(
        editRefTableData(
          endpoint,
          table_name.toUpperCase(),
          importData?.update,
          'enhanced_session=true'
        )
      ).then(() => {
        setLoadingStatus({ status: false, message: 'creating table data...' })
        fetchData({ pageSize, pageIndex })
      })
    }
  }

  useEffect(() => {
    if (!ifTableChange) {
      if (addRowRefData?.error) {
        if (addRowRefData?.error?.list_of_failed_rules !== addModalData) {
          if (addRowRefData?.error?.message === 'Failed Rule Validation') {
            setOpenErrorLog(true)
            setModalData(addRowRefData?.error?.list_of_failed_rules)
            setAddModalData(addRowRefData?.error?.list_of_failed_rules)
          }
        }
      } else {
        setOpenErrorLog(false)
      }
    }
  }, [addRowRefData?.error])

  useEffect(() => {
    if (ifAdd) {
      fetchData({ pageSize, pageIndex })
    }
  }, [addRowRefData?.addRowRefData])

  useEffect(() => {
    if (!ifTableChange) {
      if (seedingData?.error) {
        if (seedingData?.error?.list_of_failed_rules !== modalData) {
          if (seedingData?.error?.message === 'Failed Rule Validation') {
            setOpenErrorLog(true)
            setModalData(seedingData?.error?.list_of_failed_rules)
          }
        }
      } else {
        setOpenErrorLog(false)
      }
    }
  }, [seedingData?.error])

  return (
    <>
      {refTableData?.error?.message.includes('Not an authorized user for') ? (
        <>
          <div
            class="bg-white text-dark text-center"
            style={{ height: '69vh', paddingTop: '150px' }}
          >
            <div>
              <MdDangerous style={{ fontSize: '60px' }} />
            </div>
            <div class="mt-3 fs-5">{refTableData.error.message}</div>
            <div class="mt-3 fs-5">
              <Typography gutterBottom>
                Please go back to Dashboard for table Selection{' '}
                <a
                  href="#"
                  className="email-link"
                  onClick={(e) => {
                    navigate('/ref_data')
                    window.location.reload()
                  }}
                >
                  here.
                </a>
              </Typography>
            </div>
          </div>
        </>
      ) : (
        <React.Fragment>
          <ToastContainer containerId="qwerty" />
          <div className="table-container">
            {refTableData?.loading ||
            loadingStatus?.status ||
            importFileData?.loading ||
            seedingData?.loading ? (
              <LoadingStatus status_message={loadingStatus.message} />
            ) : (
              <>
                {/* TOP Toolbar */}
                <div className="top-toolbar">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="secondary"
                      id="dropdown-basic"
                      style={{ backgroundColor: 'white', color: 'black' }}
                    >
                      Hide/Show Columns
                    </Dropdown.Toggle>
                    {table_name.length > 1 ? (
                      <Dropdown.Menu>
                        <Form className="column-select-container">
                          {TableHandler.getAllColumns(
                            refData?.columnMetadata,
                            table_name
                          ).map((value, i) => {
                            return (
                              <Form.Check
                                key={i}
                                onChange={() => {
                                  hiddenColumnListHandler(value)
                                }}
                                type="switch"
                                id="custom-switch"
                                checked={
                                  !refData.hiddenColumns[table_name].includes(
                                    value
                                  )
                                }
                                label={value}
                              />
                            )
                          })}
                        </Form>
                      </Dropdown.Menu>
                    ) : (
                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">
                          no table selected
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    )}
                  </Dropdown>

                  <div className="right-toolbar">
                    <input
                      type="checkbox"
                      id="showdelete"
                      checked={checkedShowDelete}
                      onChange={handleShowSoftDelete}
                    />
                    <label htmlFor="showdelete">
                      &nbsp;
                      <strong>Show deleted records</strong>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </label>

                    {profileData.profileData.message !==
                    'Data profile not available.' ? (
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip">
                            This data profile report is not generated at
                            runtime. This version was last generated against
                            data up to {profileData?.profileData?.timestamp}.
                          </Tooltip>
                        }
                      >
                        <button
                          variant="outline-primary"
                          // onClick={handleClickOpenProfile}
                          style={{
                            background: 'green',
                            padding: '5px',
                            border: '1px solid green',
                            color: 'green',
                          }}
                          id="profile-btn"
                        >
                          <a
                            href={profileUrl}
                            style={{
                              color: '#fff',
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Data Profile
                          </a>
                        </button>
                      </OverlayTrigger>
                    ) : (
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip">
                            Data Profile not available
                          </Tooltip>
                        }
                      >
                        <button
                          variant="outline-primary"
                          disabled
                          style={{
                            background: 'grey',
                            padding: '5px',
                            border: '1px solid grey',
                            color: '#fff',
                          }}
                          id="profile-btn"
                        >
                          Data Profile
                        </button>
                      </OverlayTrigger>
                    )}
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip"> See validation Rules</Tooltip>
                      }
                    >
                      <button
                        variant="outline-primary"
                        onClick={handleClickOpen}
                        style={{
                          background: 'green',
                          padding: '5px',
                          border: '1px solid green',
                        }}
                        id="import-btn"
                      >
                        Validation Rules
                      </button>
                    </OverlayTrigger>
                    <Dialog
                      maxWidth="md"
                      open={open}
                      TransitionComponent={Transition}
                      keepMounted
                      onClose={handleCloseValidation}
                    >
                      <DialogTitle>{'Validation Rules'}</DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                          <Table className="table-bg" responsive>
                            <thead>
                              <tr>
                                <th className="cell-border">Rule Name</th>
                                <th className="cell-border">Rule Value</th>
                                <th className="cell-border">
                                  Rule Error Message
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {refTableData?.refTableData?.rules?.map(
                                (rule, index) => {
                                  return (
                                    <tr
                                      className="cell-border"
                                      key={`${index} ${Math.random()}`}
                                    >
                                      <td
                                        className="cell-border"
                                        key={`${index} ${Math.random()}`}
                                      >
                                        {rule?.rule_name}
                                      </td>
                                      <td
                                        className="cell-border"
                                        key={`${index} ${Math.random()}`}
                                      >
                                        {rule?.rule_value}
                                      </td>
                                      <td
                                        className="cell-border"
                                        key={`${index} ${Math.random()}`}
                                      >
                                        {rule?.rule_failure_message}
                                      </td>
                                    </tr>
                                  )
                                }
                              )}
                            </tbody>
                          </Table>
                          {/* {refTableData?.refTableData?.rules?.map((d, i) => {
                        {
                          // console.log(d)
                        }
                        return (
                          <Typography key={i} gutterBottom>
                            {d}
                          </Typography>
                        )
                      })} */}
                        </DialogContentText>
                      </DialogContent>{' '}
                      <DialogActions>
                        <Button onClick={handleCloseValidation}>Close</Button>
                      </DialogActions>
                    </Dialog>

                    {refTableData?.refTableData?.data?.length > 0 ? (
                      <>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip">
                              Make sure you are uploading the data using
                              template downloaded from export button.
                            </Tooltip>
                          }
                        >
                          <button variant="outline-primary" id="import-btn">
                            <span className="toolbar-icon">
                              <BiImport />
                            </span>
                            <input
                              id="file-input"
                              className="custom-file-input"
                              type="file"
                              name="file"
                              onChange={(e) => {
                                handleImportFile(e)
                              }}
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            />
                          </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip">
                              Export Limit 10K Records only
                            </Tooltip>
                          }
                        >
                          <button
                            variant="outline-primary"
                            // title="Export Data"
                            className={loadBtnStatus ? 'load-btn' : ''}
                            disabled={loadBtnStatus}
                            onClick={exportTableData}
                          >
                            <span className="toolbar-icon">
                              <BiExport />
                            </span>{' '}
                            Export
                          </button>
                        </OverlayTrigger>
                      </>
                    ) : (
                      <>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip">
                              Make sure you are uploading the data using
                              template downloaded from export button.
                            </Tooltip>
                          }
                        >
                          <button variant="outline-primary" id="seed">
                            <span className="toolbar-icon">
                              <FaLevelDownAlt />
                            </span>
                            <input
                              id="file-input"
                              className="custom-seed-file-input"
                              type="file"
                              name="file"
                              onChange={(e) => {
                                handleSeedFile(e)
                              }}
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            />
                          </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip"> Export Template</Tooltip>
                          }
                        >
                          <button
                            variant="outline-primary"
                            // title="Export Data"
                            className={loadBtnStatus ? 'load-btn' : ''}
                            disabled={loadBtnStatus}
                            onClick={exportTableData}
                          >
                            <span className="toolbar-icon">
                              <BiExport />
                            </span>{' '}
                            Export
                          </button>
                        </OverlayTrigger>
                      </>
                    )}
                  </div>
                </div>

                {/* Displays complete table */}

                <Table {...getTableProps()}>
                  <thead className="sticky-table-header">
                    {headerGroups.map((headerGroup, i) => (
                      <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, i) =>
                          column?.id === 'selection' ? (
                            <th key={column.id}>
                              {/* {console.log(column.render('Header').props.columns)}
                          {console.log(refTableData)}
                          {
                            column.render('Header').props.columns.map((d,i) => {
                              refTableData?.refTableData?.unique_constraints.map((e) => {
                                if(d.Header === e ) {
                                  return <div key={i}>abc </div>
                                }

                              })
                            })
                          } */}
                              <div
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                                className="table-header"
                              >
                                {column.render('Header')}

                                {columnSort[0]?.field === column['Header'] ? (
                                  <span>
                                    {columnSort[0]?.direction === 'asc'
                                      ? ' 🔽'
                                      : '' ||
                                        columnSort[0]?.direction === 'desc'
                                      ? ' 🔼'
                                      : ''}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div>
                                {column.canFilter
                                  ? column.render('Filter')
                                  : null}
                              </div>
                            </th>
                          ) : (
                            <th key={i}>
                              <div
                                onClick={() => handleSorting(column.Header)}
                                className="table-header"
                              >
                                {column.render('Header')}
                                {refTableData?.refTableData?.unique_constraints.map(
                                  (a, i) => {
                                    if (a === column['Header']) {
                                      return (
                                        <OverlayTrigger
                                          key={i}
                                          overlay={
                                            <Tooltip id="tooltip">
                                              Unique Key
                                            </Tooltip>
                                          }
                                        >
                                          <KeyIcon
                                            className="key-icon"
                                            key={i}
                                            aria-label="twitter"
                                          />
                                        </OverlayTrigger>
                                      )
                                    } else {
                                      return <div key={i}>{}</div>
                                    }
                                  }
                                )}
                                {columnSort[0]?.field === column['Header'] ? (
                                  <span>
                                    {columnSort[0]?.direction === 'asc'
                                      ? ' 🔽'
                                      : '' ||
                                        columnSort[0]?.direction === 'desc'
                                      ? ' 🔼'
                                      : ''}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div>
                                {column.canFilter
                                  ? column.render('Filter')
                                  : null}
                              </div>
                            </th>
                          )
                        )}
                      </tr>
                    ))}
                  </thead>
                  <tbody className="tbody" {...getTableBodyProps()}>
                    {page.map((row, index) => {
                      prepareRow(row)
                      return (
                        <tr
                          key={index}
                          {...row.getRowProps()}
                          onDoubleClick={() =>
                            handleEditableMode(index, row.values)
                          }
                        >
                          {row.cells.map((cell) => {
                            return editModeState &&
                              index === rowIndex &&
                              enabledColsMap[cell.column.id] ? (
                              <td {...cell.getCellProps()}>
                                <input
                                  type="text"
                                  defaultValue={cell.value}
                                  onChange={handleValueEditableMode(
                                    cell.column.id
                                  )}
                                />
                              </td>
                            ) : cell.value === true ? (
                              <td {...cell.getCellProps()}>true</td>
                            ) : cell.value === false ? (
                              <td {...cell.getCellProps()}>false</td>
                            ) : (
                              <td {...cell.getCellProps()}>
                                {cell.render('Cell')}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </>
            )}
          </div>
          {/* Bottom ToolBar of table */}
          <Row>
            <Col sm={6}>
              <div className="page-control">
                <div className="page-of">
                  Page{' '}
                  <em>
                    {pageIndex + 1} of {totalPages}
                  </em>
                </div>
                <div className="prev-next-btn">
                  <button
                    onClick={() => onPrevious()}
                    disabled={!canPreviousPage}
                  >
                    {' '}
                    Prev{' '}
                  </button>
                  <button onClick={() => onNext()} disabled={!canNextPage}>
                    {' '}
                    Next{' '}
                  </button>
                </div>
                <div className="second-control">
                  <span>Go to page:</span>
                  <input
                    className="page-number-input"
                    type="number"
                    defaultValue={pageIndex || 1}
                    onBlur={(e) => {
                      const page = e.target.value ? Number(e.target.value) : 0
                      // handling zero indexing
                      gotoPage(page - 1)
                    }}
                  />
                  <select
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value))
                    }}
                  >
                    {available_page_size.map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </div>

                <div
                  style={{
                    display: 'inline-block',
                    marginLeft: '50px',
                    fontWeight: 600,
                    fontSize: '14px',
                  }}
                >
                  {' '}
                  Total results: {refTableData?.refTableData?.total_results}
                </div>
              </div>
            </Col>
            {/* Bottom-right Buttons */}
            <Col sm={6}>
              {editModeState ? (
                <>
                  <div className="inlineedit">
                    <Button
                      className="m_r-5 block-btn main-button btn-sm"
                      onClick={handleUpdateEditMode}
                    >
                      Save
                    </Button>
                    <Button
                      className="m_r-5 block-btn main-button-cancel btn-sm btn-danger"
                      onClick={handleDeleteEditMode}
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <Button
                    className="m_r-5 block-btn main-button btn-sm"
                    onClick={handleShow}
                  >
                    Edit
                  </Button>
                  <Button
                    className="m_r-5 block-btn main-button btn-sm"
                    onClick={handleShowDelete}
                  >
                    Delete
                  </Button>
                  <Button
                    className="m_r-5 block-btn main-button btn-sm"
                    onClick={showAddRow}
                  >
                    Add
                  </Button>
                  <Button
                    className="m_r-5 block-btn main-button btn-sm"
                    onClick={showAuditLog}
                  >
                    Audit log
                  </Button>
                  {checkedShowDelete ? (
                    <Button
                      className="m_r-5 mt-3 block-btn main-button"
                      onClick={updateRecordsRestoreDelete}
                    >
                      Restore Deleted
                    </Button>
                  ) : null}
                </>
              )}
            </Col>
          </Row>

          {/* EDIT MODAL----------------------------------------------------- */}
          <Modal size="xl" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Data: </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
              <EditTable
                table_data={editRows}
                setEditRows={setEditRows}
                table_cols={tableColumns}
                enabledColsMap={enabledColsMap}
                datatype={datatype}
                table_name={table_name}
                setLoadingStatus={setLoadingStatus}
                picklist_value={picklist_value}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-danger" onClick={handleClose}>
                Close
              </Button>
              <Button variant="outline-primary" onClick={updateRecords}>
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>

          {/* ADD ROW MODAL ---------------------------------------------- */}
          <Modal
            dialogClassName="large-modal"
            show={addRow}
            onHide={closeAddRow}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Data: </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
              <AddRow
                table_name={table_name}
                fetchTableRecord={fetchData}
                setLoadingStatus={setLoadingStatus}
                setShowAddRow={setShowAddRow}
                picklist_value={picklist_value}
                setIfTableChange={setIfTableChange}
              />
            </Modal.Body>{' '}
          </Modal>

          {/* AUDIT MODAL ---------------------------------------- */}
          <Modal size="xl" show={auditLog} onHide={closeAuditLog}>
            <Modal.Header closeButton>
              <Modal.Title>Audit Log</Modal.Title>
            </Modal.Header>
            <Modal.Body
              className="modal-body"
              id="parent-scroll"
              style={{ overflow: 'auto' }}
            >
              <AuditLog table_name={table_name} closeAuditLog={closeAuditLog} />
            </Modal.Body>
          </Modal>

          {/* IMPORT PREVIEW MODAL ---------------------------------------- */}
          <Modal size="xl" show={importPreview} onHide={closeImportPreview}>
            <Modal.Header closeButton>
              <Modal.Title>
                Import Preview{' '}
                <p style={{ fontSize: '11px', color: 'brown' }}>
                  {' '}
                  (Preview 100 records only)
                </p>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              className="modal-body y-scroll"
              style={{ overflow: 'auto' }}
            >
              <ImportPreview
                table_name={table_name}
                importData={importData}
                closeImportPreview={closeImportPreview}
              />
            </Modal.Body>
            <Modal.Footer>
              <h6 style={{ marginRight: 'auto' }}>
                {' '}
                Total Count : {importData?.total_count}
              </h6>
              <Button variant="outline-success" onClick={publishImportData}>
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Error Dialog ---------------------------------------- */}
          <ErrorDialog
            modalData={modalData}
            setOpenErrorLog={setOpenErrorLog}
            openErrorLog={openErrorLog}
            setModalData={setModalData}
          />

          {/* Delete MODAL ---------------------------------------- */}
          <Modal show={showDelete} onHide={handleCloseDelete}>
            <Modal.Header closeButton>
              <Modal.Title>Delete Data</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflowY: 'hidden' }}>
              Are you sure to delete this entry ?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseDelete}>
                Close
              </Button>
              <Button variant="primary" onClick={deleteRow}>
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      )}
    </>
  )
}

export default SampleTable
