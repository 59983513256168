import React, { useState, useEffect, useMemo, useContext } from 'react'
import '../RefData/RefTable.css'

import LoadingStatus from '../../Helper/LoadingStatus'
import {
  useTable,
  usePagination,
  useRowSelect,
  useFilters,
  useGlobalFilter,
  useSortBy,
} from 'react-table'
import { Table, Button, Col, Row } from 'react-bootstrap'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import Modal from 'react-bootstrap/Modal'
import { AuthContext } from '../../context/authProvider'
import { TableHandler, ForumHandler } from '../../Utils'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch, useSelector } from 'react-redux'
import DBTGradingScoreDashboard from './dbtGradingScores'
import { getDbtGradingModels } from '../../../redux/actions/dbtGradingActions/dbtGradingAction'
import MentionHandler from '../Utilities/quillSearch'

export default function DBTGradingModelDashboard({
  uid = '',
  dbtAsset = '',
  showModal,
  projectName = '',
  handleCloseModal,
}) {
  const [loadingStatus, setLoadingStatus] = useState({
    status: false,
    message: 'Fetching table records...',
  })

  const [authCreds] = useContext(AuthContext)
  const [showModalData, setShowModalData] = useState(true)
  const [tableData, setTableData] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  /* eslint-disable no-unused-vars */
  const [totalRows, setTotalRows] = useState(0)
  const [tableColumns, setTableColumns] = useState([])
  const [localPageIndex, setLocalPageIndex] = useState(0)
  const [columnFilters, setColumnFilters] = useState([])
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      fontSize: '12px',
      whiteSpace: 'break-spaces',
    },
  }))
  const [columnSort, setColumnSortValue] = useState([])
  const [modelId, setModelId] = useState('')
  const [modelName, setModelName] = useState('')
  const [isSort, setSort] = useState(false)
  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint
  const dbtGradingModels = useSelector((state) => state.getDbtModelsReducer)
  
  let userFetchTimeOut

  const table_name = 'USER'
  const columnMetadata = {
    USER: {
      fields: [
        {
          name: 'MODEL_NAME',
          headerTitle: 'MODEL NAME',
          is_editable: true,
          is_filterable: true,
          size: 20,
        },
        {
          name: 'GRADE',
          headerTitle: 'GRADE',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
        {
          name: 'DEVELOPER_EMAILS',
          headerTitle: 'DEVELOPER EMAILS',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
        {
          name: 'JOB_ESCALATION',
          headerTitle: 'JOB ESCALATION',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
        {
          name: 'JOB_NAME',
          headerTitle: 'JOB NAME',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
        {
          name: 'DATABASE_NAME',
          headerTitle: 'DATABASE NAME',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
        {
          name: 'SCHEMA_NAME',
          headerTitle: 'SCHEMA NAME',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
        {
          name: 'DBT_PROJECT_TAG',
          headerTitle: 'DBT PROJECT TAG',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },

        {
          name: 'MODEL_FILE_PATH',
          headerTitle: 'MODEL FILE PATH',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
      ],
      tag: 'user',
    },
  }
  const hiddenColumns = {
    USER: [
      'PROJECT_UID',
      'UID',
      'CREATED_ON',
      'CREATED_BY',
      'MODIFIED_ON',
      'MODIFIED_BY',
    ],
  }

  const fetchData = async (
    { pageSize = 10, pageIndex = 1 },
    // filters,
    sort_value = columnSort
  ) => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })
    if (uid || dbtAsset) {
      if (uid && columnFilters.length == 0) {
        columnFilters.push({ op: 'eq', field: 'PROJECT_UID', value: `${uid}` })
      } else if(dbtAsset && columnFilters.length == 0) {
        columnFilters.push({ op: 'eq', field: 'JOB_NAME', value: `${dbtAsset}` })
      }
      
      setLocalPageIndex(pageIndex)
      setShowModalData(true)
      const params = {
        page_size: pageSize,
        page_number: pageIndex + 1, //handle 0 indexing
        project_uid: uid,
        filters: JSON.stringify(columnFilters),
        sort: sort_value,
      }
      dispatch(getDbtGradingModels(endpoint, params))
        .then(() => {
          setLoadingStatus({
            status: false,
            message: 'Fetching table records...',
          })
        })
        .catch(() => {
          setLoadingStatus({
            status: false,
            message: 'Fetching table records...',
          })
        })
    }
  }

  const viewScoresModal = (e, uid, modelName) => {
    e.preventDefault()
    setModelId(uid)
    setModelName(modelName)
    setShowModalData(false)
  }
  const handleScoreModal = () => {
    setModelId('')
    setModelName('')
    setShowModalData(true)
  }
  useEffect(() => {
    let table_data = dbtGradingModels?.dbtGradingModels?.data
    if (table_data?.length > 0) {
      setTableColumns(
        TableHandler.createColumnMappingforKholab(
          [
            'MODEL_NAME',
            'GRADE',
            'DEVELOPER_EMAILS',
            'JOB_ESCALATION',
            'JOB_NAME',
            'DATABASE_NAME',
            'SCHEMA_NAME',
            'DBT_PROJECT_TAG',
            'MODEL_FILE_PATH',
          ],
          columnMetadata,
          table_name
        )
      )

      setTotalPages(dbtGradingModels?.dbtGradingModels?.num_pages)
      setTotalRows(dbtGradingModels?.dbtGradingModels?.total_results)
      setTableData(table_data)
    } else {
      setTotalPages(0)
      setTotalRows(0)
      setTableData([])
    }
  }, [setTableData, dbtGradingModels])

  const quillSearch = (searchTerm, renderList, mentionChar) => {
    clearTimeout(userFetchTimeOut)
    if (searchTerm.length >= 3) {
      userFetchTimeOut = setTimeout(() => {
        ForumHandler.fetchUserData(
          endpoint,
          searchTerm,
          renderList,
          mentionChar
        )
      }, 1000)
    }
  }

  const module = MentionHandler.mentionconfig(quillSearch)

  //handle sorting on columns by updating fetch data sort value
  const handleSorting = (header) => {
    setSort(true)

    if (columnSort.length !== 0 && columnSort[0].field !== header) {
      setColumnSortValue([
        { field: `${header}`, direction: columnSort[0].direction },
      ])
    } else {
      if (columnSort.length === 0) {
        setColumnSortValue([{ field: `${header}`, direction: 'asc' }])
      } else if (columnSort[0].direction === 'asc') {
        setColumnSortValue([{ field: `${header}`, direction: 'desc' }])
      } else {
        setColumnSortValue([])
      }
    }
  }

  // contains list of setTimout ids
  let timeoutIds = []

  // clears all the setTimeout from timeoutIds array
  const clearAllTimeout = () => {
    for (let i = 0; i < timeoutIds.length; i++) {
      clearTimeout(timeoutIds[i])
    }
    timeoutIds = []
  }

  // Default Column

  function DefaultColumnFilter({
    column: { filterValue, setFilter },
    headers,
  }) {
    const [initLoad, setInitLoad] = useState(true)

    useEffect(() => {
      if (initLoad === true) {
        return
      }
      clearAllTimeout()

      let timeoutId = setTimeout(() => {
        let filter_payload = []

        if (uid) {
          filter_payload.push({ op: 'eq', field: 'PROJECT_UID', value: `${uid}` })
        } else if(dbtAsset) {
          filter_payload.push({ op: 'eq', field: 'JOB_NAME', value: `${dbtAsset}` })
        }
        headers.forEach((header_obj, index) => {
          if (header_obj.filterValue !== undefined) {
            let multipleFilter = header_obj.filterValue.split(',')
            multipleFilter.forEach((value) => {
              if (value.length !== 0) {
                if (value[0] === '"' && value[value.length - 1] === '"') {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'like' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] =
                      '%' + value.slice(1, value.length - 1) + '%'
                    filter_payload.push(local_obj)
                  }
                } else if (
                  value[0] === '*' &&
                  value[value.length - 1] === '*'
                ) {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = value.slice(1, value.length - 1)
                    filter_payload.push(local_obj)
                  }
                } else {
                  let local_obj = { op: 'ilike' }
                  local_obj['field'] = header_obj.id
                  local_obj['value'] = '%' + value + '%'
                  filter_payload.push(local_obj)
                }
              } else {
                let local_obj = { op: 'ilike' }
                local_obj['field'] = header_obj.id
                local_obj['value'] = '%' + value + '%'
                filter_payload.push(local_obj)
              }
            })
          }
        })
        setInitLoad(true)
        setColumnFilters(filter_payload)
        fetchData({ pageSize, pageIndex })
      }, 2200)

      timeoutIds.push(timeoutId)
    }, [filterValue, pageSize])

    return (
      <input
        className="form-control"
        value={filterValue || ''}
        onFocus={() => {
          setInitLoad(false)
        }}
        placeholder="type to search..."
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
      />
    )
  }
  const defaultColumn = {
    Filter: DefaultColumnFilter,
  }

  const available_page_size = ['10', '50', '100', '200', '500']
  const data = useMemo(() => tableData, [hiddenColumns, tableData])
  const columns = useMemo(() => tableColumns, [hiddenColumns, tableColumns])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
    state: { selectedRowIds, pageIndex, pageSize },
    gotoPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: localPageIndex,
        hiddenColumns: hiddenColumns[table_name],
        pageSize: 10,
        selectedRowIds: { 1: false },
      },
      manualPagination: true,
      manualFilters: true,
      pageCount: totalPages,
      autoResetSortBy: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {}
  )

  // used for manual pagination
  const onNext = () => {
    gotoPage(pageIndex + 1)
  }
  const onPrevious = () => {
    gotoPage(pageIndex - 1)
  }

  useEffect(() => {
    fetchData(
      { pageSize, pageIndex },
      // JSON.stringify(columnFilters),
      JSON.stringify(columnSort)
    )
  }, [uid, pageSize, pageIndex, columnSort])
  // updates localStorage with new list of hiddenColumns
  useEffect(() => {
    localStorage.setItem('hidden_columns', JSON.stringify(hiddenColumns))
  }, [hiddenColumns])

  return (
    <>
      <Modal size="xl" show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>View DBT Grading Models {projectName ? `(${projectName})` : ''} {dbtAsset ? `(${dbtAsset})` : ''}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalBody">
          {loadingStatus.status ? (
            <LoadingStatus status_message={loadingStatus.message} />
          ) : (
            <>
              {showModalData ? (
                <div style={{ background: '#fff' }}>
                  <div
                    className="table-container"
                    style={{ textAlign: 'center' }}
                  >
                    <Table {...getTableProps()}>
                      <thead className="sticky-table-header">
                        {headerGroups.map((headerGroup, i) => (
                          <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, i) =>
                              column?.id === 'selection' ? (
                                <th key={column.id}>
                                  <div
                                    {...column.getHeaderProps(
                                      column.getSortByToggleProps(),
                                      { style: { width: column.size } }
                                    )}
                                    className="table-header-badge"
                                  >
                                    {column.render('Header')}

                                    {columnSort[0]?.field ===
                                    column['Header'] ? (
                                      <span>
                                        {columnSort[0]?.direction === 'asc'
                                          ? ' 🔽'
                                          : '' ||
                                            columnSort[0]?.direction === 'desc'
                                          ? ' 🔼'
                                          : ''}
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                  <div>
                                    {column.canFilter
                                      ? column.render('Filter')
                                      : null}
                                  </div>
                                </th>
                              ) : (
                                <th key={i}>
                                  <div
                                    onClick={() => handleSorting(column.id)}
                                    className="table-header-badge"
                                  >
                                    {column.render('Header')}

                                    {columnSort[0]?.field === column['id'] ? (
                                      <span>
                                        {columnSort[0]?.direction === 'asc'
                                          ? ' 🔽'
                                          : '' ||
                                            columnSort[0]?.direction === 'desc'
                                          ? ' 🔼'
                                          : ''}
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                  <div>
                                    {column.canFilter
                                      ? column.render('Filter')
                                      : null}
                                  </div>
                                </th>
                              )
                            )}
                          </tr>
                        ))}
                      </thead>
                      <tbody
                        className="tbody npsgroupmembers"
                        {...getTableBodyProps()}
                      >
                        {page.map((row, index) => {
                          prepareRow(row)
                          return (
                            <tr key={index}>
                              {row.cells.map((cell) => {
                                return cell.column.id === 'MODEL_NAME' ? (
                                  <td
                                    key={cell}
                                    {...cell.getCellProps({
                                      style: {
                                        width: cell.column.size + '%',
                                      },
                                    })}
                                  >
                                    <a
                                      href="#"
                                      className="dbt_links"
                                      title="Click to view DBT Grading Models Score"
                                      onClick={(e) =>
                                        viewScoresModal(
                                          e,
                                          cell.row.original.UID,
                                          cell.row.original.MODEL_NAME
                                        )
                                      }
                                    >
                                      {cell.render('Cell')}
                                    </a>
                                  </td>
                                ) : cell.value && cell.value.length > 60 ? (
                                  <BootstrapTooltip title={cell.render('Cell')}>
                                    <td
                                      key={cell}
                                      {...cell.getCellProps({
                                        style: {
                                          width: cell.column.size + '%',
                                          'max-width': '350px',
                                          'white-space': 'nowrap',
                                          overflow: 'hidden',
                                          'text-overflow': 'ellipsis',
                                        },
                                      })}
                                    >
                                      {cell.render('Cell')}
                                    </td>
                                  </BootstrapTooltip>
                                ) : (
                                  <td
                                    key={cell}
                                    {...cell.getCellProps({
                                      style: {
                                        width: cell.column.size + '%',
                                      },
                                    })}
                                  >
                                    {cell.render('Cell')}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </div>
                  {/* Bottom ToolBar of table */}
                  <Row>
                    <Col sm={12}>
                      <div className="page-control">
                        <div className="page-of">
                          Page{' '}
                          <em>
                            {pageIndex + 1} of {totalPages}
                          </em>
                        </div>
                        <div className="prev-next-btn">
                          <button
                            className="badge-btn"
                            onClick={() => onPrevious()}
                            disabled={!canPreviousPage}
                          >
                            {' '}
                            Prev{' '}
                          </button>
                          <button
                            onClick={() => onNext()}
                            className="badge-btn"
                            disabled={!canNextPage}
                          >
                            {' '}
                            Next{' '}
                          </button>
                        </div>
                        <div className="second-control">
                          <span>Go to page:</span>
                          <input
                            className="page-number-input"
                            type="number"
                            defaultValue={pageIndex || 1}
                            onBlur={(e) => {
                              const page = e.target.value
                                ? Number(e.target.value)
                                : 0
                              // handling zero indexing
                              gotoPage(page - 1)
                            }}
                          />
                          <select
                            value={pageSize}
                            onChange={(e) => {
                              setPageSize(Number(e.target.value))
                            }}
                          >
                            {available_page_size.map((pageSize) => (
                              <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            marginLeft: '24px',
                            fontWeight: 600,
                            fontSize: '14px',
                          }}
                        >
                          {' '}
                          Total results:{' '}
                          {dbtGradingModels?.dbtGradingModels?.total_results}
                        </div>
                      </div>
                    </Col>
                    {/* Bottom-right Buttons */}
                  </Row>
                </div>
              ) : (
                <DBTGradingScoreDashboard
                  model_id={modelId}
                  modelName={modelName}
                  handleScoreModal={handleScoreModal}
                />
              )}
            </>
          )}
          <Modal.Footer>
            <Button
              variant="danger"
              type="submit"
              className="addModalBtn"
              onClick={handleCloseModal}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  )
}
