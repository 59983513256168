import React from 'react'
import './indexChapter.css'
import { BiPencil } from 'react-icons/bi'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { styled } from '@mui/material/styles'
import Rating from '@mui/material/Rating'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}))

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: 'Very Satisfied',
  },
}

function IconContainer(props) {
  const { value, ...other } = props
  return <span {...other}>{customIcons[value].icon}</span>
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
}
export default function Input({
  objValue,
  onChange,
  index,
  deleteField,
  editField,
  disabled,
  viewAnswerForm = "false",
}) {
  const { label, placeholder, required, type, value, ratingType, options } =
    objValue
  const newArr = options?.filter(
    (element) => element !== null && element !== '' && element !== undefined
  )

  return (
    <div className="input-group-nps">
      {type == 'text-read-only' ? (
        <></>
      ) : (
        <>
          <div>
            <label htmlFor={label}>{label}</label>
            {required == true ? (
              <label htmlFor={label} style={{ color: 'red' }}>
                *
              </label>
            ) : (
              <></>
            )}
          </div>
        </>
      )}

      {type == 'radio' ? (
        <div className="input-nps">
          {newArr?.map((option, i) => {
            return (
              <div key={i} className="radio-input-nps">
                <input
                  type="radio"
                  id={option}
                  value={option}
                  checked={option == value ? true : false}
                  onChange={(e) => onChange(e, index)}
                  disabled={
                    disabled == 'true' || disabled == true ? true : false
                  }
                />
                <label className="radio-label-nps" htmlFor={option}>
                  {option}
                </label>
              </div>
            )
          })}
          {disabled == 'true' ? (
            <> </>
          ) : (
            <>
              <div onClick={(e) => deleteField(e, index)}>X</div>

              <div onClick={(e) => editField(e, index)}>
                <BiPencil />
              </div>
            </>
          )}
        </div>
      ) : type == 'checkbox' ? (
        <div className="input-nps">
          {newArr?.map((option, i) => {
            return (
              <div key={i} className="radio-input-nps">
                <input
                  type="checkbox"
                  id={option}
                  value={option}
                  checked={
                    objValue?.checkboxValue?.includes(option) ? true : false
                  }
                  onChange={(e) => onChange(e, index)}
                  disabled={
                    disabled == 'true' || disabled == true ? true : false
                  }
                />
                <label className="radio-label-nps" htmlFor={option}>
                  {option}
                </label>
              </div>
            )
          })}
          {disabled == 'true' ? (
            <> </>
          ) : (
            <>
              <div onClick={(e) => deleteField(e, index)}>X</div>

              <div onClick={(e) => editField(e, index)}>
                <BiPencil />
              </div>
            </>
          )}
        </div>
      ) : type == 'Nps' ? (
        <>
          <div>
            <div style={{ width: '100%', marginRight: '15px' }}>
              <div className="nps-form">
                <div className="row-nps">
                  {[...Array(11).keys()].map((num) => (
                    <button
                      key={num}
                      defaultValue={value}
                      className={`btn-input ${
                        num === 0 ? 'btn-0' : num === 10 ? 'btn-10' : ''
                      } ${value === num ? 'active' : ''}`}
                      disabled={
                        disabled == 'true' || disabled == true ? true : false
                      }
                    >
                      <b>{num}</b>
                    </button>
                  ))}
                </div>
                <div className="text-edit-me">
                  <div className="not-at-all1">NOT AT ALL LIKELY</div>
                  <div className="not-at-all1">EXTREMELY LIKELY</div>
                </div>
              </div>
            </div>
            {disabled == 'true' ? (
              <></>
            ) : (
              <>
                <div
                  className="textarea-cancel"
                  onClick={(e) => deleteField(e, index)}
                >
                  X
                </div>
                <div
                  className="textarea-cancel"
                  onClick={(e) => editField(e, index)}
                >
                  <BiPencil />
                </div>
              </>
            )}
          </div>
        </>
      ) : type == 'textarea' ? (
        <div>
          <div className="input-nps">
            <textarea
              id={label}
              placeholder={placeholder || ''}
              onChange={(e) => onChange(e, index)}
              rows="4"
              cols="70"
              value={value}
              disabled={disabled == 'true' || disabled == true ? true : false}
            />
          </div>
          {disabled == 'true' ? (
            <></>
          ) : (
            <>
              <div
                className="textarea-cancel"
                onClick={(e) => deleteField(e, index)}
              >
                X
              </div>
              <div
                className="textarea-cancel"
                onClick={(e) => editField(e, index)}
              >
                <BiPencil />
              </div>
            </>
          )}
        </div>
      ) : type == 'text-read-only' ? (
        <>
          <div className="input-nps">
            <p>{parse(label)}</p>{' '}
            <div
              className="textarea-cancel"
              onClick={(e) => deleteField(e, index)}
            >
              X
            </div>
            <div
              className="textarea-cancel"
              onClick={(e) => editField(e, index)}
            >
              <BiPencil />
            </div>
          </div>
        </>
      ) : type == 'rating' ? (
        <>
          <div className="input-nps">
            {ratingType == 'Heart' ? (
              <StyledRating
                name="customized-color"
                defaultValue={value}
                getLabelText={(value) =>
                  `${value} Heart${value !== 1 ? 's' : ''}`
                }
                precision={1}
                style={{ color: 'red' }}
                icon={<FavoriteIcon fontSize="inherit" />}
                emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
              />
            ) : ratingType == 'star' ? (
              <Rating
                name="half-rating"
                defaultValue={value}
                precision={1}
                readOnly
              />
            ) : ratingType == 'faces' ? (
              <>
                <StyledRating
                  name="highlight-selected-only"
                  defaultValue={value}
                  IconContainerComponent={IconContainer}
                  getLabelText={(value) => customIcons[value].label}
                  highlightSelectedOnly
                />{' '}
              </>
            ) : (
              <></>
            )}

            {disabled == 'true' ? (
              <></>
            ) : (
              <>
                <div
                  className="textarea-cancel"
                  onClick={(e) => deleteField(e, index)}
                >
                  X
                </div>
                <div
                  className="textarea-cancel"
                  onClick={(e) => editField(e, index)}
                >
                  <BiPencil />
                </div>
              </>
            )}
          </div>
        </>
      ) : type === 'text' && viewAnswerForm === 'true' && disabled === 'true' ? (
        <div className="input-nps">
           <OverlayTrigger
                overlay={<Tooltip id="tooltip">{value}</Tooltip>}
              >
          <input
            type={type || 'text'}
            id={label}
            value={value || ''}
            placeholder={placeholder || ''}
            onChange={(e) => onChange(e, index)}
            disabled={disabled == 'true'}
          />
          </OverlayTrigger>
        </div>
      ) : (
        <>
          {' '}
          <div className="input-nps">
            <input
              type={type || 'text'}
              id={label}
              value={value || ''}
              placeholder={placeholder || ''}
              onChange={(e) => onChange(e, index)}
              disabled={disabled == 'true' || disabled == true ? true : false}
            />
            {disabled == 'true' ? (
              <></>
            ) : (
              <>
                <div onClick={(e) => deleteField(e, index)}>X</div>
                <div onClick={(e) => editField(e, index)}>
                  <BiPencil />
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  )
}
