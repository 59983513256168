import React, { useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Chip,
  Tooltip as MuiTooltip,
} from '@mui/material';
import DynamicLineChart from '../Utilities/DynamicLineChart';
import './BadgeDensityChart.css'

const BadgeDensityChart = ({ badgeData }) => {
  const [aggregationLevel, setAggregationLevel] = useState('All');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [chartData, setChartData] = useState([]);

  const formatMonth = (dateString) => {
    const [year, month] = dateString.split('-');
    const date = new Date(year, parseInt(month) - 1);
    return date.toLocaleString('en-US', { month: 'short' }) + ' ' + year;
  };

  const getAllMonths = (data) => {
    const months = new Set();
    data.forEach(item => {
      item.data.forEach(d => months.add(d.month));
    });
    return Array.from(months).sort((a, b) => new Date(a) - new Date(b));
  };

  const transformData = (level, selectedItems) => {
    const sourceData = {
      All: badgeData?.chapters || [],
      Company: badgeData?.companies || [],
      'KHC Group': badgeData?.khcGroups || []
    }[level] || [];

    const filteredData = sourceData.filter(item => 
      selectedItems.includes(item?.name)
    );

    if (!filteredData.length) return [];

    return getAllMonths(filteredData).map(month => {
      const dataPoint = { 
        month: formatMonth(month), 
        originalMonth: month 
      };
      
      filteredData.forEach(item => {
        const monthData = item.data.find(d => d.month === month);
        if (monthData) {
          dataPoint[item.name] = monthData.bdm;
          dataPoint[`${item.name}_data`] = {
            badgeCount: monthData?.badgeCount,
            employees: monthData?.employees
          };
        }
      });
      return dataPoint;
    });
  };

  const getCurrentLevelOptions = (level) => {
    const options = {
      All: badgeData?.chapters || [],
      Company: badgeData?.companies || [],
      'KHC Group': badgeData?.khcGroups || []
    }[level] || [];
    return options.map(item => item.name);
  };

  const handleAggregationChange = (event) => {
    const newLevel = event.target.value;
    setAggregationLevel(newLevel);
  };

  const handleOptionsChange = (event) => {
    const options = event.target.value;
    setSelectedOptions(options);
    setChartData(transformData(aggregationLevel, options));
  };

  useEffect(() => {
    if (badgeData) {
      if (aggregationLevel === 'All') {
        const availableChapters = getCurrentLevelOptions(aggregationLevel);
        if (availableChapters.length > 0) {
          setSelectedOptions([availableChapters[0]]);
          setChartData(transformData(aggregationLevel, [availableChapters[0]]));
        }
      } else {
        const allOptions = getCurrentLevelOptions(aggregationLevel);
        setSelectedOptions(allOptions);
        setChartData(transformData(aggregationLevel, allOptions));
      }
    }
  }, [aggregationLevel, badgeData]);

  const renderControls = () => (
    <div className="bdm-controls-stack">
      <MuiTooltip title="Select how you want to group and view the badge density data" arrow>
        <FormControl className={`bdm-form-control ${aggregationLevel === 'All' ? 'bdm-form-control-all' : 'bdm-form-control-full'}`}>
          <InputLabel>Select Aggregation Level</InputLabel>
          <Select
            value={aggregationLevel}
            onChange={handleAggregationChange}
            label="Select Aggregation Level"
          >
            {['All', 'Company', 'KHC Group'].map(level => (
              <MenuItem key={level} value={level}>{level}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </MuiTooltip>

      {aggregationLevel !== 'All' && (
        <FormControl className="bdm-form-control bdm-form-control-full">
          <InputLabel>Select {aggregationLevel}</InputLabel>
          <Select
            multiple
            value={selectedOptions}
            onChange={handleOptionsChange}
            label={`Select ${aggregationLevel}`}
            renderValue={(selected) => (
              <div className="bdm-chip-container">
                {selected.map((value) => (
                  <Chip key={value} label={value} size="small" />
                ))}
              </div>
            )}
          >
            {getCurrentLevelOptions(aggregationLevel).map((item) => (
              <MenuItem key={item} value={item}>
                <Checkbox checked={selectedOptions.includes(item)} />
                <ListItemText primary={item} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );

  return (
    <div className="bdm-chart-container">
      {renderControls()}
      
      <DynamicLineChart
        data={chartData}
        lines={selectedOptions.map(option => ({
          dataKey: option,
          name: option
        }))}
        xAxisKey="month"
        yAxisLabel="BADGE DENSITY"
        connectNulls={false}
        dotConfig={{ r: 4 }}
        activeDotConfig={{ r: 6 }}
        strokeWidth={3}
        lineType="monotone"
        gridConfig={{ strokeDasharray: '3 3' }}
        valueTitle = "Badge Density"
      />
    </div>
  );
};

export default BadgeDensityChart;
