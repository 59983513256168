import React, { useEffect, useContext, useState, useRef } from 'react'
import jwt_decode from 'jwt-decode'
import Sidebar from '../AppBar/Appbar'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import Button from '@mui/material/Button'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import { AsyncPaginate } from 'react-select-async-paginate'
import axios from 'axios'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import LoadingStatus from '../../Helper/LoadingStatus'
import { useDispatch, useSelector } from 'react-redux'
import { AuthContext } from '../../context/authProvider'
import parse from 'html-react-parser'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { getIdeaDetail, approveOrRejectIdea, assignIdea, createIdeasVote, markIdeaCompletion, editIdea, createComments, getcommentsbyid } from '../../../redux/actions/ideasActions/ideasAction'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import LayersClearIcon from '@mui/icons-material/LayersClear'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import SendIcon from '@mui/icons-material/Send'
import EditNoteIcon from '@mui/icons-material/EditNote'
import { status_label, BootstrapTooltip, complexityArr, categoryArr } from './IdeaFunction'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import PersonRemoveIcon from '@mui/icons-material/PersonRemove'
import { toastTopRightError } from '../../../components/Helper/ToastObjects'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { RoleHandler } from '../Utilities/roleCheck'
const ViewIdeaDetail = () => {
  let history = useNavigate()

  const dispatch = useDispatch()
  const [authCreds] = useContext(AuthContext)
  const endpoint = authCreds.restEndpoint

  const [statusLabelStyle, setStatusLabelStyle] = useState('')
  const [editorContent, setEditorContent] = useState('')
  let search = window.location.search
  const params = new URLSearchParams(search)
  const id = params.get('q')
  let decodedToken
  let tokenValues = localStorage?.getItem('token')
  if (tokenValues) {
    decodedToken = jwt_decode(tokenValues)
  }
  const handleQuill = (value) => {
    setEditorContent(value)
  }
  const roleValue = RoleHandler.checkCollabAPIAdminRole(tokenValues)
  const loadingMsg = 'Fetching Idea record detail...'
  const approveOrReject = (status) => {
    if (typeof status == 'boolean') {
      let statusText = 'Reject'
      if (status) {
        statusText = 'Approve'
      }
      if (
        window.confirm(
          'Are you sure you want to ' + statusText + ' this idea ?'
        )
      ) {
        setLoadingStatus({
          status: true,
          message: loadingMsg,
        })
        if (decodedToken && decodedToken.preferred_username) {
          let params = {
            id: id,
            is_approved: status,
            user_email: decodedToken.preferred_username.toLowerCase(),
          }
          // approve or reject Idea
          dispatch(approveOrRejectIdea(endpoint, params)).then(() => {
            setLoadingStatus({ status: false, message: loadingMsg })
            fetchData()
          })
        } else {
          setLoadingStatus({ status: false, message: loadingMsg })
          toast('Please login to continue.', toastTopRightError)
        }
      }
    } else {
      toast('Please click the button again!', toastTopRightError)
    }
  }
  const deprecateIdea = () => {
    if (
      window.confirm(
        'Are you sure you want to Deprecate this Idea ?'
      )
    ) {
      setLoadingStatus({
        status: true,
        message: loadingMsg,
      })
      if (decodedToken && decodedToken.preferred_username) {
        let params = {
          id: id,
          status: 'Deprecated',
          title: getIdeaDetailData?.title,
          description: getIdeaDetailData?.description,
          idea_category: getIdeaDetailData?.idea_category,
          complexity: getIdeaDetailData?.complexity,
          chapter: getIdeaDetailData?.chapter,
          assigned_to: getIdeaDetailData?.assigned_to,
          start_date: getIdeaDetailData?.start_date,
          end_date: getIdeaDetailData?.end_date,
          comments: getIdeaDetailData?.comments,
          user_email: decodedToken.preferred_username.toLowerCase(),
        }
      // deprecate Idea
        dispatch(editIdea(endpoint, params)).then(() => {
          setLoadingStatus({ status: false, message: loadingMsg })
          fetchData()
        })
      } else {
        setLoadingStatus({ status: false, message: loadingMsg })
        toast('Please login to continue.', toastTopRightError)
      }
    }
    
  }
  const unassignIdea = () => {
    if (
      window.confirm(
        'Are you sure you want to Unassign this Idea?'
      )
    ) {
      setLoadingStatus({
        status: true,
        message: loadingMsg,
      })

      if (decodedToken && decodedToken.preferred_username) {
        let params = {
          id: id,
          status: 'Unassigned',
          title: getIdeaDetailData?.title,
          description: getIdeaDetailData?.description,
          idea_category: getIdeaDetailData?.idea_category,
          complexity: getIdeaDetailData?.complexity,
          chapter: getIdeaDetailData?.chapter,
          assigned_to: null,
          start_date: null,
          user_email: decodedToken.preferred_username.toLowerCase(),
        }
        // Unassign Idea
        dispatch(editIdea(endpoint, params)).then(() => {
          setLoadingStatus({ status: false, message: loadingMsg })
          fetchData()
        })
      } else {
        setLoadingStatus({ status: false, message: loadingMsg })
        toast('Please login to continue.', toastTopRightError)
      }
    }
    
  }
  const handleVote = (e) => {
    e.stopPropagation()
    if (decodedToken && decodedToken.preferred_username) {
      let payload = {
        id: id,
        user_email: decodedToken.preferred_username.toLowerCase(),
        down_vote: false,
      }
      setLoadingStatus({ status: true, message: 'Voting Inprogress...' })
      dispatch(createIdeasVote(endpoint, payload))
        .then(() => {
          setLoadingStatus({ status: false, message: 'Voted Successfully' })
          fetchData()
        })
        .catch(() => {
          setLoadingStatus({ status: false, message: 'Voting Error.' })
        })
    }
  }
  const initLoad = useRef(false)
  const [showAssignIdea, setShowAssignIdea] = useState(false)
  const [showEditIdeas, setShowEditIdeas] = useState(false)
  const [ideaTitle, setIdeaTitle] = useState('')
  const [description, setDescripiton] = useState('')
  const [category, setCategory] = useState('')
  const [chapter, setChapter] = useState('')
  const [complexity, setComplexity] = useState('')
  const [showIdeaCompletion, setShowIdeaCompletion] = useState(false)
  const [assigneeEmail, setAssigneeEmail] = useState('')
  const [completionComment, setCompletionComment] = useState('')
  const handleCloseEditIdeas = () => {
    setShowEditIdeas(false)
    setIdeaTitle('')
    setDescripiton('')
    setCategory('')
    setChapter('')
    setComplexity('')
  }
  const OpenEditIdeaModal = () => {
    setIdeaTitle(getIdeaDetailData?.title)
    setDescripiton(getIdeaDetailData?.description)
    setCategory(getIdeaDetailData?.idea_category)
    setChapter(getIdeaDetailData?.chapter)
    setComplexity(getIdeaDetailData?.complexity)
    setShowEditIdeas(true)
  }
  const handleEditIdea = () => {
    setLoadingStatus({ status: true, message: loadingMsg })
    if (ideaTitle && description && category && chapter && complexity && id) {
      if (decodedToken && decodedToken.preferred_username) {
        const body = {
          id: id,
          title: ideaTitle,
          description: description,
          user_email: decodedToken.preferred_username.toLowerCase(),
          idea_category: category,
          chapter: chapter,
          complexity: complexity,
        }
        // Edit Idea
        dispatch(editIdea(endpoint, body)).then(() => {
          setLoadingStatus({ status: false, message: loadingMsg })
          handleCloseEditIdeas()
          fetchData()
        })
      } else {
        setLoadingStatus({ status: false, message: loadingMsg })
        toast('Please login to continue.', toastTopRightError)
      }
    } else {
      setLoadingStatus({ status: false, message: loadingMsg })
      toast('Please fill all the required fields', toastTopRightError)
    }
  }

  const handleCloseAssignIdea = () => {
    setShowAssignIdea(false)
    setAssigneeEmail('')
    setAssignedName('')
  }
  const loadOptions = async (search, { page }) => {
    if (typeof page === 'undefined') {
      // Your variable is undefined
      page = 0
    }
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }
    const response = await axios
      .get(
        `${endpoint}/api/collab/get_users?user_email=${search}&page_number=${page}&page_size=10`,
        { headers: post_headers }
      )
      .then((res) => {
        return res?.data?.data
      })
    return {
      options: response,
      // hasMore: response.length >= 1,
      additional: {
        page: search ? 2 : page + 1,
      },
    }
  }
  const [assignedName, setAssignedName] = useState('')
  const OpenAssignIdeaModal = async (emailVal = '') => {
    setAssigneeEmail('')
    if (emailVal) {
      setAssignedName(emailVal.split('@')[0])
    }

    setShowIdeaCompletion(false)
    setShowAssignIdea(true)
  }
  const handleAssignIdea = () => {
    setLoadingStatus({
      status: true,
      message: loadingMsg,
    })
    if (decodedToken && decodedToken.preferred_username) {
      if (assigneeEmail && assigneeEmail.user_email) {
        const params = {
          id: id,
          user_email: decodedToken.preferred_username.toLowerCase(),
          assigned_to: assigneeEmail.user_email,
        }
        dispatch(assignIdea(endpoint, params)).then(() => {
          setLoadingStatus({ status: false, message: loadingMsg })
          fetchData()
          setShowAssignIdea(false)
        })
      } else {
        setLoadingStatus({ status: false, message: loadingMsg })
        toast('Please select the valid assignee.', toastTopRightError)
      }
    } else {
      setLoadingStatus({ status: false, message: loadingMsg })
      toast('Please login to continue.', toastTopRightError)
    }
  }
  const OpenMarkCompleteIdeaModal = () => {
    setShowIdeaCompletion(true)
    setShowAssignIdea(false)
    setCompletionComment('')
  }
  const handleCloseIdeaCompletion = () => {
    setShowIdeaCompletion(false)
    setShowAssignIdea(false)
    setAssigneeEmail('')
    setAssignedName('')
    setCompletionComment('')
  }
  const handleMarkAsCompleteIdea = () => {
    setLoadingStatus({
      status: true,
      message: loadingMsg,
    })

    if (completionComment) {
      if (decodedToken && decodedToken.preferred_username) {
        const params = {
          id: id,
          user_email: decodedToken.preferred_username.toLowerCase(),
          comments: completionComment,
        }
        dispatch(markIdeaCompletion(endpoint, params)).then(() => {
          setLoadingStatus({ status: false, message: loadingMsg })
          fetchData()
          setShowIdeaCompletion(false)
        })
      } else {
        setLoadingStatus({ status: false, message: loadingMsg })
        toast('Please login to continue.', toastTopRightError)
      }
    } else {
      setLoadingStatus({ status: false, message: loadingMsg })
      toast('Please Enter a comments.', toastTopRightError)
    }
  }

  const getIdeaDetailData = useSelector(
    (state) => state.getIdeaDetailReducer.getIdeaDetail.data
  )
  const getCommentsData = useSelector((state)=>  state.getIdeaDetailReducer.getIdeaDetailComments.data)
  const fetchData = async () => {
    const params = { idea_id: id }
    const url = `${endpoint}/api/ideas/get_idea_by_id`
    setLoadingStatus({
      status: true,
      message: loadingMsg,
    })
    dispatch(getIdeaDetail(url, params))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: loadingMsg,
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: loadingMsg,
        })
      })
  }
  
  const getIdeaComments = async()=>{
    const params = { idea_id: id }
    setLoadingStatus({
      status: true,
      message: loadingMsg,
    })
    dispatch(getcommentsbyid(endpoint, params))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: loadingMsg,
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: loadingMsg,
        })
      })
  }
  const handleSubmit = async () => {
    setLoadingStatus({ status: true, message: 'Fetching data...' })
    if (editorContent.replace(/<(.|\n)*?>/g, '').trim().length !== 0) {
      const body = {
        "idea_id": id,
        "user_email": decodedToken.preferred_username.toLowerCase(),
        "chat": editorContent
      }

      dispatch(createComments(endpoint, body)).then(() => {
        setLoadingStatus({ status: false, message: 'Fetching data...' })
        setEditorContent('')
        getIdeaComments()
      })
    } else {
      setLoadingStatus({ status: false, message: 'Fetching data...' })
      toast('Comment can not be empty', toastTopRightError)
    }
  }

  useEffect(() => {
    if (!initLoad.current) {
      initLoad.current = true;
      fetchData()
      getIdeaComments();
    }

  }, [id])
  useEffect(() => {
    if (!localStorage.getItem('channel_metadata')) {
      history('/ideas')
    }
  }, [])

  useEffect(() => {
    if (getIdeaDetailData?.status) {
      let retArr = status_label(getIdeaDetailData?.status)
      setStatusLabelStyle(retArr.badge_status_class)
    }
  }, [getIdeaDetailData])

  const [loadingStatus, setLoadingStatus] = useState({
    status: false,
    message: loadingMsg,
  })
  const formatData = (data) => {
    if (data) {
      data = ' ' + data
    } else {
      data = 'N/A'
    }

    return data
  }

  return (
    <Sidebar
      Page="View Idea Detail"
      Index={
        loadingStatus.status ? (
          <LoadingStatus status_message={loadingStatus.message} />
        ) : (
          <div style={{ backgroundColor: '#fff', padding: '1em' }}>
            <Breadcrumb>
              <Breadcrumb.Item
                onClick={()=>history('/ideas')}
                style={{ fontWeight: '600', lineHeight: '1', color: '#1b84ff' }}
              >
                Ideas
              </Breadcrumb.Item>
              <Breadcrumb.Item
                active
                style={{ fontWeight: '600', lineHeight: '1', color: '#1b84ff' }}
              >
                {getIdeaDetailData?.title}
              </Breadcrumb.Item>
            </Breadcrumb>
            <Card style={{ marginTop: '1em' }}>
              <Card.Header style={{ textAlign: 'center', fontWeight: 'bold' }}>
                {getIdeaDetailData?.title}
              </Card.Header>
              <Card.Body>
                <Table bordered>
                  <tbody>
                    <tr>
                      <td>
                        <span className="idea-summary-label">Category</span>
                      </td>
                      <td>
                        <span className="idea_category_badge">
                          {formatData(
                            categoryArr[getIdeaDetailData?.idea_category]
                          )}
                        </span>
                      </td>
                      <td>
                        <span className="idea-summary-label">Status</span>
                      </td>
                      <td>
                        <span
                          className={`idea_category_badge ${statusLabelStyle}`}
                        >
                          {formatData(getIdeaDetailData?.status)}
                        </span>
                      </td>
                      <td>
                        <span className="idea-summary-label">Approved</span>
                      </td>
                      <td>{getIdeaDetailData?.is_approved ? 'Yes' : 'No'}</td>
                      <td>
                        <span className="idea-summary-label">Complexity</span>
                      </td>
                      <td>{formatData(getIdeaDetailData?.complexity)}</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="idea-summary-label">Views</span>
                      </td>
                      <td>
                        <span
                          style={{
                            borderRadius: '9999px',
                            backgroundColor: '#227D9B',
                            color: '#fff',
                            padding: '0.45em',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'inline-flex',
                            width: '1.75rem',
                            height: '1.75rem',
                          }}
                        >
                          {getIdeaDetailData?.view_count
                            ? getIdeaDetailData?.view_count
                            : 0}
                        </span>
                      </td>
                      <td>
                        <span className="idea-summary-label">Likes</span>
                      </td>
                      <td>
                        <span
                          style={{
                            borderRadius: '9999px',
                            backgroundColor: '#227D9B',
                            color: '#fff',
                            padding: '0.45em',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'inline-flex',
                            width: '1.75rem',
                            height: '1.75rem',
                          }}
                        >
                          {getIdeaDetailData?.vote_count
                            ? getIdeaDetailData?.vote_count
                            : 0}
                        </span>
                      </td>
                      <td>
                        <span className="idea-summary-label">Start Date</span>
                      </td>
                      <td>
                        {(getIdeaDetailData && getIdeaDetailData.start_date && getIdeaDetailData.start_date != "None")
                          ? new Date(getIdeaDetailData?.start_date)
                              .toISOString()
                              .split('T')[0]
                          : 'N/A'}
                      </td>
                      <td>
                        <span className="idea-summary-label">End Date</span>
                      </td>
                      <td>
                        {(getIdeaDetailData && getIdeaDetailData.end_date && getIdeaDetailData.end_date != "None")

                          ? new Date(getIdeaDetailData?.end_date)
                              .toISOString()
                              .split('T')[0]
                          : 'N/A'}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="idea-summary-label">Chapter</span>
                      </td>
                      <td>
                        <span className="idea_category_badge">
                          {formatData(getIdeaDetailData?.chapter)}
                        </span>
                      </td>
                      <td>
                        <span className="idea-summary-label">Requester</span>
                      </td>
                      <td style={{ textTransform: 'capitalize' }}>
                        {getIdeaDetailData?.created_by
                          ? getIdeaDetailData.created_by.split('@')[0]
                          : 'N/A'}
                      </td>
                      <td>
                        <span className="idea-summary-label">Assignee</span>
                      </td>
                      <td colSpan={3} style={{ textTransform: 'capitalize' }}>
                        {getIdeaDetailData?.assigned_to
                          ? getIdeaDetailData.assigned_to.split('@')[0]
                          : 'N/A'}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Card.Text className='idea-description-wrap'>{getIdeaDetailData?.description}</Card.Text>
                {getIdeaDetailData?.comments ? (
                  <>
                    <hr />
                    <div
                      style={{
                        color: '#071437',
                        lineHeight: '1.25rem',
                        fontSize: '1.125rem',
                        fontWeight: '600',
                      }}
                    >
                      Completion Comment
                    </div>
                    <div className='idea_text_capitalize'
                      style={{
                        color: '#071437',
                        lineHeight: '1.25rem',
                        fontSize: '1.125rem',
                        fontWeight: '600'
                      }}
                    >
                      <AccountCircleIcon />{' '}
                      {getIdeaDetailData?.modified_by
                        ? getIdeaDetailData.modified_by.split('@')[0]
                        : 'N/A'}
                    </div>
                    <div>{getIdeaDetailData?.comments}</div>
                  </>
                ) : (
                  ''
                )}
              </Card.Body>
              <Card.Footer className='idea-footer'>
                <div className="idea-footer-vote">
                  <BootstrapTooltip title={getIdeaDetailData?.is_existing_vote ? 'Click to Remove Like from this Idea' :'Click to Like this Idea'}>
                    <Button
                      size="medium"
                      variant="outlined"
                      className={`outlined-primary ${getIdeaDetailData?.is_existing_vote ? 'idea-button-liked' :''}` }
                      onClick={(e) => handleVote(e)}
                    >
                      <ThumbUpOffAltIcon /> {getIdeaDetailData?.is_existing_vote ? 'Liked' :'Like'}
                    </Button>
                  </BootstrapTooltip>
                </div>
                {(roleValue || (getIdeaDetailData && decodedToken && getIdeaDetailData.created_by == decodedToken.preferred_username.toLowerCase() && getIdeaDetailData.status == 'New')) && (
                  <div className="idea-footer-action">
                    {getIdeaDetailData && getIdeaDetailData.status == 'New' && roleValue && (
                      <>
                        <BootstrapTooltip title="Click to Approve idea">
                          <Button
                            size="medium"
                            variant="outlined"
                            className="outlined-success"
                            onClick={() => approveOrReject(true)}
                          >
                            <CheckCircleOutlineIcon /> Approve
                          </Button>
                        </BootstrapTooltip>
                        <BootstrapTooltip title="Click to Reject idea">
                          <Button
                            size="medium"
                            variant="outlined"
                            className="outlined-danger"
                            onClick={() => approveOrReject(false)}
                          >
                            <CancelOutlinedIcon /> Reject
                          </Button>
                        </BootstrapTooltip>
                      </>
                    )}
                    {getIdeaDetailData && getIdeaDetailData.status == 'Unassigned' && roleValue && (
                        <BootstrapTooltip title="Click to Assign Idea">.
                          <Button
                            size="medium"
                            variant="outlined"
                            onClick={() => OpenAssignIdeaModal()}
                          >
                            <AccountCircleIcon /> Assign
                          </Button>
                        </BootstrapTooltip>
                      )}
                    {getIdeaDetailData && getIdeaDetailData.status == 'In Progress' && roleValue && (
                        <>
                          <BootstrapTooltip title="Click to Reassign Idea">
                            <Button
                              size="medium"
                              variant="outlined"
                              onClick={() =>
                                OpenAssignIdeaModal(
                                  getIdeaDetailData.assigned_to
                                )
                              }
                            >
                              <AccountCircleIcon />
                              Reassign
                            </Button>
                          </BootstrapTooltip>
                          <BootstrapTooltip title="Click to remove Assignee and move this Idea to Unassign status. ">
                            <Button
                              size="medium"
                              variant="outlined"
                              className='outlined-warning'
                              onClick={() => unassignIdea()}
                            >
                              <PersonRemoveIcon />&nbsp;
                              Unassign
                            </Button>
                          </BootstrapTooltip>
                          <BootstrapTooltip title="Mark the Idea as Complete">
                            <Button
                              size="medium"
                              variant="outlined"
                              className="outlined-success"
                              onClick={() => OpenMarkCompleteIdeaModal()}
                            >
                              <CheckCircleOutlineIcon /> Mark as Complete
                            </Button>
                          </BootstrapTooltip>
                        </>
                      )}
                    <BootstrapTooltip title="Click to View & Edit Idea details">
                      <Button
                        size="medium"
                        variant="outlined"
                        className="outlined-primary"
                        onClick={() => OpenEditIdeaModal()}
                      >
                        <EditNoteIcon /> Edit
                      </Button>
                    </BootstrapTooltip>
                    {getIdeaDetailData && getIdeaDetailData.status != 'Deprecated' && roleValue &&(<BootstrapTooltip title="Click to Archieve this Idea by updating Deprecate status for this Idea.">
                      <Button
                        size="medium"
                        variant="outlined"
                        className='outlined-danger'
                        onClick={() => deprecateIdea()}
                      >
                        <LayersClearIcon /> Deprecate
                      </Button>
                    </BootstrapTooltip>)}
                  </div>
                  )}
              </Card.Footer>
              <div className='idea-comments-cont'>
                <h2 className='idea-comment-section-heading'>Comments</h2>
                {getCommentsData && getCommentsData.length > 0 && 
                  getCommentsData.map(item=>{
                    return (
                <div key={item.id} className='idea-comment'>
                  <div className='idea-comment-heading'>
                    <div className='idea-comment-heading-user'>{item.created_by.split('@')[0]}</div>
                    <div className='idea-comment-heading-date'>{new Date(item.created_on).toISOString().split('T')[0]}</div>
                  </div>
                  <div>
                    <p>{parse(item.chat)}</p>
                  </div>
                </div>
                    )

                  })
                }
                <div className="post-comments-form">
                  <ReactQuill theme="snow" className="react-quill" defaultValue={editorContent} onChange={handleQuill} placeholder='Enter your comments' />
                  <div className='idea-post-comment-btn-cont'>
                    <BootstrapTooltip title="Click to submit your comments for this Idea">
                    <Button variant="primary" className="idea-post-comment-btn" type="submit" onClick={handleSubmit}>
                        Post comments <SendIcon className='idea-post-comment-btn-icon' />
                    </Button>
                    </BootstrapTooltip>
                  </div>
                </div>
              </div>
            </Card>
            <Modal show={showAssignIdea} onHide={handleCloseAssignIdea}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {assignedName ? 'Reassign Ideas' : 'Assign Ideas'}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="modalBody">
                <Form>
                  <Container className="m-0">
                    {assignedName && (
                      <Row className="mb-3 mr-3 rowFlex">
                        <Form.Group
                          className="mt-2"
                          as={Col}
                          md="10"
                          controlId="validationFormik01"
                        >
                          <Form.Label>Currently Assigned To</Form.Label>
                          <span className="idea_category_badge assigned-to">
                            {assignedName}
                          </span>
                        </Form.Group>
                      </Row>
                    )}

                    <Row className="mb-3 mr-3 rowFlex">
                      <Form.Group
                        className="mt-2"
                        as={Col}
                        md="10"
                        controlId="validationFormik01"
                      >
                        <Form.Label>
                          {assignedName ? 'Reassign To' : 'Assign To'}{' '}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <AsyncPaginate
                          loadOptions={loadOptions}
                          getOptionValue={(option) => option.user_email}
                          getOptionLabel={(option) => option.user_name}
                          onChange={setAssigneeEmail}
                          additional={{
                            page: 0,
                          }}
                        />
                      </Form.Group>
                    </Row>
                  </Container>
                </Form>
                <Modal.Footer style={{ gap: '10px' }}>
                  <Button
                    variant="primary"
                    className="addModalBtn"
                    type="submit"
                    onClick={handleAssignIdea}
                    style={{ color: '#1976d2', border: '1px solid #0d6efd' }}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="danger"
                    type="submit"
                    className="close-idea-button"
                    onClick={handleCloseAssignIdea}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal.Body>
            </Modal>
            <Modal show={showIdeaCompletion} onHide={handleCloseIdeaCompletion}>
              <Modal.Header closeButton>
                <Modal.Title>Mark Idea Completion</Modal.Title>
              </Modal.Header>
              <Modal.Body className="modalBody">
                <Form>
                  <Container className="m-0">
                    <Row className="mb-3 mr-3 rowFlex">
                      <Form.Group
                        className="mt-2"
                        as={Col}
                        md="10"
                        controlId="validationFormik01"
                      >
                        <Form.Label>
                          Comments <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={completionComment}
                          onChange={(e) => setCompletionComment(e.target.value)}
                        />
                      </Form.Group>
                    </Row>
                  </Container>
                </Form>
                <Modal.Footer style={{ gap: '10px' }}>
                  <Button
                    variant="primary"
                    className="addModalBtn"
                    type="submit"
                    onClick={handleMarkAsCompleteIdea}
                    style={{ color: '#1976d2', border: '1px solid #0d6efd' }}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="danger"
                    type="submit"
                    className="close-idea-button"
                    onClick={handleCloseIdeaCompletion}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal.Body>
            </Modal>
            <Modal show={showEditIdeas} onHide={handleCloseEditIdeas}>
              <Modal.Header closeButton>
                <Modal.Title>Edit Ideas</Modal.Title>
              </Modal.Header>
              <Modal.Body className="modalBody">
                <Form>
                  <Container className="m-0">
                    <Row className="mb-3 mr-3 rowFlex">
                      <Form.Group className="flexItem col-4" as={Col} md="4">
                        <Form.Label>
                          Title<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Idea Title"
                          autoFocus
                          onChange={(e) => {
                            setIdeaTitle(e.target.value)
                          }}
                          value={ideaTitle}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3 mr-3 rowFlex">
                      <Form.Group className="flexItem col-1" as={Col} md="4">
                        <Form.Label>
                          Category<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => {
                            setCategory(e.target.value)
                          }}
                          value={category}
                        >
                          <option key="placeholder" value="">
                            Select Category
                          </option>
                          {Object.entries(categoryArr).map(([key, val]) => {
                            return (
                              <option key={key} value={key}>
                                {val}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3 mr-3 rowFlex">
                      <Form.Group className="flexItem col-1" as={Col} md="4">
                        <Form.Label>
                          Chapter<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => {
                            setChapter(e.target.value)
                          }}
                          value={chapter}
                        >
                          <option key="placeholder" value="">
                            Select Chapter
                          </option>
                          {JSON.parse(localStorage.getItem('channel_metadata'))
                            ?.channels?.sort()
                            ?.map?.((value, index) => {
                              return (
                                <option key={index} value={value}>
                                  {value}
                                </option>
                              )
                            })}
                        </Form.Select>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3 mr-3 rowFlex">
                      <Form.Group className="flexItem col-1" as={Col} md="4">
                        <Form.Label>
                          Complexity<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => {
                            setComplexity(e.target.value)
                          }}
                          value={complexity}
                        >
                          <option key="placeholder" value="">
                            Select Complexity
                          </option>
                          {Object.entries(complexityArr).map(([key, val]) => {
                            return (
                              <option key={key} value={val}>
                                {val}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3 mr-3 rowFlex">
                      <Form.Group className="flexItem" as={Col} md="4">
                        <Form.Label>
                          Description<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          onChange={(e) => {
                            setDescripiton(e.target.value)
                          }}
                          value={description}
                        />
                      </Form.Group>
                    </Row>
                  </Container>
                </Form>
                <Modal.Footer style={{ gap: '10px' }}>
                  <Button
                    variant="primary"
                    className="addModalBtn"
                    type="submit"
                    onClick={handleEditIdea}
                    style={{ color: '#1976d2', border: '1px solid #0d6efd' }}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="danger"
                    type="submit"
                    className="close-idea-button"
                    onClick={handleCloseEditIdeas}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal.Body>
            </Modal>
          </div>
        )
      }
    />
  )
}

export default ViewIdeaDetail
