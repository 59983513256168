import React, {useState, useContext, useEffect, useRef} from "react";
import Sidebar from '../AppBar/Appbar'
import { AuthContext } from '../../context/authProvider'
import { getPodById, getPodDetails, getPodConsumedCredits, getPodAssets,  getPodSummary, getPodAssetInfo, getPodAssetName } from '../../../redux/actions/podsPageActions/podsAction'
import DBTGradingModelDashboard from "../DBTGrading/dbtGradingModels";
import { ForumHandler } from '../../Utils'
import { getBadgeusers, getUserKudos } from '../../../redux/actions/badgeAction'
import MembersTable from './memberDetailsTable'
import { useDispatch, useSelector } from 'react-redux'
import LoadingStatus from '../../Helper/LoadingStatus'
import loader_gif from '../../../media/loader.gif'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import { styled } from '@mui/material/styles'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Badge from 'react-bootstrap/Badge'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import UserProfile from '../Users/userProfileModal'
import AssessmentIcon from '@mui/icons-material/Assessment'
import PeopleIcon from '@mui/icons-material/People'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import TabView from '../Utilities/tabView'
import StackedBarChart from '../Utilities/stackedBarChart'
import { useNavigate } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import PaginationFeature from '../Utilities/paginationFeature'


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  }))
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }))
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }))

const PodDetailPage = () => {
    let history = useNavigate()
    let search = window.location.search
    const params = new URLSearchParams(search)
    const id = params.get('q')
    const [channel, setChannel] = useState(localStorage.getItem('channel'))
    const initLoad = useRef(false)
    const [podAssets, setPodAssets] = useState([])
    const [podDetail, setPodDetail] = useState({});
    const [podInitLoad, setPodInitLoad] = useState(false);
    const [badgeData, setbadgeData] = useState()
    const [showModal, setShowModal] = useState(false)
    const [showDBTGradingModal, setShowDBTGradingModal] = useState(false)
    const [dbtAsset, setDbtAsset] = useState('')
    const [isLocalUser, setIsLocalUser] = useState(false)
    const [builders, setBuilders] = useState([])
    const [stakeholders, setStakeholders] = useState([])
    const [productOwners, setProductOwners] = useState([])
    const [showAssetInfo, setShowAssetInfo] = useState(false);
    const [deMembersCount, setDeMembersCount] = useState(0);
    const [consumedCredits, setConsumedCredits] = useState([]);
    const [podSummary, setPodSummary] = useState({})
    const [podAssetInfo, setPodAssetInfo] = useState({})
    const [selectedAssetInfo, setSelectedAssetInfo] = useState('')
    const [creditsAsset, setCreditAsset] = useState({})
    const [assetNameList, setAssetNameList] = useState([])
    const [selectedAssetType, setSelectedAssetType] = useState('DAGSTER_DBT_JOB');
    const [selectedAssetName, setSelectedAssetName] = useState('all');
    const  viewProjectModal = (e, assetName) => {
      e.preventDefault()
      setDbtAsset(assetName);
      setShowDBTGradingModal(true);
    }
    const viewAssetInfo = ( e, assetName, assetType) => {
      e.preventDefault();
      setPodAssetInfo({})
      const params = {asset_name: assetName, asset_type: assetType};
      setSelectedAssetInfo(assetName)
      fetchPodAssetInfo(params);
    }

    const handleCloseDBTGradingModel = () => {
      setShowDBTGradingModal(false);
    }
    // Email pod btn states for getting pod details 
    const available_page_size = ['10','25','50', '100', '200', '500']
    const [assetsPage, setAssetsPage] = useState(0);
    const [assetLimit, setAssetLimit] = useState(25);
    const [totalAssetPages, setTotalAssetPages] = useState(0);
    const [totalAssetList, setTotalAssetList] = useState(0);
    const handleAssetPageChange = (selectedPage) => {
      setAssetsPage(selectedPage.selected)
    }
    const [encodedToEmails, setEncodedToEmails] = useState("")
    const [encodedCcEmails, setEncodedCcEmails] = useState("")
    const [encodedStakeHoldersEmail, setEncodedStakeHoldersEmail] = useState("")
    const [encodedSubject, setEncodedSubject] = useState("")
    const [authCreds] = useContext(AuthContext)
    const dispatch = useDispatch()
    const endpoint = authCreds.restEndpoint
    const [loadingStatus, setLoadingStatus] = useState({
      status: false,
      message: 'Fetching table records...',
    })
    const [loadingAssetGraphStatus, setLoadingAssetGraphStatus] = useState({
      status: false,
      message: 'Fetching DE Asset records...',
    })
    
    const [loadingAssetStatus, setLoadingAssetStatus] = useState(true)
    const [loadingSummaryStatus, setLoadingSummaryStatus] = useState(true);
    const [loadingAssetSummaryStatus, setLoadingAssetSummaryStatus] = useState({
      status: false,
      message: 'Fetching last run summary...',
    })
    const podById = useSelector((state) => state.podById)
    const podDetails = useSelector((state) => state.podDetails)
    const userKudos = useSelector((state) => state.userKudos)
    const columns = [
        'EMAIL',
        'CHAPTER',
        'TITLE',
        'GOLD',
        'SILVER',
        'BRONZE',
        'KUDOS',
        'COMPANY',
        'GROUP',
        'COUNTRY',
        'CITY',
        'TIME IN POSITION',
    ]
    function openUserprofile(row) {
      setbadgeData(row)
      if (
        localStorage.getItem('user').toUpperCase() == row?.user_name.toUpperCase()
      ) {
        setIsLocalUser(true)
      } else {
        setIsLocalUser(false)
      }
      const badgeParams = {
        page_size: 1,
        page_number: 1,
        filters: JSON.stringify([{"op":"eq","field":"user_email","value":row?.user_email}]),
        channel: null
      }
      dispatch(getBadgeusers(endpoint, badgeParams)).then(() => {
        setLoadingStatus({ status: false, message: 'Fetching data...' })
        setShowModal(true)
      })

      dispatch(getUserKudos(endpoint, row?.user_email)).then(() => {
        setLoadingStatus({ status: false, message: 'Fetching data...' })
        setShowModal(true)
      })
    }
    const fetchData =  () => {
      setLoadingStatus({ status: true, message: 'Fetching table records...' })
      dispatch(getPodById(endpoint, id))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
    }
    const fetchPodDetails = (podDetailParams) => {
      setLoadingStatus({ status: true, message: 'Fetching table records...' })
      dispatch(getPodDetails(endpoint, podDetailParams)).then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
    }
    const fetchPodConsumedCredits = async() => {
      setLoadingAssetGraphStatus({ status: true, message: 'Fetching DE Asset records...' })
      let filtersArr = [];
      const params = {pod_name:podDetail.pod_name}
      filtersArr.push(`{"op":"eq","field":"platform","value":"Data Engineering"}`)
      if(selectedAssetName && selectedAssetName != 'all') {
        filtersArr.push(`{"op":"eq","field":"asset_name","value":"${selectedAssetName}"}`)
      }
      if(selectedAssetType && selectedAssetType != 'all') {
        filtersArr.push(`{"op":"eq","field":"asset_type","value":"${selectedAssetType}"}`)
      }
      params.filters = '[' + filtersArr.join(',') + ']'
      await dispatch(getPodConsumedCredits(endpoint, params)).then(() => {
        setLoadingAssetGraphStatus({ status: false, message: 'Fetching DE Asset records...' })
      })
      .catch(() => {
        setLoadingAssetGraphStatus({ status: false, message: 'Fetching DE Asset records...' })
      })
    }
    const fetchPodSummary = async() => {
      setLoadingSummaryStatus(true);
      let filtersArr = [];
      const params = {pod_name:podDetail.pod_name}
      filtersArr.push(`{"op":"eq","field":"platform","value":"Data Engineering"}`)
      if(selectedAssetName && selectedAssetName != 'all') {
        filtersArr.push(`{"op":"eq","field":"asset_name","value":"${selectedAssetName}"}`)
        params.asset_name = selectedAssetName;
      }
      if(selectedAssetType && selectedAssetType != 'all') {
        filtersArr.push(`{"op":"eq","field":"asset_type","value":"${selectedAssetType}"}`)
      }
      
      params.filters = '[' + filtersArr.join(',') + ']'
      await dispatch(getPodSummary(endpoint, params)).then(() => {
        setLoadingSummaryStatus(false);
      })
      .catch(() => {
        setLoadingSummaryStatus(false);
      })
    }
    const fetchPodAssetInfo = (params) => { 
      
      setLoadingAssetSummaryStatus({ status: true, message: 'Fetching data...' })
      dispatch(getPodAssetInfo(endpoint, params)).then(()=>{
        setLoadingAssetSummaryStatus({ status: false, message: 'Fetching data...' })
        setShowAssetInfo(true);
      });
    }
    const fetchPodAssetName = () => { 
      const params = {pod_name: podDetail.pod_name}
      dispatch(getPodAssetName(endpoint, params))
    }
    
    const fetchPodAssets = async() => { 
      let filtersArr = [];
      setLoadingAssetStatus(true)
      const params = {pod_name:podDetail.pod_name}
      filtersArr.push(`{"op":"eq","field":"platform","value":"Data Engineering"}`)
      if(selectedAssetName && selectedAssetName != 'all') {
        filtersArr.push(`{"op":"eq","field":"asset_name","value":"${selectedAssetName}"}`)
        params.asset_name = selectedAssetName;
      }
      if(selectedAssetType && selectedAssetType != 'all') {
        filtersArr.push(`{"op":"eq","field":"asset_type","value":"${selectedAssetType}"}`)
      }
      params.filters = '[' + filtersArr.join(',') + ']'
      params.page_number = assetsPage + 1;
      params.page_size = assetLimit;
      await dispatch(getPodAssets(endpoint, params))
      .then(() => {
        setLoadingAssetStatus(false)
      })
      .catch(() => {
        setLoadingAssetStatus(false)
      }) 
    }
    useEffect(() => {
      if (podDetails?.podDetails?.data) {
        const toEmails = podDetails?.podDetails?.data?.members?.builders.map(builder => encodeURIComponent(builder?.user_email)).join(';');
        const ccEmails = podDetails?.podDetails?.data?.members?.product_owners.map(owner => encodeURIComponent(owner?.user_email)).join(';');
        const stakeHoldersEmails = podDetails?.podDetails?.data?.members?.stakeholders.map(stakeholder => encodeURIComponent(stakeholder?.user_email)).join(';');
        const subject = encodeURIComponent(`Kholab: POD [${podDetail?.pod_name}] message`);
        setEncodedToEmails(toEmails)
        setEncodedCcEmails(ccEmails)
        setEncodedSubject(subject)
        setEncodedStakeHoldersEmail(stakeHoldersEmails);

        if(podDetails?.podDetails?.data?.members?.builders?.length) {
          const deData = podDetails?.podDetails?.data?.members?.builders.filter((item)=>{ return item.role_type =='Data Engineering'})
          if(deData.length) {
            setDeMembersCount(deData.length)
          } else {
            setDeMembersCount(0)
          }
        } else {
          setDeMembersCount(0)
        }
      }
      if(podDetails?.podAssets?.data && podDetails.podAssets.data.pod_assets){
        setTotalAssetPages(podDetails?.podAssets?.num_pages);
        setTotalAssetList(podDetails?.podAssets?.total_results)
        setPodAssets(podDetails.podAssets.data.pod_assets);
      }
      if(podDetails?.podConsumedCredits?.pod_asset_credits){
        setConsumedCredits(podDetails?.podConsumedCredits?.pod_asset_credits)
      }
      if(podDetails?.podSummary && podDetails?.podSummary?.pod_name) {
        setPodSummary(podDetails?.podSummary)  
      }else {
        setPodSummary({})
      }
      if(podDetails.assetNames){
        setAssetNameList(podDetails.assetNames);
      }
      
      if(podDetails?.podAssetInfo){
        setPodAssetInfo(podDetails?.podAssetInfo);
      }else{
        setPodAssetInfo({})
      }
    }, [podDetails])
    useEffect(()=>{
      if (!initLoad.current) {
        initLoad.current = true
        fetchData()
      }

    },[])

    useEffect(()=>{
      if(podDetail && podDetail.id){
        if (podInitLoad === true) {
          return
        }
        const podDetailParams = {
          pod_id: podDetail.id,
          builders: builders,
          stakeholders: stakeholders,
          product_owners: productOwners,
        }
        fetchPodDetails(podDetailParams);
        fetchPodConsumedCredits()
        fetchPodAssets()
        fetchPodAssetName()
        fetchPodSummary()
        setPodInitLoad(true)
      }
    },[builders, stakeholders, productOwners])

    useEffect(()=>{
      if(selectedAssetName && selectedAssetType && podDetail.pod_name) {
        fetchPodConsumedCredits()
        fetchPodAssets()
        fetchPodSummary()
      }
    }, [ assetsPage, assetLimit, selectedAssetName, selectedAssetType])

    useEffect(()=>{
      let combineCreditRes = consumedCredits?.reduce(((r, c) => Object.assign(r, c)), {});
      delete combineCreditRes.month_year;
      setCreditAsset(Object.keys(combineCreditRes))
    },[consumedCredits])
    
    useEffect(()=>{
      let podData = podById?.podById?.data?.pod_data;
      if (podData && podData.id) {
          setPodDetail(podData);
          const stakeholderMatches = podData?.stakeholders_html?.match(
              ForumHandler.userHTMLCharacters
          )
          const productOwnerMatches = podData?.product_owners_html?.match(
              ForumHandler.userHTMLCharacters
          )
          const builderMatches = podData?.builders_html?.match(
              ForumHandler.userHTMLCharacters
          )
          
          let stakeholderValues = stakeholderMatches
              ? stakeholderMatches.map((mention) => mention.slice(0))
              : []

          let uniqueStakeholder = new Set(stakeholderValues)
          setStakeholders([...uniqueStakeholder])
          
          let productOwnerValues = productOwnerMatches
              ? productOwnerMatches.map((mention) => mention.slice(0))
              : []

          let uniqueProductOwner = new Set(productOwnerValues)
          setProductOwners([...uniqueProductOwner])

          let builderValues = builderMatches
              ? builderMatches.map((mention) => mention.slice(0))
              : []
          
          let uniqueBuilder = new Set(builderValues)
          setBuilders([...uniqueBuilder])
      }
      
    }, [podById])

    const TeamTabContent = () => {
      return (
        <div>
          <Accordion defaultExpanded>
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <Typography>Builders</Typography>
                <Typography>
                    <Badge bg="secondary" className="pod-badge">
                    {podDetails?.podDetails?.data?.members?.builders?.length}
                    </Badge>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <MembersTable
                    columns={columns}
                    members={podDetails?.podDetails?.data?.members?.builders}
                    openUserprofile={openUserprofile}
                />
              </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
              <Typography>Product Owners </Typography>
              <Typography>
                  <Badge bg="secondary" className="pod-badge">
                  {
                      podDetails?.podDetails?.data?.members?.product_owners
                      ?.length
                  }
                  </Badge>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MembersTable
                  columns={columns}
                  members={
                  podDetails?.podDetails?.data?.members?.product_owners
                  }
                  openUserprofile={openUserprofile}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
              <Typography>Stakeholders</Typography>
              <Typography>
                  <Badge bg="secondary" className="pod-badge">
                  {
                      podDetails?.podDetails?.data?.members?.stakeholders
                      ?.length
                  }
                  </Badge>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MembersTable
                  columns={columns}
                  members={
                  podDetails?.podDetails?.data?.members?.stakeholders
                  }
                  openUserprofile={openUserprofile}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      )
    };

    const AssetTabContent = () => {
      const columns =  ['Asset Type', 'Asset Name','Snowflake Credits', 'Grade', 'Last Job Triggered Time', 'Status'];
      
      return loadingAssetGraphStatus.status ? (
          <LoadingStatus status_message={loadingAssetGraphStatus.message} />
      ):
        ( 
        <>
          <div style={{display: 'flex', flexDirection: 'column' }}>
            <div style={{display: 'flex', gap: '1em'}}>
              <Form.Select
                onChange={(e) => {
                  setSelectedAssetType(e.target.value)
                }}
                value={selectedAssetType}
                style={{width: '15em'}}
              >
                <option key="placeholder" value="all">
                  Select Asset Type
                </option>
                <option value="DAGSTER_DBT_JOB">DAGSTER_DBT_JOB</option>
              </Form.Select>
              <Form.Select
                onChange={(e) => {
                  setSelectedAssetName(e.target.value)
                }}
                value={selectedAssetName}
                style={{width: '15em'}}
              >
                <option key="placeholder" value="all">
                  Select Asset Name
                </option>
              {assetNameList?.map((item) =>{
                return (
                  <option value={item.asset_name} key={item.asset_name}>{item.asset_name}</option> 
                 )
              })}
              </Form.Select>
            </div>
            <div className="summary-tile-div">
              <div className="summary-tile-container"  style={{paddingLeft: 0, cursor: 'default'}}>
                <div className="summary-tile">
                  {
                    loadingSummaryStatus ? <span><img width="16px" height="16px" src={loader_gif} /> Loading...</span>
                  :(
                    <span className="summary-tile-count">
                      {podSummary.total_credits ? (podSummary.total_credits).toFixed(2) : 'NA'}
                    </span>
                    )  
                  } 
                    <span className="summary-tile-desc">
                        <span className="summary-tile-link">Credits</span>
                    </span>
                    {podSummary.percentage_variance && (
                      <span class={`idea_category_badge  badge_container_desc_role_type badge-role-type-tag ${podSummary.percentage_variance < 0 ? 'badge-danger' : 'badge-success'}`}>
                        {podSummary.percentage_variance < 0 ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
                        {(podSummary.percentage_variance).toFixed(2)}%</span>
                    )}
                </div>
              </div>
              <div className="summary-tile-container" style={{cursor: 'default'}} >
                  <div className="summary-tile">
                  {
                    loadingSummaryStatus ? <span><img width="16px" height="16px" src={loader_gif} /> Loading...</span>
                  :(
                      <span className="summary-tile-count">
                        {podSummary.total_assets ? podSummary.total_assets: (selectedAssetName && selectedAssetName != 'all') ? 1 : 0}
                      </span>
                  )}
                      <span className="summary-tile-desc">
                        <span className="summary-tile-link">Assets</span>
                      </span>
                  </div>
              </div>
              <div className="summary-tile-container" style={{cursor: 'default'}}>
                <div className="summary-tile">
                {
                    loadingSummaryStatus ? <span><img width="16px" height="16px" src={loader_gif} /> Loading...</span>
                  :(
                    <span className="summary-tile-count">
                    {(podSummary.total_credits && podSummary.total_assets) ? (podSummary?.total_credits/podSummary?.total_assets).toFixed(2) : 'NA'}
                    </span>
                  )}
                    <span className="summary-tile-desc">
                        <span className="summary-tile-link">Credit/Asset</span>
                    </span>
                </div>
              </div>                        
              <div className="summary-tile-container" style={{paddingRight: 0, cursor: 'default'}}>
                <div className="summary-tile">
                {
                    loadingSummaryStatus ? <span><img width="16px" height="16px" src={loader_gif} /> Loading...</span>
                  :(
                  <span className="summary-tile-count">
                    {(podSummary.total_credits && deMembersCount) ? (podSummary?.total_credits/deMembersCount).toFixed(2): 'NA'}
                  </span>
                  )}
                  <span className="summary-tile-desc">
                    <span className="summary-tile-link">Credit/DE</span>
                  </span>
                </div>
              </div>   
            </div>
            {consumedCredits && consumedCredits.length > 0 && creditsAsset && (<StackedBarChart chartTitle="Snowflake Credits" xAxisKey="month_year" xAxisLabel= "Executed Month" yAxisLabel="Credits" data={consumedCredits} barChartKey={creditsAsset} />)}  
          </div>
          <TableContainer component={Paper} style={{marginTop: '1em'}}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={Math.random()} className="cell" align="center" style={{fontWeight: '600'}}>
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
              {loadingAssetStatus ? 
                <TableRow>
                  <TableCell className="cell" align="center" colSpan={6}>
                  <img width="16px" height="16px" src={loader_gif} /> Loading Assets...
                  </TableCell>
                </TableRow>
              :
              podAssets.length > 0 ?
                podAssets?.map((row) => (
                  <TableRow key={row?.id}>
                    <TableCell className="cell" align="center">
                      {row?.asset_type}
                    </TableCell>
                    <TableCell className="cell" align="center">
                    <Tooltip title="Click to view Asset - Last Executed Status, Time, Job Details.">
                    <a href="#" className="dbt_links" onClick={(e) => viewAssetInfo( e, row.asset_name, row.asset_type)}>
                      {row.asset_name}
                      </a>
                      </Tooltip>
                    </TableCell>
                    <TableCell className="cell" align="center">
                      {row.snowflake_credits ? (row.snowflake_credits).toFixed(2) : 0 }
                    </TableCell>
                    <TableCell className="cell" align="center">
                      <Tooltip title="Click to view DBT Grading Model Details and Scores.">
                      <a href="#" className="dbt_links" onClick={(e) => viewProjectModal( e, row.asset_name)}>
                        {row.job_grade ? row.job_grade : '-'}
                      </a>
                      </Tooltip>
                    </TableCell>
                    <TableCell className="cell" align="center">
                      {row.last_job_triggered_time ? row?.last_job_triggered_time: '-'}
                    </TableCell>
                    <TableCell className="cell" align="center">
                      {row.run_status ? row.run_status=='SUCCEEDED' ? <span className="idea_category_badge badge-success-outline">{row.run_status}</span> : <span className="idea_category_badge badge-error-outline">{row.run_status}</span>: '-'}
                    </TableCell>
                  </TableRow>
                )) 
              :
                (<TableRow>
                  <TableCell className="cell" align="center" colSpan={6}>
                    No Assets available.
                  </TableCell>
                </TableRow> 
                )
              }
              </TableBody>
            </Table>
          </TableContainer>
          <PaginationFeature 
                totalPages={totalAssetPages}
                handlePageChange={handleAssetPageChange}
                currentPage={assetsPage}
                totalResults={totalAssetList}
                pageSize={assetLimit}
                setPageSize={setAssetLimit}
                availablePageSize={available_page_size}
            />
          <DBTGradingModelDashboard
            dbtAsset={dbtAsset}
            showModal={showDBTGradingModal}
            handleCloseModal={handleCloseDBTGradingModel}
          />
          <Modal size="lg" show={showAssetInfo} onHide={()=>setShowAssetInfo(false)}>
            <Modal.Header closeButton>
            {/*  */}
              <Modal.Title>{selectedAssetInfo} Last Run Summary</Modal.Title>
            </Modal.Header>
            <Modal.Body className="asset_info_modal">
            {loadingAssetSummaryStatus.status ? (
                <LoadingStatus status_message={loadingAssetSummaryStatus.message} />
            ) : 
              Object.keys(podAssetInfo).length === 0 ? (
                <span style={{color: 'red', display: 'flex', justifyContent: 'center', margin: '1em 0'}}>This job has not triggered yet.</span>
              ) : (
                <table className="asset_info_table">
                <tbody>
                  <tr>
                    <td className="asset_info_label">Description</td>
                    <td>{podAssetInfo?.Description}</td>
                  </tr>
                  <tr>
                    <td className="asset_info_label">Last job triggered time</td>
                    <td>{podAssetInfo[`Last job triggered time`]}</td>
                  </tr>
                  <tr>
                    <td className="asset_info_label">Run Status</td>
                    <td>{podAssetInfo[`Run status`] &&  (podAssetInfo[`Run status`] =='SUCCEEDED' ? <span className="idea_category_badge badge-success-outline">{podAssetInfo[`Run status`]}</span> : <span className="idea_category_badge badge-error-outline">{podAssetInfo[`Run status`]}</span>)}</td>
                  </tr>
                  <tr>
                    <td className="asset_info_label">Parent Jobs</td>
                    <td>
                      <div>
                    {podAssetInfo[`Parent Jobs`] &&  JSON.parse(podAssetInfo[`Parent Jobs`]).length && JSON.parse(podAssetInfo[`Parent Jobs`]).map((value) =>{
                      return <span className="idea_category_badge badge-primary badge_container_desc_role_type badge-role-type-tag" key={value}>{value}</span>;
                    })}
                    </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              )
            }
              
              <Modal.Footer style={{ gap: '10px' }}>
                
                <Button variant="danger" type="button" className="addModalBtn" onClick={()=>setShowAssetInfo(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal.Body>
          </Modal>
        </>
      )
    }

    const podDetailPageContent = {
      defaultTab: 'Team',
      dataSet: [
          {
              key: 'Team',
              title: <><PeopleIcon /> Team</>,
              content: <TeamTabContent />
          },
          {
            key: 'Asset',
            title: <><AssessmentIcon /> DE Asset</>,
            content: <AssetTabContent />
          }
      ]
    };

    
    return(
        <>
            {loadingStatus.status ? (
                <LoadingStatus status_message={loadingStatus.message} />
            ) : (
            <>
                <Sidebar
                    Page="pod_detail_page"
                    channel={channel}
                    setChannel={setChannel}
                    Index={
                        <>
                        <Container className="m-0" style={{ backgroundColor: '#fff', marginLeft: '1em !important', paddingTop: '1em'}}>
                          <div style={{display:'flex', height: '2em', marginBottom: '0.5em', gap: '0.5em'}}>
                            <Tooltip title="Name">
                              <span style={{color: '#071437', fontWeight: 500, fontSize: '1.25rem'}}>{podDetail?.pod_name}</span>
                            </Tooltip>
                            <Tooltip title="Funtional Area">
                              <Badge bg="primary" className="pod-badge" style={{width: 'fit-content', fontSize: '0.85rem', borderRadius: '99999px'}}>
                                  {podDetail?.functional_area}
                              </Badge>
                            </Tooltip>
                            <Tooltip title="Click to go Back to PODs">
                              <Button type="button" onClick={()=>history('/pods')} style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#00000099', color: '#fff',borderColor: '#00000099', lineHeight: 1, marginLeft: '5em'}}>
                                Go Back
                              </Button>
                            </Tooltip>
                            {podDetails.podDetails && podDetails.podDetails.data && podDetails.podDetails.data.members.builders.length > 0 && (
                            <a
                              href={
                                podDetails?.podDetails?.data?.members
                                  ?.product_owners?.length
                                  ? `mailto:${encodedToEmails}?cc=${encodedCcEmails}&subject=${encodedSubject}`
                                  : `mailto:${encodedToEmails}?subject=${encodedSubject}`
                              }
                              className="btn email-pod-btn"
                              style={{ margin: '0', lineHeight: 1 }}
                            >  
                              <Tooltip title={
                                podDetails?.podDetails?.data?.members
                                  ?.product_owners?.length
                                  ? `Send email to POD members - builders and product owners`
                                  : `Send email to POD members - builders`
                              }>
                                {
                                podDetails?.podDetails?.data?.members
                                  ?.product_owners?.length
                                  ? `Email PO + Builders`
                                  : `Email Builders`
                                }
                              
                              </Tooltip>
                            </a>)}
                            {podDetails.podDetails && podDetails.podDetails.data && podDetails.podDetails.data.members.stakeholders.length > 0 && (
                              <a
                              href={
                                podDetails?.podDetails?.data?.members
                                  ?.product_owners?.length
                                  ? `mailto:${encodedStakeHoldersEmail}?cc=${encodedCcEmails}&subject=${encodedSubject}`
                                  : `mailto:${encodedStakeHoldersEmail}?subject=${encodedSubject}`
                              }
                              className="btn email-pod-btn"
                              style={{ margin: '0', lineHeight: 1 }}
                            >  
                              <Tooltip title={
                                podDetails?.podDetails?.data?.members
                                  ?.product_owners?.length
                                  ? `Send email to POD members - Stakeholders and product owners`
                                  : `Send email to POD members - Stakeholders`
                              }>
                              
                              {
                                podDetails?.podDetails?.data?.members
                                  ?.product_owners?.length
                                  ? `Email PO + Stakeholders`
                                  : `Email Stakeholders`
                              }
                              </Tooltip>
                            </a>
                            )}
                            
                          </div>
                          <Tooltip title="Description">
                            <Typography>{podDetail?.description}</Typography>
                          </Tooltip>
                          <TabView dataArr={podDetailPageContent} />
                        </Container>
                        {showModal ? (
                            <>
                            <UserProfile
                                selectedUser={badgeData?.user_name}
                                badgeData={badgeData}
                                setBadgeData={setbadgeData}
                                showModal={showModal}
                                setShowModal={setShowModal}
                                isLocalUser={isLocalUser}
                                history={history}
                                pageSize={1}
                                pageIndex={1}
                                userKudos={userKudos}
                            />
                            </>
                        ) : (
                            ''
                        )}
                        </>
                    }
                />
            </>
          ) }
        </>
        
    
    )
}

export default PodDetailPage;
