import {
  ADD_COLLAB_POST,
  ADD_COLLAB_POST_LOADING,
  ADD_COLLAB_ERROR,
  GET_COLLAB_LOADING,
  GET_COLLAB_POST,
  GET_COLLAB_ERROR,
  ADD_ANSWER_ERROR,
  ADD_ANSWER_LOAD,
  ADD_ANSWER_POST,
  ADD_COMMENT_ERROR,
  ADD_COMMENT_LOAD,
  ADD_COMMENT_POST,
  ADD_VOTE_ERROR,
  ADD_VOTE_POST,
  ADD_VOTE_LOAD,
  COLLAB_RESET,
  GET_MOST_UPVOTED_POSTS,
  GET_MOST_UPVOTED_POSTS_ERROR,
  GET_MOST_UPVOTED_POSTS_LOADING,
  GET_COLLAB_SEARCH_RESULTS_LOADING,
  GET_COLLAB_SEARCH_RESULTS,
  GET_COLLAB_SEARCH_RESULTS_ERROR,
  GET_RECENT_POSTS,
  GET_RECENT_POSTS_LOADING,
  GET_RECENT_POSTS_ERROR,
  GET_KHOLAB_SUMMARY,
  GET_KHOLAB_SUMMARY_LOADING,
  GET_KHOLAB_SUMMARY_ERROR
} from '../constants/types'

const initialState = {
  collabData: [],
  postData: [],
  searchData: [],
  mostUpvotedPosts: [],
  recentPosts: [],
  kholabSummary: {},
  loading: true,
  error: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_COLLAB_POST_LOADING:
      return {
        ...state,
        loading: true,
      }
    case ADD_COLLAB_POST:
      return {
        ...state,
        loading: false,
        postData: action.payload,
      }
    case ADD_COLLAB_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_COLLAB_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_COLLAB_POST:
      return {
        ...state,
        loading: false,
        collabData: action.payload,
      }
    case GET_COLLAB_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case ADD_ANSWER_LOAD:
      return {
        ...state,
        loading: true,
      }
    case ADD_ANSWER_POST:
      return {
        ...state,
        loading: false,
      }
    case ADD_ANSWER_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case ADD_COMMENT_LOAD:
      return {
        ...state,
        loading: true,
      }
    case ADD_COMMENT_POST:
      return {
        ...state,
        loading: false,
      }
    case ADD_COMMENT_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case ADD_VOTE_LOAD:
      return {
        ...state,
        loading: true,
      }
    case ADD_VOTE_POST:
      return {
        ...state,
        loading: false,
      }
    case ADD_VOTE_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_MOST_UPVOTED_POSTS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_MOST_UPVOTED_POSTS:
      return {
        ...state,
        loading: false,
        mostUpvotedPosts: action.payload,
      }
    case GET_MOST_UPVOTED_POSTS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_RECENT_POSTS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_RECENT_POSTS:
      return {
        ...state,
        loading: false,
        recentPosts: action.payload,
      }
    case GET_RECENT_POSTS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_KHOLAB_SUMMARY_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_KHOLAB_SUMMARY:
      return {
        ...state,
        loading: false,
        kholabSummary: action.payload,
      }
    case GET_KHOLAB_SUMMARY_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_COLLAB_SEARCH_RESULTS:
      return {
        ...state,
        loading: false,
        searchData: action.payload,
      }
    case GET_COLLAB_SEARCH_RESULTS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_COLLAB_SEARCH_RESULTS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case COLLAB_RESET:
      return {
        postData: [],
        loading: false,
        error: '',
      }
    default:
      return state
  }
}
