import React, { useEffect, useState } from 'react'
import { Navbar, Nav, NavDropdown, Container, Col } from 'react-bootstrap'
import TooltipHover from '@mui/material/Tooltip'

import { useSelector } from 'react-redux'
import kh_logo from '../media/kraftheinzlogo.png'
import Kholab from '../media/kholab-logo.png'
import Khnps from '../media/khnps-logo.png'
import { useMsal, useIsAuthenticated } from '@azure/msal-react'
import { MdMailOutline } from 'react-icons/md'
import { Link, useLocation } from 'react-router-dom'
import { loginRequest } from '../components/context/authConfig'
import { ToastContainer } from 'react-toastify'
import PgpEncryptionApp from '../components/pages/Utilities/PgpEncryptionApp'
import AnzRecommendation from '../components/pages/Utilities/AnzRecommendation'
import BlobUpload from '../components/pages/Utilities/DeFileUpload'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import '../App.css'
import { useNavigate } from 'react-router-dom'
import UserProfile from './pages/Users/userProfileModal'

const Menu = () => {
  /* eslint-disable no-unused-vars */
  let history = useNavigate()
  let location = useLocation()

  const [username, setUsername] = React.useState(null)
  const [showUserProfile, setShowUserProfile] = React.useState(false)
  const [badgeData, setBadgeData] = React.useState()
  const userKudos = useSelector((state) => state.userKudos)

  //Kraft heinz & Kholab logo
  const [khLogo, setKhLogo] = useState({
    img: kh_logo,
    alt: 'Kraft heinz logo',
    logoClass: 'menu_img',
  })

  //navbar hide all links except Kholab & KH-NPS
  const [hideLink, setHideLink] = useState({
    otherLinks: true,
    khoLabLink: true,
    npsLink: true,
  })

  //pgp encryption modal handlers and state
  const [show, setShow] = useState(false)

  //form validation and error handling
  const [form, setForm] = useState({})
  const [errors, setErrors] = useState({})

  //ANZ Recommendation app modal handlers and state
  const [showAnz, setShowAnz] = useState(false)

  const [showJira, setShowJira] = useState(false)

  //ANZ form and error states
  const [anzForm, setAnzForm] = useState({})
  const [anzErrors, setAnzErrors] = useState({})

  //handle anz modal
  const handleCloseAnz = () => {
    setShowAnz(false)
    setAnzErrors({})
    setAnzForm(new Object())
    // setAnzOption('')
  }
  const handleShowAnz = () => setShowAnz(true)

  //handle pgp modal
  const handleClose = () => {
    setShow(false)
    setErrors({})
    setForm(new Object())
  }

  //handle blob upload state
  const [showBlobUpload, setShowBlobUpload] = useState(false)

  //Blob upload forms and errors
  const [blobUploadForm, setBlobUploadForm] = useState({})
  const [blobUploadErrors, setBlobUploadErrors] = useState({})

  //handle blob upload modal
  const handleCloseBlobUpload = () => {
    setShowBlobUpload(false)
    setBlobUploadErrors({})
    setBlobUploadForm(new Object())
  }
  const handleShowBlobUpload = () => setShowBlobUpload(true)

  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e)
    })
  }
  function handleLogout(instance) {
    instance.logoutRedirect().catch((e) => {
      console.error(e)
    })
  }
  function handleOpenProfile() {
    setShowUserProfile(true)
  }
  // function handleDbtReport() {
  //   history('/dbt-report')
  // }
  const handleShow = () => setShow(true)

  const isAuthenticated = useIsAuthenticated()
  const { accounts, instance } = useMsal()

  const env = process.env.REACT_APP_ENV

  const hideSurveyLinks = [
    '/view-surveys',
    '/survey-admin',
    '/create-survey',
    '/template',
    '/create-template',
    '/edit-template',
    '/create-view-groups',
    '/admin-survey-view',
    '/nps',
  ]

  const hideKholabLinks = [
    '/forum',
    '/glossary',
    '/dbt-report',
    '/add-question',
    '/coming-soon',
    '/badge',
    '/pods',
    '/pod/detail',
    '/users',
    '/ideas',
    '/nps-survey',
    '/ideas/manage-ideas',
    '/jobs',
    '/announcements',
    '/kholab_home',
  ]

  //routes info
  const isSurveyPage = location.pathname.startsWith('/survey/')

  const isKholabPage =
    location.pathname.startsWith('/forum/') ||
    location.pathname.startsWith('/badge/') ||
    location.pathname.startsWith('/pods/') ||
    location.pathname.startsWith('/pod/detail') ||
    location.pathname.startsWith('/glossary/') ||
    location.pathname.startsWith('/nps-survey/') ||
    location.pathname.startsWith('/ingestion-intake/')

  const isSurvey = hideSurveyLinks.includes(location.pathname) || isSurveyPage
  const isKhoLab = hideKholabLinks.includes(location.pathname) || isKholabPage
  useEffect(() => {
    if (isSurvey || isKhoLab) {
      setHideLink({
        otherLinks: false,
        khoLabLink: false,
        npsLink: false,
      })
      {
        isKhoLab
          ? setKhLogo({
              img: Kholab,
              alt: 'kholab logo',
              logoClass: 'menu_img',
            })
          : setKhLogo({
              img: Khnps,
              alt: 'Kraft heinz NPS logo',
              logoClass: 'menu_img_nps',
            })
      }
    } else {
      setHideLink({
        otherLinks: true,
        khoLabLink: true,
        npsLink: true,
      })
      setKhLogo({
        img: kh_logo,
        alt: 'Kraft heinz logo',
        logoClass: 'menu_img',
      })
    }
  }, [location])

  useEffect(() => {
    if (isAuthenticated) {
      let local_username = accounts[0].username.split('@')[0]
      setUsername(local_username)
    }
  }, [isAuthenticated])
  // const gotoTower = () => {
  //   history('/control-tower')
  // }

  return (
    <>
      <ToastContainer containerId="qwerty" />
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <Col lg="2">
            {' '}
            <Link
              to={isSurvey ? '/view-surveys' : isKhoLab ? '/forum' : '/home'}
            >
              <img
                className={khLogo.logoClass}
                src={khLogo.img}
                alt={khLogo.alt}
              />{' '}
            </Link>
          </Col>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            {/* ToDo: replace hrefs with Link to */}
            {accounts.length > 0 ? (
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: '100px' }}
                navbarScroll
              >
                {hideLink.otherLinks && (
                  <>
                    <NavDropdown title="Products" id="StewardScrollingDropdown">
                      <NavDropdown.Item href="/ref_data">
                        Reference Data
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/azure_ocr">
                        Azure OCR
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/steps">FnF</NavDropdown.Item>
                      {hideLink.khoLabLink && (
                        <>
                          <NavDropdown.Item href="/kholab_home">
                            Kholab
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/view-surveys">
                            KH-NPS
                          </NavDropdown.Item>
                        </>
                      )}
                    </NavDropdown>
                    <NavDropdown title="API" id="APIScrollingDropdown">
                      <NavDropdown.Item
                        // href={`https://deapi${env}.apps.kraftheinz.com/graphiql`}
                        target="_blank"
                        style={{ color: 'grey' }}
                      >
                        GraphQL
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        href={`https://deapi${env}.apps.kraftheinz.com/api/docs`}
                        target="_blank"
                      >
                        REST Documentation
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="DataOps" id="APIScrollingDropdown">
                      <NavDropdown.Item href="/control-tower" target="_self">
                        Control Tower
                      </NavDropdown.Item>
                      {env === '-uat' ? (
                        <NavDropdown.Item
                          href={`https://kraftheinz.dagster.cloud/${env}/runs`}
                          target="_blank"
                        >
                          Dagster
                        </NavDropdown.Item>
                      ) : env === '' ? (
                        <NavDropdown.Item
                          href={`https://kraftheinz.dagster.cloud/prod/runs`}
                          target="_blank"
                        >
                          Dagster
                        </NavDropdown.Item>
                      ) : (
                        <NavDropdown.Item
                          href={`https://kraftheinz.dagster.cloud/dev/runs`}
                          target="_blank"
                        >
                          Dagster
                        </NavDropdown.Item>
                      )}
                      <NavDropdown.Item
                        href="https://event-orchestration.mykhapps.com/"
                        target="_blank"
                      >
                        {' '}
                        Event Orchestration
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        href="https://teams.microsoft.com/l/team/19%3acQNjb25gEZ9gRwC43WlABbjszB94qdkdIgZR15aO-Cg1%40thread.tacv2/conversations?groupId=58420685-d8fb-45a1-8996-63b8cdd1d92b&tenantId=3ce34e42-c07d-47bb-b72a-4ce606de6b88"
                        target="_blank"
                      >
                        Join MS Teams
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        href="https://khcprod.service-now.com/khc_sp?id=khc_sc_cat_item&sys_id=159204dedbcec11005edd9fcd3961998"
                        target="_blank"
                      >
                        Submit ServiceNow Ticket
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown
                      title="Utilities"
                      id="StewardScrollingDropdown"
                    >
                      <NavDropdown.Item onClick={handleShow}>
                        PGP Encryption App
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={handleShowAnz}>
                        ANZ Recommendation
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={handleShowBlobUpload}>
                        DE File Upload
                      </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="DE" id="DEScrollingDropdown">
                      <NavDropdown.Item href="/snowflake_clone">
                        Snowflake Clone
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/capacity_tracker">
                        Capacity Tracker
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/neo4j_lineage">
                        Data Lineage (Under Development)
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/data-dictionary">
                        DBT - Snowflake Data Dictionary
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        href="https://khaz-eus2-dbt-docs-prod-app-001.azurewebsites.net/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        DBT - Snowflake Data Dictionary (supercharged)
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/data-dictionary-bq">
                        DBT - BigQuery Data Dictionary
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/dbt-grading">
                        DBT - Grading
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/ingestion-intake">
                        Ingestion Intake
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/snf_cluster">
                        Snowflake Clustering
                      </NavDropdown.Item>
                      {/* <NavDropdown.Item onClick={handleDbtReport}>
                    DBT Observability
                  </NavDropdown.Item> */}
                    </NavDropdown>
                  </>
                )}

                {/* <Nav.Link href="/khde-docs">Documentation</Nav.Link> */}

                {/* {hideLink.npsLink && (
                  <Nav.Link href="/view-surveys">KH-NPS</Nav.Link>
                )} */}
                {/* <Nav.Link href="/badge">Badge</Nav.Link> */}
                {/* <Nav.Link href="/app-home">Apps</Nav.Link> */}
              </Nav>
            ) : null}
          </Navbar.Collapse>

          {accounts.length > 0 ? (
            <>
              {/* adds username on authentication */}
              {isAuthenticated ? (
                <>
                  {/* <Link to="/my-profile"> */}
                  <TooltipHover
                    arrow
                    placement="bottom"
                    title="Click to view your profile"
                  >
                    <Button
                      variant="outline-secondary"
                      className="username-login"
                      onClick={() => handleOpenProfile()}
                    >
                      {username}
                    </Button>
                  </TooltipHover>
                  {/* </Link> */}
                  <Button
                    variant="outline-danger"
                    className="ml-auto p-1"
                    onClick={() => handleLogout(instance)}
                  >
                    Sign out
                  </Button>
                </>
              ) : (
                <Button
                  variant="outline-primary"
                  className="ml-auto add-ons"
                  onClick={() => handleLogin(instance)}
                >
                  Sign in
                </Button>
              )}
              <div className="right-menu-items" title="Send Email">
                <a
                  className="email-btn"
                  href="mailto:dl_khcdewebappdev@kraftheinz.com?subject=KHC%20WebApp%20-%20Questions/Issues/Concerns"
                >
                  <MdMailOutline />
                </a>
              </div>
            </>
          ) : null}
        </Container>
        <Modal size="lg" show={show} onHide={handleClose}>
          <PgpEncryptionApp />
        </Modal>

        {/* ANZ Recommendations */}
        <Modal size="xl" show={showAnz} onHide={handleCloseAnz}>
          <AnzRecommendation />
        </Modal>

        {/* Blob Upload */}
        <Modal size="xl" show={showBlobUpload} onHide={handleCloseBlobUpload}>
          <BlobUpload />
        </Modal>
      </Navbar>
      {showUserProfile ? (
        <>
          <UserProfile
            selectedUser={username}
            badgeData={badgeData}
            setBadgeData={setBadgeData}
            showModal={showUserProfile}
            setShowModal={setShowUserProfile}
            isLocalUser={true}
            history={history}
            pageSize={1}
            pageIndex={0}
            userKudos={userKudos}
            inMenuJs={true}
          />
        </>
      ) : (
        ''
      )}
    </>
  )
}

export default Menu
