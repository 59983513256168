import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useRef,
} from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Modal from 'react-bootstrap/Modal'
import { AuthContext } from '../../context/authProvider'
import '../../../App.css'
import 'react-toastify/dist/ReactToastify.css'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import jwt_decode from 'jwt-decode'
import ReactQuill from 'react-quill'
import { ForumHandler } from '../../Utils'
import Card from 'react-bootstrap/Card'
import image_preview from '../../../media/image_preview.png'
import kudos_appreciation_notification from '../../../media/kudos_appreciation_notification.png'
import kudos_innovation_notification from '../../../media/kudos_innovation_notification.png'
import kudos_teamwork_notification from '../../../media/kudos_teamwork_notification.png'
import kudos_leadership_notification from '../../../media/kudos_leadership_notification.png'
import kudos_delivery_notification from '../../../media/kudos_delivery_notification.png'
import { toast } from 'react-toastify'
import { createKudosEntry } from '../../../redux/actions/collabAction'
import { toastTopRightError } from '../../Helper/ToastObjects'
import LoadingStatus from '../../Helper/LoadingStatus'
import { useDispatch } from 'react-redux'
import { RoleHandler } from '../Utilities/roleCheck'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import MentionHandler from '../Utilities/quillSearch'
import SummaryHelpContent from '../Utilities/summaryHelpContent'
import { format } from 'date-fns'

const SendKudosForm = ({ kudosModal, handleCloseKudosModal ,setKudoModalCollab, receiverEmails = [] }) => {
  let tokenValue = localStorage.getItem('token')
  const [authCreds] = useContext(AuthContext)
  let userFetchTimeOut
  const endpoint = authCreds.restEndpoint
  const today = new Date(Date.now())

  let decodedToken = jwt_decode(localStorage.getItem('token'))
  let preferred_username = decodedToken.preferred_username

  const getUserName = (email) => {
    if (!email) return ''
    return email.split('@')[0]
  }

  const defaultSubmitterEditorContent = `<p><span class="mention" data-index="0" data-denotation-char="@" data-id="${preferred_username}" data-value="${getUserName(
    preferred_username
  )}"><span contenteditable="false"><span class="ql-mention-denotation-char">@</span>${getUserName(
    preferred_username
  )}</span></span></p>`

  const [userEmail, setUserEmail] = useState(preferred_username)
  const [reqDate, setReqDate] = React.useState(today.toLocaleDateString())
  const [editorContent, setEditorContent] = React.useState('')
  const [copyEditorContent, setCopyEditorContent] = React.useState('')
  const [submitterEditorContent, setSubmitterEditorContent] = React.useState(
    defaultSubmitterEditorContent
  )
  const [kudoType, setKudoType] = React.useState('')
  const [message, setMessage] = useState('')
  const [imagePath, setImagePath] = useState('')
  const [displayCard, setDisplayCardValue] = useState(false)
  const [buttonShouldDisable, setButtonShouldDisable] = useState(true)
  const [nomineeEmails, setNomineeEmails] = useState(receiverEmails)
  const [submitterEmails, setSubmitterEmails] = useState([])
  const [isNomineeSelected, setIsNomineeSelected] = useState(false)
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })

  const kudos_types = [
    {
      kudos_type: 'Appreciation',
      kudos_message:
        'In recognition of your exceptional dedication and hard work, your contribution is truly valued and admired by all. Thank you for your outstanding efforts!',
      kudos_image_path: kudos_appreciation_notification,
    },
    {
      kudos_type: 'Innovation',
      kudos_message:
        'Your creative thinking and innovative approach have brought fresh ideas and solutions to our team. Your ability to think outside the box is inspiring and greatly appreciated. Congratulations on your innovative contributions!',
      kudos_image_path: kudos_innovation_notification,
    },
    {
      kudos_type: 'Team Work',
      kudos_message:
        'Your collaborative spirit and commitment to working together have not gone unnoticed. Your teamwork has been instrumental in achieving our shared goals. Keep up the fantastic work!',
      kudos_image_path: kudos_teamwork_notification,
    },
    {
      kudos_type: 'Delivery',
      kudos_message:
        'Your precision and unwavering commitment have propelled our success forward. your ability to consistently meet deadlines with high quality results is truly commendable. Keep up the fantastic work!',
      kudos_image_path: kudos_delivery_notification,
    },
    {
      kudos_type: 'Leadership',
      kudos_message:
        'Your vision and guidance have inspired the team to achieve new heights. Your ability to lead with confidence and integrity is truly commendable. Your leadership makes a difference every day!',
      kudos_image_path: kudos_leadership_notification,
    },
  ]

  const dispatch = useDispatch()

  const isAdmin = RoleHandler.checkAdminRole(tokenValue)

  const useFocusAndSetRef = (ref) => {
    return useCallback(
      (node) => {
        if (node !== null) {
          ref.current = node
          const quillEditor = node.getEditor()
          const len = quillEditor.getLength()
          quillEditor.setSelection(len, len)
          quillEditor.focus()
        }
      },
      [ref]
    )
  }

  const quillRef = useRef(null)
  const setQuillRef = useFocusAndSetRef(quillRef)

  useEffect(() => {
    const regex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
    const matches = editorContent.match(regex)
    let mentionVal = matches ? matches.map((mention) => mention.slice(0)) : []

    if (receiverEmails.length > 0) {
      setIsNomineeSelected(true)
    } else {
      if (mentionVal.length > 0) {
        setIsNomineeSelected(true)
        if (JSON.stringify(nomineeEmails) !== JSON.stringify(mentionVal)) {
          setNomineeEmails([...new Set([...nomineeEmails, ...mentionVal])])
        } else {
          setNomineeEmails([...nomineeEmails])
        }
      } else {
        setNomineeEmails([])
        setIsNomineeSelected(false)
      }
    }
    return () => {
      setNomineeEmails(receiverEmails)
    }
  }, [editorContent])

  useEffect(() => {
    if (
      nomineeEmails.length > 0 &&
      message.length > 0 &&
      kudoType.length > 0 &&
      submitterEmails.length > 0
    ) {
      setButtonShouldDisable(false)
    } else {
      setButtonShouldDisable(true)
    }
    return () => {
      setButtonShouldDisable(true)
    }
  }, [nomineeEmails, kudoType, message, submitterEmails])

  useEffect(() => {
    const regex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
    const matches = submitterEditorContent.match(regex)
    let mentionVal = matches ? matches.map((mention) => mention.slice(0)) : []

    if (mentionVal.length > 0) {
      if (JSON.stringify(nomineeEmails) !== JSON.stringify(mentionVal)) {
        const submitterEmailsTemp = [...submitterEmails, ...mentionVal];
+       setSubmitterEmails([... new Set(submitterEmailsTemp)])
      } else {
        setSubmitterEmails([... new Set(submitterEmails)])
      }
    } else {
      setSubmitterEmails([])
    }
    return () => {
      setSubmitterEmails([])
    }
  }, [submitterEditorContent])

  useEffect(() => {
    if (submitterEmails.length > 0) {
      setUserEmail(submitterEmails[0])
    } else {
      setUserEmail('')
    }
  }, [submitterEmails])

  // useEffect(() => {
  //   setReqDate(today.toLocaleDateString())
  // })

  const handleQuill = (value) => {
    setEditorContent(value)
  }

  const handleCopyQuill = (value) => {
    setCopyEditorContent(value)
  }

  const handleSubmitterQuill = (value) => {
    setSubmitterEditorContent(value)
  }

  const quillSearch = (searchTerm, renderList, mentionChar) => {
    clearTimeout(userFetchTimeOut)
    if (searchTerm.length >= 3) {
      userFetchTimeOut = setTimeout(() => {
        ForumHandler.fetchUserData(
          endpoint,
          searchTerm,
          renderList,
          mentionChar
        )
      }, 1000)
    }
  }

  const module = MentionHandler.mentionconfig(quillSearch)

  const handleCloseModal = () => {
    setKudoModalCollab(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoadingStatus({ status: true, message: 'Sending Kudos...' })

    let user_email = userEmail.toLowerCase()

    if (submitterEmails.length > 1) {
      setLoadingStatus({ status: false, message: 'Fetching data...' })
      toast('OOPS! You cannot have multiple submitters.', toastTopRightError)
    } else {
      if (!nomineeEmails.includes(user_email)) {
        const regex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
        const matches = copyEditorContent.match(regex)
        let copyEmails = matches
          ? matches.map((mention) => mention.slice(0))
          : []
        const bodyJSON = {
          sender_email: userEmail,
          message: message,
          kudos_type: kudoType,
          receiver_emails: nomineeEmails,
          user_email: preferred_username.toLowerCase(),
          copy_emails: copyEmails,
          submission_date: reqDate,
        }

        dispatch(createKudosEntry(endpoint, bodyJSON)).then(() => {
          setLoadingStatus({ status: false, message: 'Fetching data...' })
          // toast('Kudos Sent Successfully', toastTopRightSuccess)
          handleCloseModal()
        })
      } else {
        setLoadingStatus({ status: false, message: 'Fetching data...' })
        toast('OOPS! You cannot sends kudos to yourself.', toastTopRightError)
      }
    }
  }

  const handleKudoTypeChange = (selectedKudo) => {
    setKudoType(selectedKudo)

    if (selectedKudo == 'Select Kudos Type') {
      setDisplayCardValue(false)
      setKudoType('')
      setMessage('')
    } else {
      setDisplayCardValue(true)
      let msg = ''
      let img_path = ''
      for (let x in kudos_types) {
        if (kudos_types[x].kudos_type == selectedKudo) {
          msg = kudos_types[x].kudos_message
          img_path = kudos_types[x].kudos_image_path
        }
      }
      setMessage(msg)
      setImagePath(img_path)
    }
  }
  return (
    <Modal size="xl" show={kudosModal} onHide={handleCloseKudosModal}>
      <Modal.Header className="send-kudos-header" closeButton>
        <Modal.Title>
          Send Kudos
          <div style={{display: 'flex', fontSize: '0.875rem', lineHeight: '1.25rem'}}>Send appreciation!  Highlight great work!  Celebrate wins!</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalBody" style={{padding: 0}}>
        <SummaryHelpContent Page='Send Kudos' />
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <div>
          <Container>
            <Row>
              <Col md={6}>
                <Form>
                  {isAdmin == true ? (
                    <Form.Group
                      className=""
                      as={Col}
                      md="10"
                      controlId="validationFormikUsername"
                    >
                      <Form.Label>Submitter</Form.Label>
                      <ReactQuill
                        style={{ background: '#fff' }}
                        theme="snow"
                        className="react-quill-badge"
                        ref={setQuillRef}
                        onChange={handleSubmitterQuill}
                        modules={module}
                        placeholder="Add submitter using @ operator"
                        defaultValue={submitterEditorContent}
                      />
                    </Form.Group>
                  ) : (
                    <></>
                  )}
                  {isAdmin == true ? (
                    <Form.Group
                      className=""
                      as={Col}
                      md="10"
                      controlId="validationFormikUsername"
                    >
                      <br />
                      <Form.Label>Submission Date</Form.Label>
                      <br />
                      <DatePicker
                        selected={reqDate}
                        placeholderText="Enter Kudos Sent Date in mm/dd/yyyy"
                        onChange={(date) =>
                          setReqDate(format(date, 'MM/dd/yyyy'))
                        }
                        className="form-control"
                        style={{ width: '621px' }}
                      />
                    </Form.Group>
                  ) : (
                    <></>
                  )}
                  <Form.Group
                    className=""
                    as={Col}
                    md="10"
                    controlId="validationFormik01"
                  >
                    <Form className="mt-2">
                      <Form.Label></Form.Label>
                      <Form.Select
                        onChange={(e) => {
                          handleKudoTypeChange(e.target.value)
                        }}
                        value={kudoType}
                      >
                        <option>Select Kudos Type</option>
                        {kudos_types?.map((bl) => {
                          return (
                            <option key={bl.kudos_type} value={bl.kudos_type}>
                              {bl.kudos_type}
                            </option>
                          )
                        })}
                      </Form.Select>
                    </Form>
                  </Form.Group>
                  {receiverEmails.length > 0 ? (
                    <Form.Group
                      className=""
                      as={Col}
                      md="10"
                      controlId="validationFormik01"
                    >
                      <div>
                        <br />
                        <Form className="mt-2">
                          <InputGroup>
                            <Form.Control
                              type="text"
                              placeholder="receiver email"
                              aria-describedby="inputGroupPrepend"
                              name="mail"
                              defaultValue={receiverEmails[0]}
                              disabled="true"
                            />
                          </InputGroup>
                        </Form>
                      </div>
                    </Form.Group>
                  ) : (
                    <Form.Group
                      className=""
                      as={Col}
                      md="10"
                      controlId="validationFormik01"
                    >
                      <Form className="mt-2">
                        <Form.Label></Form.Label>
                        <ReactQuill
                          style={{ background: '#fff' }}
                          theme="snow"
                          className="react-quill-badge"
                          ref={setQuillRef}
                          onChange={handleQuill}
                          modules={module}
                          placeholder="Add nominees using @ operator"
                          // formats={allowedFormats}
                        />{' '}
                      </Form>
                    </Form.Group>
                  )}
                  <Form.Group
                    className=""
                    as={Col}
                    md="10"
                    controlId="validationFormik01"
                  >
                    <Form className="mt-2">
                      <Form.Label></Form.Label>
                      <ReactQuill
                        style={{ background: '#fff' }}
                        theme="snow"
                        className="react-quill-badge"
                        ref={setQuillRef}
                        onChange={handleCopyQuill}
                        modules={module}
                        placeholder="Include Copy to emails using @ operator"
                      />{' '}
                    </Form>
                  </Form.Group>

                  <Form.Group
                    className=""
                    as={Col}
                    md="10"
                    controlId="validationFormikUsername"
                  >
                    <Form.Label></Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        placeholder="Message"
                        aria-describedby="inputGroupPrepend"
                        name="Message"
                        as="textarea"
                        rows={4}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                  <div>
                    * You can edit the default kudos text message to send your
                    custom message
                  </div>
                  <br />
                  <div className='kudos-jumbotron'>
                    <span className='kudos-jumbotron-heading'>Once you submit kudos the following will occur:</span>
                    <ol className='kudos-jumbotron-list' type="1">
                      <li>Email notification will be sent to individual you have given Kudos to</li>
                      <li>Managers of the nominees will be copied in the notification emails by default</li>
                      <li>A Kudos badge in Kudo’s column will appear on the ‘Badges’ page of the individual you have given kudos to</li>
                    </ol>
                  </div>
                </Form>
              </Col>

              <Col md={6}>
                {displayCard == true ? (
                  <div className="card-container">
                    <div className="ribbon">{kudoType}</div>
                    <Card style={{ width: '30rem' }} className="text-center">
                      {/* <Card.Header background='success'>KUDOS</Card.Header> */}
                      <Card.Body>
                        <Card.Img
                          variant="top"
                          src={imagePath}
                          style={{ width: '50%', height: '100%' }}
                        />
                        <br />
                        <br />
                        <Card.Title style={{ color: '#239faf' }}>
                          {isNomineeSelected
                            ? nomineeEmails[0]
                            : '*Nominee Name'}
                        </Card.Title>
                        <Card.Text style={{ fontWeight: 'bold' }}>
                          {message}
                        </Card.Text>
                        <br />
                        <Card.Text>From</Card.Text>
                        <Card.Text
                          style={{
                            fontWeight: 'bold',
                            background: '#fff',
                            border: '2px',
                          }}
                        >
                          {userEmail}
                        </Card.Text>
                      </Card.Body>
                    </Card>{' '}
                  </div>
                ) : (
                  <Card style={{ width: '25rem', height: '25rem' }}>
                    <Card.Img
                      variant="top"
                      src={image_preview}
                      style={{
                        width: '100%',
                        height: '150px',
                        objectFit: 'cover',
                      }}
                    />
                    <Card.Body>
                      <Card.Title>Kudo Card Preview</Card.Title>
                      <Card.Text style={{ fontWeight: 'bold' }}>
                        This is a placeholder card. Please select kudo type to
                        preview the actual card.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                )}
              </Col>
            </Row>
            <br />
          </Container>
          <Modal.Footer>
            <Button
              variant="primary"
              className="addModalBtn kudos-submit-button"
              type="submit"
              onClick={handleSubmit}
              disabled={buttonShouldDisable}
            >
              Submit Kudos
            </Button>
            <Button
              variant="danger"
              type="submit"
              className="addModalBtn"
              onClick={handleCloseModal}
            >
              Close
            </Button>
          </Modal.Footer>
        </div>
      )}
      </Modal.Body>
    </Modal>
  )
}
export default SendKudosForm
